import React, {useState} from 'react';
import {useQuery} from 'react-query';
import * as querydata from '../dataService/DataService';

const CustomerType = props => {
  //define variables
  const getCustomerTypes = useQuery(
    'CompanySelect',
    querydata.getAllCustomerTypes
  );

  if (getCustomerTypes.isLoading) {
    return <div>Loading...</div>;
  }

  if (getCustomerTypes.error) {
    return <div>Error: {getCustomerTypes.error.message}</div>;
  }

  if (!getCustomerTypes.data) {
    return <div>No data fetched.</div>;
  }
  const handleSelectChange = event => {
    const selectedValue = event.target.value;
    const selectedOption = getCustomerTypes.data.data.find(
      option => option.customer_type_id == selectedValue
    );

    if (selectedOption) {
      props.setSelectedCustomerTypeCode(selectedOption.customer_type_code);
    }

    // If you want to also propagate the change to the parent component, call props.handleChange here
    if (props.handleChange) {
      props.handleChange(event);
    }
  };

  return (
    <>
      <select
        id="RegistantsType"
        className="form-control form-select"
        name="customerTypeId"
        value={props.values && props.values.customerTypeId}
        // onChange={props.handleChange}
        onChange={handleSelectChange}
        onBlur={props.handleBlur}
        disabled={props.disabled}
      >
        <option value="" defaultValue="Select">
          --Select--
        </option>
        {getCustomerTypes.data.data &&
          getCustomerTypes.data.data.map(option => {
            return (
              <option
                key={option.customer_type_id}
                value={option.customer_type_id}
              >
                {option.customer_type}
              </option>
            );
          })}
      </select>
      {/* {props.errors.customerTypeId && props.touched.customerTypeId ? (
                            <p className="form-error text-danger">
                              {props.errors.customerTypeId}
                            </p>
                          ) : null} */}
    </>
  );
};

export default CustomerType;
