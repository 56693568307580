import React, {useState} from 'react';
import * as queryData from '../../service/dataService';
import {useQuery} from 'react-query';
import {Link} from 'react-router-dom';
import {saveAs} from 'file-saver';

const Projects = () => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const {data, isLoading, error} = useQuery(
    'getAllProjects',
    queryData.getAllMyProjects
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data || !data.data.data) {
    return <div>No data fetched.</div>;
  }

  const downloadContract = () => {
    const image_url =
      projects && projects[selectedProjectIndex].rooftop_solar_contract;
    saveAs(image_url, 'rooftop_solar_contract');
  };

  const downloadSanctionLetter = () => {
    const image_url =
      projects && projects[selectedProjectIndex].loan_sanction_letter;
    saveAs(image_url, 'loan_sanction_letter');
  };

  const downloadAgreement = () => {
    const image_url = projects && projects[selectedProjectIndex].loan_agreement;
    saveAs(image_url, 'loan_agreement');
  };

  const downloadDisbursalLetter = () => {
    const image_url =
      projects && projects[selectedProjectIndex].loan_disbursal_letter;
    saveAs(image_url, 'loan_disbursal_letter');
  };

  const projects = data && data.data && data.data.data ? data.data.data : '';
  // console.log(projects.length)
  const handleProjectSelect = event => {
    const selectedIndex = event.target.value;
    setSelectedProjectIndex(selectedIndex);
  };
  const formateDate = date => {
    if (date) {
      const dateObject = new Date(date);
      const newDate = new Date(dateObject.getTime());
      newDate.setHours(newDate.getHours() + 5.5);
      // Check if the dateObject is valid
      if (!isNaN(newDate)) {
        const day = newDate.getDate();
        const month = newDate.getUTCMonth() + 1; // Add 1 because months are zero-based
        const year = newDate.getUTCFullYear();

        // Check if the day value is within a valid range (1 to 31)
        if (day >= 1 && day <= 31) {
          const formattedDate = `${day.toString().padStart(2, '0')}/${month
            .toString()
            .padStart(2, '0')}/${year}`;
          return formattedDate;
        }
      }
    }
    // Return null for invalid or empty dates
    return null;
  };

  return (
    // <!--Products Head start-->
    <section>
      <div className="solfin-profile cunsumer-top-space header-space pt-1 pb-3">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <h5 className="fw-bold">Projects</h5>
              </div>
              {projects.length > 0 ? (
                <div className="select-project">
                  <select
                    name=""
                    className="form-control rounded-0 border-bottom border-0 ps-0 pe-0 fw-semibold form-select pb-1"
                    onChange={handleProjectSelect}
                    value={selectedProjectIndex || ''}
                  >
                    {/* <option value="">Select a Project</option> */}
                    {projects.map((project, index) => (
                      <option key={index} value={index}>
                        {projects && projects[index]
                          ? projects && projects[index].project_name
                          : ''}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
              ) : (
                ''
              )}

              {/* <!-- /select project --> */}
              {projects.length > 0
                ? selectedProjectIndex !== null && (
                    <div className="card rounded-4 mt-3">
                      <div className="card-header ps-4 pe-4 pt-3 pb-3 rounded-top-4">
                        <span className="fw-semibold">
                          Project:{' '}
                          {projects && projects[selectedProjectIndex]
                            ? projects &&
                              projects[selectedProjectIndex].project_name
                            : ''}
                        </span>
                      </div>
                      {/* <!-- /card-header --> */}
                      <div className="card-body ps-xl-5 ps-4 pe-xl-5 pe-4">
                        <div className="project-status">
                          <span className="fw-semibold d-flex text-nowrap align-items-center">
                            Project Status
                            <button className="btn btn-primary btn-header rounded-3 text-nowrap text-capitalize green-btn calculate-btn pt-2 pb-2 ps-4 pe-4 ms-4">
                              {projects && projects[selectedProjectIndex]
                                ? projects &&
                                  projects[selectedProjectIndex].status
                                : ''}
                            </button>
                          </span>
                        </div>
                        <div className="registration-details d-flex justify-content-between align-items-center pt-1 pb-2">
                          <ul className="list-unstyled p-0 w-100 d-flex flex-wrap justify-content-between align-items-center">
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  City
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].city
                                    : ''}
                                </span>
                              </div>
                            </li>

                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Type Of Panel
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .type_of_panel
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  State
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].state
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Installation Date
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      formateDate(
                                        projects[selectedProjectIndex]
                                          .installation_date
                                      )
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Address
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].address1
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Financed
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .is_financed === true
                                      ? 'Yes'
                                      : 'No'
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Finance Partner
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .finance_partner
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Installed Capacity
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].capacity
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Estimated Unit Generated
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .estimated_unit_generated
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Type Of Solution
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].solution
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  EPC
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .company_name
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  DSA
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex].dsa_name
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Project Cost
                                </span>
                                <span className="fw-medium mt-1 mb-1">
                                  ₹{' '}
                                  {projects && projects[selectedProjectIndex]
                                    ? projects &&
                                      projects[selectedProjectIndex]
                                        .project_cost
                                    : ''}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75">
                                  Rooftop Solar Contract
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .rooftop_solar_contract ? (
                                  <span className="fw-medium d-flex align-items-center">
                                    <a
                                      href={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .rooftop_solar_contract
                                          : ''
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={downloadContract}
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].rooftop_solar_contract:""} alt="" title="RooftopSolarContract" height="25"/> */}{' '}
                                      <i className="fa-solid fa-download"></i>{' '}
                                      Download{' '}
                                    </a>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Loan Sanction Letter
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .loan_sanction_letter ? (
                                  <span className="fw-medium d-flex align-items-center">
                                    <Link
                                      to={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .loan_sanction_letter
                                          : ''
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].loan_sanction_letter:""} alt="" title="LoanSanctionLetter" height="25"/> */}
                                      <button
                                        className="btn text-dark text-green p-0"
                                        onClick={downloadSanctionLetter}
                                      >
                                        {' '}
                                        <i className="fa-solid fa-download"></i>{' '}
                                        Download{' '}
                                      </button>
                                    </Link>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Loan Agreement
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .loan_agreement ? (
                                  <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                    <a
                                      href={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .loan_agreement
                                          : ''
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={downloadAgreement}
                                      // className="text-dark text-green p-0"
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].loan_agreement:""} alt="" title="LoanAgreement" height="25"/> */}{' '}
                                      <i className="fa-solid fa-download"></i>{' '}
                                      Download{' '}
                                    </a>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </li>
                            <li>
                              <div className="border-bottom pt-1 pb-1 d-flex justify-content-between align-items-center">
                                <span className="opacity-75 mt-1 mb-1">
                                  Loan Disbursal Letter
                                </span>
                                {projects &&
                                projects[selectedProjectIndex] &&
                                projects[selectedProjectIndex]
                                  .loan_disbursal_letter ? (
                                  <span className="fw-medium d-flex align-items-center mt-1 mb-1">
                                    <Link
                                      to={
                                        projects &&
                                        projects[selectedProjectIndex]
                                          ? projects &&
                                            projects[selectedProjectIndex]
                                              .loan_disbursal_letter
                                          : ''
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {/* <img class="ms-2" src={projects[selectedProjectIndex]?projects[selectedProjectIndex].loan_disbursal_letter:""} alt="" title="LoanDisbursalLetter" height="25"/> */}
                                      <button
                                        className="btn text-dark text-green p-0"
                                        onClick={downloadDisbursalLetter}
                                      >
                                        {' '}
                                        <i className="fa-solid fa-download"></i>{' '}
                                        Download
                                      </button>
                                    </Link>
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <!-- /card-body --> */}
                    </div>
                  )
                : 'There is no project associated with you '}
              {/* <!-- /card --> */}
            </div>
            {/* <!-- /col-12 --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /my-products --> */}
    </section>
    // {/* <!--Products Head end--> */}
  );
};

export default Projects;
