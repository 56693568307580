import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
export default function exportToExcel(apiResult, columns, headers) {
  const filteredData = apiResult.map(item =>
    columns.reduce((obj, column, index) => {
      obj[headers[index]] = item[column];
      return obj;
    }, {})
  );
  // Convert API result to Excel workbook
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(filteredData);
  XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');

  // Generate Excel file
  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
  const excelData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const excelUrl = URL.createObjectURL(excelData);

  // Download the Excel file
  const link = document.createElement('a');
  link.href = excelUrl;
  link.download = 'ApplicationList.xlsx';
  link.click();
  URL.revokeObjectURL(excelUrl);
}
