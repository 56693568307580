import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import * as queryData from '../service/DataService';
import {saveAs} from 'file-saver';

const ComfortLetterDetails = () => {
  const {letterCode} = useParams();
  const [partnerSince, setPartnerSince] = useState(null);
  const queryClient = useQueryClient();
  const {data, isLoading, error} = useQuery(
    ['getComfortLettersDetails', +letterCode],
    () => queryData.getComfortLettersDetails(letterCode)
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  
  const handleDateChange = date => {
    setPartnerSince(date);
  };

  const getFinancialYears = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const month = today.getMonth(); // 0 = January, 1 = February, ..., 11 = December

    // Determine the current financial year
    let startYear = currentYear;
    let endYear = currentYear + 1;

    if (month < 3) {
      // If the current month is before April
      startYear -= 1;
      endYear -= 1;
    }

    const currentFinancialYear = `${startYear}-${endYear}`;

    // Determine the last two completed financial years
    const lastFinancialYears = [
      `${startYear - 2}-${startYear - 1}`,
      `${startYear - 1}-${startYear}`,
    ];

    // Determine the upcoming two financial years
    const upcomingFinancialYear = `${endYear}-${endYear + 1}`;
    //`${endYear + 1}-${endYear + 2}`

    return {
      currentFinancialYear,
      lastFinancialYears,
      upcomingFinancialYear,
    };
  };
  const {currentFinancialYear, lastFinancialYears, upcomingFinancialYear} =
    getFinancialYears();

  const generateComfortLetter = async e => {
    e.preventDefault();
    if (!partnerSince && !data.data.data.dealerPartnershipDate) {
      toast.error('Please selected partnership date');
      return;
    }
    const dealerCode = document.getElementById('dealerCode').value;
    if (!dealerCode) {
      toast.error('Please enter dealer code');
      return;
    }

    const recommendedLimit = document.getElementById('recommendedLimit').value;
    if (!recommendedLimit) {
      toast.error('Please enter recommended limit');
      return;
    }
    const currentYearSales = {};
    const currentFinancialYearSales = document.getElementById(
      'sales-' + currentFinancialYear
    ).value;
    if (!currentFinancialYearSales) {
      toast.error('Please current year sales number');
      return;
    }

    const upcomingFinancialYearSales = document.getElementById(
      'sales-' + upcomingFinancialYear
    ).value;
    if (!upcomingFinancialYearSales) {
      toast.error(
        'Please enter ' + upcomingFinancialYear + ' year sales number'
      );
      return;
    }
    currentYearSales[currentFinancialYear] = currentFinancialYearSales;
    currentYearSales[upcomingFinancialYear] = upcomingFinancialYearSales;

    const lastYearFinancialSales = {};
    for (const year of lastFinancialYears) {
      const financialYearSales = document.getElementById('sales-' + year).value;
      if (!financialYearSales) {
        toast.error('Please enter ' + year + ' year sales number');
        return;
      }

      lastYearFinancialSales[year] = financialYearSales;
    }
    const istDate = moment(partnerSince || data.data.data.dealerPartnershipDate * 1000).tz('Asia/Kolkata');
    const form = {
      dealerCode: dealerCode,
      comfortLetterCode: letterCode,
      dealerPartnershipDate: Math.floor(istDate.valueOf() / 1000),
      yearVsSalesNumber: lastYearFinancialSales,
      yearVsProjectedSales: currentYearSales,
      recommendedLimitInLac: recommendedLimit,
    };
    const response = await queryData.generateComfortLetter(form);
    if (response.success && response.data.letterCode) {
      toast.success(
        'Document has been shared to your email id, Please digital sign document using DSC'
      );
    } else if (response.message) {
      toast.error(response.message);
    } else {
      toast.error(
        'Unable to generate comfort letter, please try after sometime.'
      );
    }
    queryClient.invalidateQueries('getComfortLettersDetails');
  };

  const updateComfortLetter = async e => {
    e.preventDefault();
    if (!partnerSince) {
      toast.error('Please selected partnership date');
      return;
    }
    const dealerCode = document.getElementById('dealerCode').value;
    if (!dealerCode) {
      toast.error('Please enter dealer code');
      return;
    }
    const currentYearSales = {};
    const currentFinancialYearSales = document.getElementById(
      'sales-' + currentFinancialYear
    ).value;
    // if (!currentFinancialYearSales) {
    //   toast.error('Please current year sales number');
    //   return;
    // }

    const upcomingFinancialYearSales = document.getElementById(
      'sales-' + upcomingFinancialYear
    ).value;
    // if (!upcomingFinancialYearSales) {
    //   toast.error(
    //     'Please enter ' + upcomingFinancialYear + ' year sales number'
    //   );
    //   return;
    // }
    currentYearSales[currentFinancialYear] = currentFinancialYearSales;
    currentYearSales[upcomingFinancialYear] = upcomingFinancialYearSales;

    const lastYearFinancialSales = {};
    for (const year of lastFinancialYears) {
      const financialYearSales = document.getElementById('sales-' + year).value;
      if (!financialYearSales) {
        toast.error('Please enter ' + year + ' year sales number');
        return;
      }

      lastYearFinancialSales[year] = financialYearSales;
    }
    const istDate = moment(partnerSince).tz('Asia/Kolkata');
    const form = {
      dealerCode: dealerCode,
      comfortLetterCode: letterCode,
      dealerPartnershipDate: Math.floor(istDate.valueOf() / 1000),
      yearVsSalesNumber: lastYearFinancialSales,
      yearVsProjectedSales: currentYearSales,
    };
    const response = await queryData.updateComfortLetterSalesDetails(form);
    if (response.success && response.data.letterCode) {
      toast.success(
        'Sales data has been updated successfully.'
      );
    } else if (response.message) {
      toast.error(response.message);
    } else {
      toast.error(
        'Unable to generate comfort letter, please try after sometime.'
      );
    }
    queryClient.invalidateQueries('getComfortLettersDetails');
  };

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Comfort Letter Details</h3>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="mb-0">
              <div className="tab-content mt-3" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div className="solfin-application-details">
                    <ul>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{width: '100%'}}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Letter Code:
                        </span>{' '}
                        <span>{data.data.data.letterCode}</span>
                      </li>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{width: '100%'}}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Requested Date:
                        </span>{' '}
                        <span>{data.data.data.requestedDate}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Distributor Name:
                        </span>{' '}
                        <span>
                          {data.data.data.distributorDetails.companyName}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Email:
                        </span>{' '}
                        <span>{data.data.data.distributorDetails.email}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Phone:
                        </span>{' '}
                        <span>{data.data.data.distributorDetails.phone}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Address:
                        </span>
                        <span>
                          {data.data.data.distributorDetails.address1}
                        </span>
                      </li>
                      {(data.data.data.statusEnum === 'SALES_DATA_UPDATED'&& !data?.data?.data?.canGenerateLetter)? 
                      <>
                      {data?.data?.data?.dealerPartnershipDate?
                      <li className="d-flex align-items-center pb-3">
                      <span
                        className="fw-bold mx-2"
                        style={{marginRight: '50px', width: '45%'}}
                      >
                        Partner since:
                      </span>
                      <span>
                        { format(new Date(data.data.data.dealerPartnershipDate * 1000),"yyyy-MM-dd")}
                      </span>
                    </li>:''}
                    <li className="d-flex align-items-center pb-3">
                      <span
                        className="fw-bold mx-2"
                        style={{marginRight: '50px', width: '45%'}}
                      >
                        Dealer Code:
                      </span>
                      <span>
                        { data.data.data.dealerCode}
                      </span>
                    </li>
                    {data?.data?.data?.yearVsProjectedSales?.[currentFinancialYear]?
                    <li className="d-flex align-items-center pb-3">
                      <span
                        className="fw-bold mx-2"
                        style={{marginRight: '50px', width: '45%'}}
                      >
                        Projected Sales For current financial Year{' '}
                        {currentFinancialYear} (in lac)
                      </span>
                      <span>
                        { data?.data?.data?.yearVsProjectedSales?.[currentFinancialYear] + " lac"}
                      </span>
                    </li>:''}
                    {[upcomingFinancialYear].map((year, index) =>{
                      
                      return(
                         data?.data?.data?.yearVsProjectedSales?.[year]?
                              <li className="d-flex align-items-center pb-3">
                      <span
                        className="fw-bold mx-2"
                        style={{marginRight: '50px', width: '45%'}}
                      >
                        Projected Sales For Finacial Year {year}{' '}
                        (in lac)
                      
                      </span>
                      <span>
                        { data?.data?.data?.yearVsProjectedSales?.[year] + " lac"}
                      </span>
                    </li>:'');
                            })
                          }

{lastFinancialYears
                            ? lastFinancialYears.map((year, index) => {
                              return(
                                data?.data?.data?.yearVsSalesNumber?.[year]?
                                <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Sales For Finacial Year {year} (in lac):
                        </span>
                        <span>
                          {data?.data?.data?.yearVsSalesNumber?.[year] + " lac"}
                        </span>
                      </li>:'');
                              })
                            : ''}
                    </>
                      :''}
                      
                    </ul>
                    {(data.data.data.statusEnum === 'SALES_DATA_UPDATED'&& data?.data?.data?.canGenerateLetter) || data.data.data.statusEnum === 'PENDING_ON_SALES' ? (
                      <form onSubmit={''}>
                        <div className="form-wrap comfort-letter-form">
                          <div className="form-group comfort-letter-date-div">
                            <label
                              className="comfort-letter-details-label"
                              htmlFor="cl-partner-since"
                            >
                              {' '}
                              <span
                                className="fw-bold mx-2"
                                style={{marginRight: '50px'}}
                              >
                                Partner since:
                              </span>
                            </label>
                            <span>
                              <DatePicker
                                id="cl-partner-since"
                                dateFormat="dd-MM-yyyy"
                                className="comfort-letter-details-date"
                                placeholderText="Select a Date"
                                selected={
                                  partnerSince ? new Date(partnerSince) : (data?.data?.data?.dealerPartnershipDate? new Date(data.data.data.dealerPartnershipDate * 1000) :null)
                                }
                                onChange={handleDateChange}
                              />
                            </span>
                          </div>
                          <div className="form-group comfort-letter-input-div">
                            <label
                              className="comfort-letter-details-label"
                              htmlFor="dealerCode"
                            >
                              {' '}
                              <span
                                className="fw-bold mx-2"
                                style={{marginRight: '50px'}}
                              >
                                Dealer Code:
                              </span>
                            </label>
                            <input
                              type="text"
                              id={'dealerCode'}
                              placeholder="Dealer Code"
                              name="Dealer Code"
                              value={data?.data?.data?.dealerCode}
                              className="solfin-input"
                            />
                          </div>
                          <div className="form-group comfort-letter-input-div">
                            <label
                              className="comfort-letter-details-label"
                              htmlFor={'sales' + currentFinancialYear}
                            >
                              {' '}
                              <span
                                className="fw-bold mx-2"
                                style={{marginRight: '50px'}}
                              >
                                Projected Sales For Finacial Year{' '}
                                {currentFinancialYear} (in lac):
                              </span>
                            </label>
                            <input
                              type="number"
                              id={'sales-' + currentFinancialYear}
                              placeholder="Sales For Finacial Year"
                              name="Projected Sales"
                              value={data?.data?.data?.yearVsProjectedSales?.[currentFinancialYear]}
                              className="solfin-input"
                            />
                          </div>
                          {upcomingFinancialYear
                            ? [upcomingFinancialYear].map((year, index) => {
                                return (
                                  <div
                                    key={`${year}-${index}`}
                                    className="form-group comfort-letter-input-div"
                                  >
                                    <label htmlFor={'sales-' + year}>
                                      {' '}
                                      <span
                                        className="fw-bold mx-2"
                                        style={{marginRight: '50px'}}
                                      >
                                        Projected Sales For Finacial Year {year}{' '}
                                        (in lac)
                                      </span>
                                    </label>
                                    <input
                                      type="number"
                                      id={'sales-' + year}
                                      placeholder="Sales For Finacial Year"
                                      name="Sales"
                                      value={data?.data?.data?.yearVsProjectedSales?.[year]}
                                      className="solfin-input"
                                    />
                                  </div>
                                );
                              })
                            : ''}
                          {lastFinancialYears
                            ? lastFinancialYears.map((year, index) => {
                                return (
                                  <div
                                    key={`${year}-${index}`}
                                    className="form-group comfort-letter-input-div"
                                  >
                                    <label
                                      className="comfort-letter-details-label"
                                      htmlFor={'sales-' + year}
                                    >
                                      {' '}
                                      <span
                                        className="fw-bold mx-2"
                                        style={{marginRight: '50px'}}
                                      >
                                        Sales For Finacial Year {year} (in lac):
                                      </span>
                                    </label>
                                    <input
                                      type="number"
                                      id={'sales-' + year}
                                      placeholder="Sales For Finacial Year"
                                      name="Sales"
                                      value={data?.data?.data?.yearVsSalesNumber?.[year]}
                                      className="solfin-input"
                                    />
                                  </div>
                                );
                              })
                            : ''}
                          {data?.data?.data?.canGenerateLetter?
                          <div className="form-group comfort-letter-input-div">
                            <label
                              className="comfort-letter-details-label"
                              htmlFor="recommendedLimit"
                            >
                              {' '}
                              <span
                                className="fw-bold mx-2"
                                style={{marginRight: '50px'}}
                              >
                                Recommended Limit(in lacs):
                              </span>
                            </label>
                            <input
                              type="number"
                              id={'recommendedLimit'}
                              placeholder="Recommended Limit"
                              name="Recommended Limit"
                              className="solfin-input"
                            />
                          </div>:''
}
                        </div>
                      </form>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /card header --> */}

          {/* <!-- /dash-card --> */}
        </div>
        {data.data.data.statusEnum === 'SALES_DATA_UPDATED' && data?.data?.data?.canGenerateLetter ? (
          <button
            type="submit"
            className="btn btn-info"
            value="Save"
            onClick={generateComfortLetter}
          >
            Generate Comfort Letter
          </button>
        ) : (
          ''
        )}

{data.data.data.statusEnum === 'PENDING_ON_SALES' && !data?.data?.data?.canGenerateLetter ? (
          <button
            type="submit"
            className="btn btn-info"
            value="Save"
            onClick={updateComfortLetter}
          >
            Save Details
          </button>
        ) : (
          ''
        )}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default ComfortLetterDetails;
