const handleCreateLead = targetModalId => {
  return (
    <button
      data-bs-toggle="modal"
      data-bs-target={`#${targetModalId}`}
      // onClick={() => handleArchive(row)}
      className="ms-2 btn btn-sm filter-btn green-btn"
      title="New"
    >
      New Lead
    </button>
  );
};

export default handleCreateLead;
