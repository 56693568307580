import React, {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {Link, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as queryData from '../../service/dataService';
import {saveAs} from 'file-saver';

const ProjectDetails = () => {
  const {id} = useParams();

  const [sanctionLetter, setSanctionLetter] = useState('');
  const [disbursalLetter, setDisbursalLetter] = useState('');
  const [isdisbursalLetterUploaded, setdisbursalLetterUploaded] =
    useState(false);
  const [issanctionLetterUploaded, setsanctionLetterUploaded] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const queryClient = useQueryClient();

  const handleDownloadLoanAgreement = () => {
    const image_url = data && data.data && data.data.loan_agreement;
    saveAs(image_url, 'loan_agreement');
  };

  const handleDownloadRooftopContract = () => {
    const image_url = data && data.data && data.data.rooftop_solar_contract;
    saveAs(image_url, 'rooftop_solar_contact');
  };

  const formData = new FormData();
  formData.append('loanSanctionImage', sanctionLetter);
  formData.append('loanDisbursalImage', disbursalLetter);

  const handleDocSubmit = () => {
    if (issanctionLetterUploaded && isdisbursalLetterUploaded) {
      // console.log(formData, "...")
      insertLoanDocuments.mutate({id, formData});
      setIsSaved(true);
    } else {
      toast.error('Please upload both documents before submitting.');
    }
  };

  const insertLoanDocuments = useMutation(queryData.insertLoanDocuments, {
    onError: error => {
      toast.error(error.message);
      // console.log("error");
    },
    onSuccess: data => {
      queryClient.invalidateQueries('getProjectDetail');
      toast.success('Documents uploaded Successfully');
    },
  });

  const handleDisbursalLetter = e => {
    setDisbursalLetter(e.target.files[0]);
    setdisbursalLetterUploaded(true);
    // document.getElementById("LoanDisbursalLetter").value = e.target.files[0].name;
    // console.log(disbursalLetter, "..")
  };
  const handleSanctionLetter = e => {
    setSanctionLetter(e.target.files[0]);

    setsanctionLetterUploaded(true);
    // document.getElementById("LoanSanctionLetter").value = e.target.files[0].name;
    // console.log(sanctionLetter, "//")
  };

  const {data, isLoading, error} = useQuery(['getProjectDetail', +id], () =>
    queryData.getProjectDetail(id)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/admin/projects">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/admin/projects">Project</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Upload Documents
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{display: 'block'}}
                  >
                    <form action="#" method="get">
                      <div className="solfin-application-details">
                        <ul>
                          <li>
                            <span className="detail-label">Project Name</span>
                            <span className="detail-value">
                              {data.data.project_name}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">City</span>
                            <span className="detail-value">
                              {data.data.city}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">State</span>
                            <span className="detail-value">
                              {data.data.state}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Address</span>
                            <span className="detail-value">
                              {data.data.address1}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Solution</span>
                            <span className="detail-value">
                              {data.data.solution}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">Capacity</span>
                            <span className="detail-value">
                              {data.data.capacity}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Panel Type</span>
                            <span className="detail-value">
                              {data.data.type_of_panel}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Unit Generated per day
                            </span>
                            <span className="detail-value">
                              {data.data.estimated_unit_generated}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">DSA</span>
                            <span className="detail-value">
                              {data.data.dsa_name}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">EPC</span>
                            <span className="detail-value">
                              {data.data.company_name}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">Project Cost</span>
                            <span className="detail-value">
                              {data.data.project_cost}
                            </span>
                          </li>

                          <li>
                            <span className="detail-label">
                              Financing Required
                            </span>
                            <span className="detail-value">
                              {data.is_financed === true ? 'Yes' : 'No'}
                            </span>
                          </li>
                          {/* <li>
                          <span className="detail-label">Finance Partner</span>
                          <span className="detail-value">
                            {data.data.finance_partner}
                          </span>
                        </li> */}

                          <li>
                            <span className="detail-label">Loan Agreement</span>
                            <span className="detail-value img-uploaded">
                              {data && data.data && data.data.loan_agreement ? (
                                <a
                                  href={data.data.loan_agreement}
                                  target="_blank"
                                  className="text-dark"
                                  onClick={handleDownloadLoanAgreement}
                                  rel="noreferrer"
                                >
                                  <i className="fa-solid fa-download"></i>{' '}
                                  Download
                                </a>
                              ) : (
                                ''
                              )}
                            </span>
                          </li>
                          <li>
                            <span className="detail-label">
                              Rooftop Solar Contract
                            </span>

                            <span className="detail-value img-uploaded">
                              {data &&
                              data.data &&
                              data.data.rooftop_solar_contract ? (
                                <a
                                  href={data.data.rooftop_solar_contract}
                                  target="_blank"
                                  onClick={handleDownloadRooftopContract}
                                  className="text-dark"
                                  rel="noreferrer"
                                >
                                  <i className="fa-solid fa-download"></i>{' '}
                                  Download
                                  {/* <button className="btn text-dark" >Download </button> */}
                                </a>
                              ) : (
                                ''
                              )}
                            </span>
                          </li>
                          {/* <li>
                          <span className="detail-label">Installation Date</span>
                        <span className="detail-value">{data.data.installation_date}</span>
                        </li> */}
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="tab-box" id="tab-2">
                    <div className="solfin-application-details">
                      <div className="row">
                        {/* <div className="col-md-6"> */}
                        <div className="proposal-label mb-4">
                          Loan Sanction Letter
                        </div>

                        <div className="form-group">
                          <div className="upload-file w-100 text-center">
                            {/* <label htmlFor="LoanSanctionLetter">
                              <input type="file"
                              name="loanSanctionLetter"
                              id="LoanSanctionLetter"
                              onChange={handleSanctionLetter}
                              />
                              <div className="upload-file-upload">
                                <i className="fa fa-upload" aria-hidden="true"></i>
                                <span></span>
                              </div>
                            </label> */}

                            <input
                              type="file"
                              name="loanSanctionLetter"
                              id="LoanSanctionLetter"
                              onChange={handleSanctionLetter}
                              className="form-control solfin-input"
                              placeholder="No file selected"
                            />
                            {data &&
                            data.data &&
                            data.data.loan_sanction_letter ? (
                              <div className="uploaded-file">
                                <a
                                  href={data.data.loan_sanction_letter}
                                  target="_blank"
                                  alt="Quote"
                                  title="Quote"
                                  download
                                  rel="noreferrer"
                                >
                                  <img
                                    src="/images/test-file.png"
                                    alt="Loan Sanction letter"
                                  />
                                  {/* <span className="delete-file">
                                  <i className="fa fa-close" aria-hidden="true"></i>
                                </span> */}
                                </a>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {/* </div> */}

                        {/* <!-- /form-group --> */}
                        {/* <div className="col-md-6"> */}

                        <div className="proposal-label mb-4">
                          Loan Disbursal Letter
                        </div>
                        <div className="form-group">
                          <div className="upload-file w-100 text-center">
                            {/* <label htmlFor="LoanDisbursalLetter">
                              <input type="file"
                              name="loanDisbursalLetter"
                              id="LoanDisbursalLetter"
                              onChange={handleDisbursalLetter}
                              />
                              <div className="upload-file-upload">
                                <i className="fa fa-upload" aria-hidden="true"></i>
                                <span></span>
                              </div>
                            </label> */}
                            {/* <label for="Dealsin">Loan Agreement</label> */}
                            {/* <a href="" target="_blank" rel="noreferrer">
                          <img
                            alt="Image"
                            src=""
                            className="w-25 me-3"
                          />
                          </a> */}
                            <input
                              type="file"
                              name="loanDisbursalLetter"
                              id="LoanDisbursalLetter"
                              onChange={handleDisbursalLetter}
                              className="form-control solfin-input"
                              placeholder="No file selected"
                            />
                            {data &&
                            data.data &&
                            data.data.loan_disbursal_letter ? (
                              <div className="uploaded-file">
                                <a
                                  href={data.data.loan_disbursal_letter}
                                  target="_blank"
                                  alt="Quote"
                                  title="Quote"
                                  download
                                  rel="noreferrer"
                                >
                                  <img
                                    src="/images/test-file.png"
                                    alt="Loan Disbursal Letter"
                                  />
                                  {/* <span className="delete-file">
                                  <i className="fa fa-close" aria-hidden="true"></i>
                                </span> */}
                                </a>
                              </div>
                            ) : (
                              ''
                            )}
                            {/* </div> */}
                          </div>
                        </div>
                        {/* <!-- /form-group --> */}
                        <div className="form-group w-100 mb-0">
                          <div className="submit-filter">
                            {insertLoanDocuments.isLoading ? (
                              <button
                                className="ms-auto opacity-100 btn btn-info spinner-btn2"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                  Loading...
                                </span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-info"
                                value="Save"
                                onClick={handleDocSubmit}
                              >
                                {isSaved ||
                                (data.data.loan_disbursal_letter &&
                                  data.data.loan_sanction_letter)
                                  ? 'Saved'
                                  : 'Save'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /end tab -->  */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /dash-card -->  */}
    </>
  );
};

export default ProjectDetails;
