import {Route, Routes, useNavigate} from 'react-router-dom';
import DashBoard from '../pages/dashboard/DashBoard';
import Layout from '../components/Layout';
import ApplicationsLeads from '../pages/leads/ApplicationsLeads';
import EstimatorLeads from '../pages/leads/EstimatorLeads';
import Customer from '../pages/customers/Customer';
import {auth} from '../../FrontEnd/dataService/DataService';
import Cookies from 'js-cookie';
import {useEffect} from 'react';
import ApplicationDetailPage from '../pages/leads/ApplicationDetailPage';
import DSAApplicants from '../pages/dsa/DSAApplicants';
import DSAEmpanelled from '../pages/dsa/DSAEmpanelled';
import EPCEmpanelled from '../pages/epc/EPCEmpanelled';
import EPCApplicants from '../pages/epc/EPCApplicants';
import ApplicantsDetailPage from '../pages/epc/ApplicantsDetailPage';
import EPCEmpanelledDetailPage from '../pages/epc/EPCEmpanelledDetailPage';
import DSAEmpanelledDetails from '../pages/dsa/DsaEmpanelledDetails';
import DSAApplicantsDetails from '../pages/dsa/DsaApplicantsDetails';
import AdminProtectedRoutes from '../../AdminProtectedRoutes';
import DistributorLeads from '../pages/epc/DistributorLeads';
import Project from '../pages/projects/Project';
import ProjectDetails from '../pages/projects/ProjectDetails';
import CustomerDetailPage from '../pages/customers/CustomerDetailPage';
import EmpanelledDistributors from '../pages/epc/DistributorEmpanelled';
import DistributorKyc from '../pages/epc/DistributorKyc';
import PendingInvoices from '../pages/distributor/PendingInvoices';
import VerifiedInvoices from '../pages/distributor/VerifiedInvoices';

const AdminRoutes = () => {
  //   const token= Cookies.get('token');
  //     const navigate = useNavigate();

  //   useEffect(()=>{
  //  async function usedLocation(){
  //    const response =await auth()
  //     console.log(response)
  //     if(response===400){
  //       navigate("/login")
  //     }
  //   }
  //   usedLocation()
  // },[]

  //   )
  return (
    <>
      <Routes>
        {/* <Route path="/admin" element={<><Layout><DashBoard /></Layout></>} /> */}
        <Route
          path="admin/estimator-leads"
          element={
            <>
              <Layout>
                <EstimatorLeads />
              </Layout>
            </>
          }
        />
        <Route
          path="admin/application"
          element={
            <>
              <Layout>
                <ApplicationsLeads />
              </Layout>
            </>
          }
        />
        <Route
          path="admin/application/details/:id"
          element={
            <Layout>
              <ApplicationDetailPage />
            </Layout>
          }
        />
        <Route
          path="admin/customers"
          element={
            <Layout>
              <Customer />
            </Layout>
          }
        />
        <Route
          path="admin/customers/details/:id"
          element={
            <Layout>
              <CustomerDetailPage />
            </Layout>
          }
        />

        <Route
          path="admin/epc-applicants"
          element={
            <Layout>
              <EPCApplicants />
            </Layout>
          }
        />
        <Route
          path="admin/distributor/kyc"
          element={
            <Layout>
              <DistributorKyc />
            </Layout>
          }
        />

        <Route
          path="admin/channelFinancing/invoices/pending"
          element={
            <Layout>
              <PendingInvoices />
            </Layout>
          }
        />

        <Route
          path="admin/channelFinancing/invoices/verified"
          element={
            <Layout>
              <VerifiedInvoices />
            </Layout>
          }
        />

        <Route
          path="admin/epc-applicants/epc-applicants-detail/:id"
          element={
            <Layout>
              <ApplicantsDetailPage />
            </Layout>
          }
        />
        <Route
          path="admin/distributor-applicants/kyc-detail/:id"
          element={
            <Layout>
              <ApplicantsDetailPage />
            </Layout>
          }
        />
        <Route
          path="admin/epc-empanelled"
          element={
            <Layout>
              <EPCEmpanelled />
            </Layout>
          }
        />

        <Route
          path="admin/distributor-empanelled"
          element={
            <Layout>
              <EmpanelledDistributors />
            </Layout>
          }
        />
        <Route
          path="admin/epc-empanelled/detail/:id"
          element={
            <Layout>
              <EPCEmpanelledDetailPage />
            </Layout>
          }
        />

        <Route
          path="admin/dsa-applicants"
          element={
            <Layout>
              <DSAApplicants />
            </Layout>
          }
        />
        <Route
          path="/admin/dsa-applicants/detail/:id"
          element={
            <Layout>
              <DSAApplicantsDetails />
            </Layout>
          }
        />
        <Route
          path="admin/dsa-empanelled"
          element={
            <Layout>
              <DSAEmpanelled />
            </Layout>
          }
        />
        <Route
          path="/admin/dsa-empanelled/detail/:id"
          element={
            <Layout>
              <DSAEmpanelledDetails />
            </Layout>
          }
        />

        {/* <Route path="admin/contracts" element={<Contracts />}>
            <Route index element={<ContractCustomer />} />
            <Route path="admin/contract-epc" element={<ContractEPC />} />
            <Route path="admin/contract-dsa" element={<ContractDSA />} />
          </Route> */}

        <Route
          path="/admin/projects"
          element={
            <Layout>
              <Project />
            </Layout>
          }
        />
        <Route
          path="/admin/project/detail/:id"
          element={
            <Layout>
              <ProjectDetails />
            </Layout>
          }
        />
        <Route
          path="/admin/distributor-leads"
          element={
            <Layout>
              <DistributorLeads />
            </Layout>
          }
        />
      </Routes>
    </>
  );
};

export default AdminRoutes;
