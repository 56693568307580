import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

// import Layout from './AdminDashboard/components/Layout';
// import DashBoard from './AdminDashboard/pages/dashboard/DashBoard';
import AdminRoutes from './AdminDashboard/routes/AdminRoutes';
import './App.css';
import AllRoutes from './FrontEnd/routes/AllRoutes';
import EpcRoutes from './EpcDashboard/routes/EpcRoutes';
import CustomerRoutes from './CustomerDashboard/routes/CustomerRoutes';
import AdminProtectedRoutes from './AdminProtectedRoutes';
import EpcProtectedRoutes from './EPCProtectedRoutes';
import CustomerProtectedRoutes from './CustomerProtectedRoutes';
import ManufacturerRoutes from './ManufacturerDashboard/routes/ManufacturerRoutes';
// import DsaRoutes from "./DSADashboard/routes/DsaRoutes";
import DsaRoutes from './DsaDashboard/routes/DsaRoutes';
import DSAProtectedRoutes from './DSAProtectedRoutes';
import DistributorRoutes from './EpcDashboard/routes/DistributorRoutes';

function App() {
  useEffect(() => {
    // remove all the console logs from production
    if (process.env.NODE_ENV === 'production') {
      console.log = () => {};
    }
  }, []);
  return (
    <>
      <AllRoutes />
      <AdminProtectedRoutes Component={AdminRoutes} />
      <EpcProtectedRoutes Component={EpcRoutes} />
      <EpcProtectedRoutes Component={DistributorRoutes} />
      <CustomerProtectedRoutes Component={CustomerRoutes} />
      <DSAProtectedRoutes Component={DsaRoutes} />
      <ManufacturerRoutes Component={ManufacturerRoutes} />

      {/* {isAdmin & !isEpc ? <AdminRoutes /> :<EpcRoutes />} */}
      {/* <AdminRoutes /> */}
      {/* {isEpc & !isAdmin ? <EpcRoutes /> : <AdminRoutes />} */}
      {/* <EpcRoutes /> */}
      {/* <CustomerRoutes /> */}
      {/* <Layout><DashBoard/></Layout> */}
      <ToastContainer />
    </>
  );
}

export default App;
