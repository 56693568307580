import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import * as queryData from '../../service/dataService';
import Pagination from '../../components/Pagination';
import ChangePassword from '../profile/ChangePassword';

const AppliedApplications = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);

  const {data, isLoading, error} = useQuery(
    'getAllApplicationsOfLoggedInConsumer',
    queryData.getAllApplicationsOfLoggedInConsumer
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  const listItems = [
    {
      status: 'Application received',
      statusCode: 'OPEN',
    },
    {
      status: 'Site visit scheduled',
      statusCode: 'SITEVISITSCHEDULED',
    },
    {status: 'Survey Done', statusCode: 'SURVEYDONE'},
    {status: 'Proposal Shared', statusCode: 'PROPOSALSENT'},
    {status: 'Contract Signed', statusCode: 'PROPOSALACCEPTED'},
    {status: 'Project initiated', statusCode: 'INITIATED'},
    // Add more status and icons here
  ];

  let currentStatusCode = '';

  data &&
    data.data &&
    data.data.data &&
    data.data.data.map(
      (rowData, index) => (currentStatusCode = rowData.status)
    );
  // console.log(currentStatusCode, "////")

  function getEquivalentStatusCode(statusCode) {
    if (statusCode === 'QUOTESHARED') {
      return 'SURVEYDONE';
    } else if (statusCode === 'PASSEDTOEPC') {
      return 'OPEN';
    } else if (statusCode === 'SITEVISITDECLINED') {
      return 'OPEN';
    }
    return statusCode;
  }

  // Assuming you have a variable named 'currentStatusCode' with the current status code
  const equivalentStatusCode = getEquivalentStatusCode(currentStatusCode);

  function getStatusFromStatusCode(statusCode) {
    // Find the status in the listItems
    const foundItem = listItems.find(item => item.statusCode === statusCode);

    if (foundItem) {
      return foundItem.status;
    } else {
      // If not found, get the equivalent status
      const equivalentStatusCode = getEquivalentStatusCode(statusCode);
      const equivalentStatusItem = listItems.find(
        item => item.statusCode === equivalentStatusCode
      );

      if (equivalentStatusItem) {
        return equivalentStatusItem.status;
      } else {
        // If the equivalent status is also not found, return a default status
        return 'Unknown Status';
      }
    }
  }

  const entriesPerPage = 10;
  const totalEntries =
    data && data.data && data.data.data && data.data.data.length;

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };
  return (
    <section>
      <div className="solfin-profile cunsumer-top-space header-space pb-2 pt-1">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <h5 className="fw-bold">Applications - Applied</h5>
              </div>
              <div className="col-12 text-end mb-2">
                <Link
                  to="/customer/new-application"
                  className="btn btn-primary btn-header rounded-3 text-nowrap text-capitalize green-btn calculate-btn pt-2 pb-2 ps-lg-5 ps-4 pe-lg-5 pe-4 ms-4"
                >
                  New
                </Link>
              </div>

              {data.data.data.length > 0 ? (
                <div className="applied-application-data">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Registrant Type</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Financing Required</th>
                          <th>Application Date &amp; Time</th>
                          {/* <th>Loan Status</th> */}
                          <th>Application Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.data &&
                          data.data.data
                            .slice(
                              (activePage - 1) * entriesPerPage,
                              activePage * entriesPerPage
                            )
                            .map((rowData, indexOnPage, index) => {
                              const serialNumber =
                                (activePage - 1) * entriesPerPage +
                                indexOnPage +
                                1;
                              const equivalentStatusCode =
                                getEquivalentStatusCode(rowData.status);
                              return (
                                <tr key={rowData.application_id}>
                                  <td>{serialNumber}</td>
                                  <td>
                                    <Link
                                      to={`/customer/status/${rowData.application_id}`}
                                    >
                                      {rowData.customer_type}
                                    </Link>
                                  </td>
                                  <td>{rowData.city}</td>
                                  <td>{rowData.state}</td>
                                  <td>
                                    {rowData.is_finance_required === 1
                                      ? 'Yes'
                                      : 'No'}
                                  </td>
                                  <td>{rowData.created_on}</td>
                                  <td>
                                    {getStatusFromStatusCode(
                                      rowData.status_code
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    activePage={activePage}
                    totalPages={Math.ceil(totalEntries / entriesPerPage)}
                    onPageChange={handlePageChange}
                  />
                </div>
              ) : (
                'There are no new applications'
              )}
            </div>
            {/* <!-- /col-12 --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /my-products --> */}
    </section>
    //   <!--Products Head end-->
  );
};

export default AppliedApplications;
