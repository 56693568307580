import React, {useEffect, useRef, useState} from 'react';
import LeadTable from '../../../AdminDashboard/pages/leads/components/LeadTable';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getAllEpcProjects} from '../../service/DataService';
import {useNavigate} from 'react-router-dom';
import exportToExcel from '../../../components/ExportToExcel';
import * as dataquery from '../../service/DataService';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Projects = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);

  const {data, isLoading, error} = useQuery(
    'getAllEpcProjects',
    getAllEpcProjects
  );

  const handleNavigate = row => {
    const id = row.row.original.application_project_id;
    navigate(`project-detail/${id}`);
  };
  const updateProjectStatus = useMutation(dataquery.updateProjectStatus, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      queryClient.invalidateQueries('getAllEpcProjects');
    },
  });

  //update installation Date mutation
  const updateInsallationDateMutation = useMutation(
    dataquery.updateInstallationDate,
    {
      onError: error => {
        toast.error(error.message);
      },
      onSuccess(data) {
        toast.success(data.message);
        queryClient.invalidateQueries('getAllEpcProjects');
      },
    }
  );

  const projectStatus = useQuery(
    'getProjectStatusProvidedByAdmin',
    dataquery.getProjectStatusProvidedByAdmin
  );

  const handleChange = (e, row) => {
    // console.log(e.target.value);
    // console.log(row.original.customer_id);
    const statusId = e.target.value;
    const id = row.original.application_project_id;
    row.original.project_status_id = statusId;
    const data = updateProjectStatus.mutate({id, statusId});
    // console.log(data, "status");
  };

  const handleInstallationDate = (e, row) => {
    // console.log(e.target.value);
    const id = row.original.application_project_id;
    // const installationDate = e.target.value
    const installationDate = e;
    console.log(e);
    const data = updateInsallationDateMutation.mutate({id, installationDate});
  };

  const formattedDate = date => {
    if (date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to the month because it's zero-based
      const day = dateObject.getDate().toString().padStart(2, '0');

      // Create the new formatted date string
      const formattedDateString = `${year}-${month}-${day}`;
      return formattedDateString;
    }
    return null;
  };
  const columns = React.useMemo(() => [
    {
      Header: 'Sr. No',
      Cell: (row, index) => {
        return index + 1;
      },
    },
    {Header: 'Project Id', accessor: 'application_project_id'},
    {
      Header: 'Project Name',
      Cell: row => {
        return (
          <button onClick={() => handleNavigate(row)} className="btn text-dark">
            <u>{row.row.original.project_name}</u>
          </button>
        );
      },
    },
    {Header: 'Solution', accessor: 'solution'},
    {Header: 'Capacity', accessor: 'capacity'},
    {Header: 'Project Cost', accessor: 'project_cost'},
    {Header: 'Type of Panel', accessor: 'type_of_panel'},
    {
      Header: 'Installation Date',
      Cell: ({row}) => {
        return (
          // <input
          //   type="date"
          //   onChange={(e) => handleInstallationDate(e,row)}
          //   value={formattedDate(row.original.installation_date)}
          // ></input>
          <DatePicker
            selected={
              row.original.installation_date
                ? new Date(row.original.installation_date)
                : null
            }
            onChange={date => handleInstallationDate(date, row)}
            dateFormat="dd-MM-yyyy" // Adjust the date format as needed
            className="custom-datepicker"
            placeholderText="Select a Date"
          />
        );
      },
    },
    {Header: 'Unit generated per day', accessor: 'estimated_unit_generated'},
    {
      Header: 'Status',
      // maxWidth: 1000,
      // minWidth: 140,
      // width: 1000,
      Cell: ({row}) => (
        <select
          onChange={e => handleChange(e, row)}
          value={row.original.project_status_id}
          className="form-select"
          style={{width: '200px'}}
        >
          {projectStatus &&
            projectStatus.data &&
            projectStatus.data.data &&
            projectStatus.data.data.data &&
            projectStatus.data.data.data.map(data => (
              <option key={data.status_id} value={data.status_id}>
                {data.status}
              </option>
            ))}
        </select>
      ),
    },
  ]);
  // Export To Excel
  const handleExport = () => {
    exportToExcel(
      data.data,
      [
        'project_name',
        'solution',
        'capacity',
        'project_cost',
        'type_of_panel',
        'installation_date',
        'estimated_unit_generated',
      ],
      [
        'Project Name',
        'Solution',
        'Capacity',
        'Project Cost',
        'Type of Panel',
        'Installation Date',
        'Unit generated per day',
      ]
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Assigned Leads</h3>
        </div>
        <LeadTable
          tableName={'List of Projects'}
          columns={columns}
          tableData={data.data}
          handleExport={handleExport}
          cityallowed
          stateallowed
          istartandenddateallowed
          pstatusallowed
        />
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default Projects;
