import {Link, useNavigate, useParams} from 'react-router-dom';
import * as querydata from '../../service/dataService';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {saveAs} from 'file-saver';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';

import {toast} from 'react-toastify';
import QuoteLoanOffer from './components/QuoteLoanOffer';
import ProjectApproveModal from './components/ProjectApproveModal';
// import GoogleMap from "../../components/GoogleMap";

const ApplicationDetailPage = () => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const [isApproved, setIsApproved] = useState(false);
  const [isQuoteApproved, setIsQuoteApproved] = useState(false);
  const [isQuoteRejected, setIsQuoteRejected] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [initiaEpcId, setInitiaEpcId] = useState(null);

  const navigate = useNavigate();
  const createProject = useMutation(querydata.createProject, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      navigate('/admin/projects');
      queryClient.invalidateQueries('allProjects');
      const button = document.querySelector('.approve-project-btn');
      if (button) {
        button.setAttribute('data-bs-dismiss', 'modal');
      }
    },
  });

  const rejectProposal = useMutation(querydata.rejectProposal, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      queryClient.invalidateQueries('getQuoteLoanOffer');
    },
  });
  // variable for file upload

  // const [sanctionLetter, setSanctionLetter] = useState("");
  // const [disbursalLetter, setDisbursalLetter] =useState("")

  const getQuoteLoanOffer = useQuery(['getQuoteLoanOffer', +id], () =>
    querydata.getQuoteLoanOffer(id)
  );

  const quoteData =
    getQuoteLoanOffer &&
    getQuoteLoanOffer.data &&
    getQuoteLoanOffer.data.data &&
    getQuoteLoanOffer.data.data.data === undefined
      ? ''
      : getQuoteLoanOffer &&
        getQuoteLoanOffer.data &&
        getQuoteLoanOffer.data.data &&
        getQuoteLoanOffer.data.data.data;

  const downloadSignedAgreement = e => {
    const image_url =
      getQuoteLoanOffer &&
      getQuoteLoanOffer.data &&
      getQuoteLoanOffer.data.data &&
      getQuoteLoanOffer.data.data.data &&
      getQuoteLoanOffer.data.data.data.sign_loan_agreement;
    saveAs(image_url, 'signed_loan_agreement.jpg');
  };

  const downloadSignedContract = e => {
    const image_url =
      getQuoteLoanOffer &&
      getQuoteLoanOffer.data &&
      getQuoteLoanOffer.data.data &&
      getQuoteLoanOffer.data.data.data &&
      getQuoteLoanOffer.data.data.data.sign_rooftop_solar_contract;
    saveAs(image_url, 'signed_solar_contract.jpg');
  };

  const {data, isLoading, error} = useQuery(['getCustomer', +id], () =>
    querydata.getSingleCustomer(id)
  );

  useEffect(() => {
    //if (data) {

    setInitiaEpcId(data && data.data && data.data.data[0].assigned_to_epc);

    // console.log("initiaEpcId")
    // console.log(initiaEpcId)

    //const dropdown = document.getElementById('epcDropdown');

    // Set the value of the dropdown to the desired option
    // const selectedValue = 'option2'; // Replace this with the desired option value
    // dropdown.value = initiaEpcId;
    //setEPCId(initiaEpcId)
    //}
  }, [data]);

  if (initiaEpcId) {
    const dropdown = document.getElementById('epcDropdown');
    dropdown.value = initiaEpcId;
  }

  //function setEPCId(initiaEpcId){

  //}

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  // values.epc = data && data.data && data.data.data && data.data.data[0].assigned_to_epc
  // console.log(values.epc, "///")

  const handleDownloadElecBill = () => {
    const image_url =
      data &&
      data.data &&
      data.data.data &&
      data.data.data[0] &&
      data.data.data[0].elec_bill_image;
    saveAs(image_url, 'ElectricityBill');
  };

  const cordinates =
    data &&
    data.data &&
    data.data.data &&
    data.data.data[0] &&
    data.data.data[0].google_coordinates;
  const dataMap = {
    google_coordinates: cordinates,
  };

  //   const handleChangeStatus = async (id, statusCode) => {
  //     const updataData = { id: id, statusCode: statusCode }
  //     const data1 = await updateStatusMutation.mutateAsync(updataData);
  // }

  const handleApprove = () => {
    createProject.mutate(id);
    setIsApproved(true);
  };

  const handleReject = () => {
    rejectProposal.mutate(id);
  };

  // value={data.assigned_to_epc ? values.epc = data.assigned_to_epc : values.epc}
  const coordinates =
    data &&
    data.data &&
    data?.data?.data &&
    data.data.data[0].google_coordinates;

  let formattedLatitude = '';
  let formattedLongitude = '';
  if (coordinates) {
    const [latitude, longitude] = coordinates.split(',').map(parseFloat);
    formattedLatitude = latitude.toFixed(2);
    formattedLongitude = longitude.toFixed(2);
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/admin/application">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/admin/application">Application</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Quote & Loan Offer
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-proposal-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-proposal"
                    type="button"
                    role="tab"
                    aria-controls="pills-proposal"
                    aria-selected="false"
                  >
                    Signed Documents
                  </button>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{display: 'block'}}
                  >
                    <form action="#" method="get">
                      {data &&
                        data.data &&
                        data.data.data &&
                        data.data.data.map(data => (
                          // eslint-disable-next-line react/jsx-key
                          <div className="solfin-application-details">
                            <ul>
                              <li>
                                <span className="detail-label">
                                  Registrant Type
                                </span>
                                <span className="detail-value">
                                  {data.customer_type}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">Full Name</span>
                                <span className="detail-value">
                                  {data.customer_name}
                                </span>
                              </li>
                              {data.company_name === null ? (
                                ''
                              ) : (
                                <li>
                                  <span className="detail-label">
                                    Company Name
                                  </span>
                                  <span className="detail-value">
                                    {data.company_name || 'Company Name'}
                                  </span>
                                </li>
                              )}

                              <li>
                                <span className="detail-label">Email</span>
                                <span className="detail-value">
                                  {data.email}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">Phone</span>
                                <span className="detail-value">
                                  {data.phone}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">Address</span>
                                <span className="detail-value">
                                  {data.address1}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">City</span>
                                <span className="detail-value">
                                  {data.city}
                                </span>
                              </li>
                              <li>
                                <span className="detail-label">State</span>
                                <span className="detail-value">
                                  {data.state}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">Pincode</span>
                                <span className="detail-value">
                                  {data.pincode}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">PAN Number</span>
                                <span className="detail-value">{data.pan}</span>
                              </li>

                              <li>
                                <span className="detail-label">
                                  Electricity Bill
                                </span>
                                <span className="detail-value img-uploaded">
                                  {data.elec_bill_image ? (
                                    <a
                                      className="text-dark"
                                      href={data && data.elec_bill_image}
                                      target="_blank"
                                      rel="noreferrer"
                                      onClick={handleDownloadElecBill}
                                    >
                                      <i className="fa-solid fa-download text-dark"></i>
                                      Download
                                    </a>
                                  ) : (
                                    //  <Link
                                    //  to={data.elec_bill_image}
                                    //  target="_blank"
                                    //  >
                                    //   <i className="fa-solid fa-download text-dark"></i>
                                    //    Download
                                    //  </Link>
                                    ''
                                  )}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">
                                  Electricity Consumer No
                                </span>
                                <span className="detail-value">
                                  {data.electricity_consumer_no}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">
                                  Financing Required
                                </span>
                                <span className="detail-value">
                                  {data.is_finance_required === 1
                                    ? 'Yes'
                                    : 'No'}
                                </span>
                              </li>

                              <li>
                                <span className="detail-label">
                                  Location on Map
                                </span>
                                <span className="detail-value">
                                  {formattedLatitude} , {formattedLongitude}
                                </span>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </form>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="tab-box" id="tab-2">
                    <QuoteLoanOffer data={data} />
                  </div>
                  {/* <!-- /end tab --> */}
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-proposal"
                  role="tabpanel"
                  aria-labelledby="pills-proposal-tab"
                  tabIndex="0"
                >
                  <div className="tab-box" id="tab-3">
                    <div className="solfin-application-details">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="proposal-label mb-4">
                            Rooftop Solar Contract
                          </div>
                          <div className="uploaded-file w-100 text-center p-4">
                            {getQuoteLoanOffer &&
                            getQuoteLoanOffer.data &&
                            getQuoteLoanOffer.data.data &&
                            getQuoteLoanOffer.data.data.data &&
                            getQuoteLoanOffer.data.data.data
                              .sign_rooftop_solar_contract ? (
                              <Link
                                className="w-100 text-center p-4"
                                to={
                                  getQuoteLoanOffer &&
                                  getQuoteLoanOffer.data &&
                                  getQuoteLoanOffer.data.data &&
                                  getQuoteLoanOffer.data.data.data &&
                                  getQuoteLoanOffer.data.data.data
                                    .sign_rooftop_solar_contract
                                    ? getQuoteLoanOffer.data.data.data
                                        .sign_rooftop_solar_contract
                                    : 'No uploaded file'
                                }
                                target="_blank"
                              >
                                <img
                                  src="/images/default_file.jpg"
                                  alt="Rooftop Solar Contract"
                                  onClick={downloadSignedContract}
                                  // className="w-25 me-3"
                                />

                                <span className="delete-file">
                                  <i
                                    className="fa fa-circle-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Link>
                            ) : (
                              'No Uploaded File '
                            )}
                          </div>
                        </div>
                        {/* <!-- /col-mnd-6 --> */}
                        <div className="col-md-6">
                          <div className="proposal-label mb-4">
                            Loan Agreement
                          </div>
                          <div className="uploaded-file w-100 text-center p-4">
                            {/* <a className="w-100 text-center p-4" href="images/test.pdf" target="_blank" alt="Quote" title="Quote">
                                                    <img src="../assets/images/test-file.png" alt=""/>
                                                    <span className="delete-file"><i className="fa fa-circle-down" aria-hidden="true"></i></span>
                                                </a> */}
                            {getQuoteLoanOffer &&
                            getQuoteLoanOffer.data &&
                            getQuoteLoanOffer.data.data &&
                            getQuoteLoanOffer.data.data.data &&
                            getQuoteLoanOffer.data.data.data
                              .sign_loan_agreement ? (
                              <Link
                                className="w-100 text-center p-4"
                                to={
                                  getQuoteLoanOffer &&
                                  getQuoteLoanOffer.data &&
                                  getQuoteLoanOffer.data.data &&
                                  getQuoteLoanOffer.data.data.data &&
                                  getQuoteLoanOffer.data.data.data
                                    .sign_loan_agreement
                                    ? getQuoteLoanOffer.data.data.data
                                        .sign_loan_agreement
                                    : 'No File to download'
                                }
                                target="_blank"
                              >
                                <img
                                  alt="Loan Agreement"
                                  src="/images/default_file.jpg"
                                  onClick={downloadSignedAgreement}
                                  // className="w-25 me-3"
                                />
                                <span className="delete-file">
                                  <i
                                    className="fa fa-circle-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </Link>
                            ) : (
                              'No Uploaded File'
                            )}
                          </div>
                          {/* <!-- /uploaded file --> */}
                        </div>
                        {/* <!-- /col-mnd-6 --> */}
                        <div className="col-12">
                          <div className="form-actions">
                            {/* {createProject.isLoading ? (
                              <button
                                class="opacity-100 btn btn-info spinner-btn2"
                                type="button"
                                disabled
                              >
                                <span
                                  class="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span class="visually-hidden" role="status">
                                  Loading...
                                </span>
                              </button>
                            ) : (
                              <button
                                onClick={handleApprove}
                                className={`btn btn-info ${
                                  isApproved ? "disabled" : ""
                                }`}
                                disabled={
                                  isApproved ||
                                  (getQuoteLoanOffer &&
                                    getQuoteLoanOffer.data &&
                                    getQuoteLoanOffer.data.data &&
                                    getQuoteLoanOffer.data.data.data &&
                                    getQuoteLoanOffer.data.data.data
                                      .sign_rooftop_solar_contract === null)
                                    ? true
                                    : false
                                }
                              >
                                {isApproved ? "Approved" : "Approve"}
                              </button>
                            )} */}

                            {/* Modal Button  */}
                            <button
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              className={`btn btn-info ${
                                isApproved ? 'disabled' : ''
                              }`}
                              disabled={
                                isApproved ||
                                (getQuoteLoanOffer &&
                                  getQuoteLoanOffer.data &&
                                  getQuoteLoanOffer.data.data &&
                                  getQuoteLoanOffer.data.data.data &&
                                  getQuoteLoanOffer.data.data.data
                                    .sign_rooftop_solar_contract === null)
                                  ? true
                                  : false
                              }
                            >
                              {isApproved ? 'Approved' : 'Approve'}
                            </button>

                            <ProjectApproveModal
                              handleApprove={handleApprove}
                              createProject={createProject}
                            />

                            {rejectProposal.isLoading ? (
                              <button
                                className="opacity-100 btn btn-info spinner-btn2"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                  Loading...
                                </span>
                              </button>
                            ) : (
                              <button
                                onClick={handleReject}
                                className="btn btn-info filter-btn"
                                disabled={
                                  getQuoteLoanOffer?.data?.data?.data &&
                                  getQuoteLoanOffer.data.data.data.is_rejected
                                }
                              >
                                {getQuoteLoanOffer?.data?.data?.data &&
                                getQuoteLoanOffer.data.data.data.is_rejected
                                  ? 'Rejected'
                                  : 'Reject'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default ApplicationDetailPage;
