import React, {useRef} from 'react';
import {useQuery} from 'react-query';
import * as queryData from '../service/DataService';
import {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Filter = ({
  isFilterActive,
  setFilter,
  setFilteredData,
  tableData,
  cityallowed,
  stateallowed,
  customertypeallowed,
  statusallowed,
  startandenddateallowed,
  areaServedallowed,
  connecttoGridAllowed,
}) => {
  const [stateId, setStateId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [areaservedId, setAreaServedId] = useState(null);
  const [typeId, settypeId] = useState(null);
  const [statusId, setstatusId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [connecttoGrid, setConnectedtoGrid] = useState(null);

  const handleOptionChange = event => {
    setConnectedtoGrid(event.target.value);
  };

  const statedropdownRef = useRef(null);
  const citydropdownRef = useRef(null);
  const areaserveddropdownRef = useRef(null);
  const typedropdownRef = useRef(null);
  const statusdropdownRef = useRef(null);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleFiltersChange = () => {
    const filtered = tableData.filter(item1 => {
      const [date, time] = item1.created_on.split(' ');
      const [day, month, year] = date.split('/');
      const [hour, minute] = time.split(':');

      // Create a new date string in "mm/dd/yyyy" format
      const convertedDateString = `${month}/${day}/${year}`;

      const itemDate = new Date(convertedDateString);
      return (
        (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= new Date(endDate)) &&
        (!areaservedId ||
          item1.service_area
            .toLowerCase()
            .includes(areaservedId.toLowerCase())) &&
        (!cityId || item1.city_id == cityId.toString()) &&
        (!stateId || item1.state_id == stateId.toString()) &&
        (!connecttoGrid || item1.connect_to_grid == connecttoGrid.toString()) &&
        (!typeId || item1.type_id == typeId.toString()) &&
        (!statusId || item1.status_id == statusId.toString())
      );
    });
    setFilteredData(filtered);
  };
  const handleClearFilters = () => {
    if (stateallowed) statedropdownRef.current.value = ''; // Reset the dropdown value
    if (cityallowed) citydropdownRef.current.value = ''; // Reset the dropdown value
    if (customertypeallowed) typedropdownRef.current.value = ''; // Reset the dropdown value
    if (statusallowed) statusdropdownRef.current.value = ''; // Reset the dropdown value
    if (areaServedallowed) areaserveddropdownRef.current.value = ''; // Reset the dropdown value
    setStateId('');
    setCityId('');
    setAreaServedId('');
    settypeId('');
    setstatusId('');
    setFilteredData(tableData);
  };

  const getAllStatesQuery = useQuery('getAllStates', queryData.getAllStates);
  const getAllCitiesQuery = useQuery('getAllCities', queryData.getAllCities);

  const getAllTypesQuery = useQuery(
    'getAllTypes',
    queryData.getAllCustomerTypes
  );
  const getFilteredCities = useQuery(['getCitiesById', +stateId], () =>
    queryData.getCitiesById(+stateId)
  );
  const getAllStatusQuery = useQuery(
    'getAllStatus',
    queryData.getEmpanelledStatus
  );

  if (getFilteredCities.data) {
    // console.log(getFilteredCities.data.data)
  }

  if (getAllStatesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllStatesQuery.error) {
    return <div>Error:</div>;
  }

  if (!getAllStatesQuery.data) {
    return <div>No data fetched.</div>;
  }
  // console.log(getAllStatesQuery.data.data)
  return (
    <>
      <div id="filters" className={`pb-4 ${!isFilterActive ? 'd-none' : ''}`}>
        <div className="row">
          <div className="col-12">
            <div className="row g-3">
              {stateallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">State</label>
                  <select
                    className="form-select solfin-input"
                    ref={statedropdownRef}
                    required
                    onChange={e => setStateId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllStatesQuery.data &&
                      getAllStatesQuery.data.data.map(data => (
                        <>
                          <option value={data.state_id}>{data.state}</option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {cityallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label ">City</label>
                  <select
                    className="form-select  solfin-input"
                    ref={citydropdownRef}
                    required
                    onChange={e => setCityId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getFilteredCities.data &&
                      getFilteredCities.data.data.map(data => (
                        <>
                          <option value={data.city_id}>{data.city}</option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {areaServedallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label  solfin-input">
                    Area Served
                  </label>
                  <input
                    ref={areaserveddropdownRef}
                    onChange={e => setAreaServedId(e.target.value)}
                    className="form-control"
                    placeholder="Search"
                  />
                  {/* <select
                  class="form-select" ref={areaserveddropdownRef}
                  isSearchable
                  required
                  onChange={(e) => setAreaServedId(e.target.value)}
                >
                  <option selected value="">
                    Choose...
                  </option>
                  {getAllCitiesQuery.data && getAllCitiesQuery.data.data.map(data => (
                    <>
                      <option value={data.city_id}>{data.city}</option>
                    </>
                  ))}
                </select> */}
                </div>
              ) : (
                ''
              )}
              {customertypeallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label  solfin-input">Type</label>
                  <select
                    className="form-select"
                    ref={typedropdownRef}
                    required
                    onChange={e => settypeId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllTypesQuery.data &&
                      getAllTypesQuery.data.data.map(data => (
                        <>
                          <option
                            id={data.customer_type_id}
                            key={data.customer_type_id}
                            value={data.customer_type_id}
                          >
                            {data.customer_type}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {statusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label  solfin-input">Status</label>
                  <select
                    className="form-select"
                    ref={statusdropdownRef}
                    required
                    onChange={e => setstatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllStatusQuery.data &&
                      getAllStatusQuery.data.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {startandenddateallowed ? (
                <>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date To</label>
                    <DatePicker
                      className="solfin-input"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date From</label>
                    <DatePicker
                      className="solfin-input"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </>
              ) : (
                ''
              )}

              {connecttoGridAllowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label checklabel">
                    Connect to Grid
                  </label>
                  <div>
                    <label className="me-3">
                      <input
                        className="me-2"
                        type="radio"
                        value="Yes"
                        checked={connecttoGrid === 'Yes'}
                        onChange={handleOptionChange}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        className="me-2"
                        type="radio"
                        value="No"
                        checked={connecttoGrid === 'No'}
                        onChange={handleOptionChange}
                      />
                      No
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isconnected"
                    value="yes"
                    id="flexRadioDefault1"
                    checked={radioChecked === "yes"}
                    onChange={handleIsConnected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="no"
                    name="isconnected"
                    checked={radioChecked === "no"}
                    id="flexRadioDefault2"
                    onChange={handleIsConnected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    No
                  </label>
                </div> */}
              <div className="d-flex align-items-center justify-content-end mt-3">
                <button
                  onClick={() => handleFiltersChange()}
                  className="btn btn-info me-2"
                  tabIndex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                >
                  <span>
                    <span className="d-none d-sm-inline-block">Search</span>
                  </span>
                </button>
                <button
                  onClick={() => handleClearFilters()}
                  className="btn btn-info filter-btn"
                  tabIndex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                >
                  <span>
                    <span className="d-none d-sm-inline-block">Reset</span>
                  </span>
                </button>
              </div>
              {/* <div className="col-12 col-sm-6 col-lg-4">
                <label className="form-label">Date From</label>
                <input
                  type="date"
                  className="form-control dt-input"
                  data-column="4"
                  data-column-index="3"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Start Date"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <label className="form-label">Date To</label>
                <div className="mb-0">
                  <input
                    type="date"
                    className="form-control dt-date flatpickr-range dt-input"
                    data-column="5"
                    placeholder="EndDate"
                    data-column-index="4"
                    name="dt_date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  /> */}
              {/* <input
                    type="hidden"
                    className="form-control dt-date start_date dt-input"
                    data-column="5"
                    data-column-index="4"
                    name="value_from_start_date"
                  />
                  <input
                    type="hidden"
                    className="form-control dt-date end_date dt-input"
                    name="value_from_end_date"
                    data-column="5"
                    data-column-index="4"
                  /> */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-0" />
    </>
  );
};

export default Filter;
