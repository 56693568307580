import React, {useEffect} from 'react';
import {useState} from 'react';
import {
  QueryCache,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {useNavigate} from 'react-router-dom';
import LeadTable from '../leads/components/LeadTable';
import {
  getNewAndRejectedDsa,
  updateDsaArchive,
} from '../../service/dataService';
import Export from '../../../components/ExportToExcel';
import {toast} from 'react-toastify';
import * as dataquery from '../../service/dataService';
import DeleteModal from '../../components/DeleteModal';
import DeleteEpcModal from '../../components/DeleteEpcModal';
import DeleteDsaModal from '../../components/DeleteDsaModal';

const DSAApplicants = () => {
  //Export To Excel
  const handleExport = () => {
    Export(
      data && data.data,
      [
        'dsa_name',
        'email',
        'phone',
        'address1',
        'pincode',
        'city',
        'state',
        'created_on',
        'service_area',
        'published_on',
        'status',
      ],
      [
        'CompanyName',
        'Email',
        'Phone',
        'Address',
        'Pincode',
        'City',
        'State',
        'Date & Time',
        'Service Area',
        'Published Date',
        'Status',
      ]
    );
  };
  const [modalData, setModalData] = useState();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = row => {
    const id = row.original.dsa_id;
    navigate(`/admin/dsa-applicants/detail/${id}`);
  };
  const queryClient = useQueryClient();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sr. No',
        accessor: 'srNo',
        // Cell: (row, index) => { return index + 1 }
      },
      {Header: 'Company Name', accessor: 'dsa_name'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Phone', accessor: 'phone'},
      {Header: 'Address', accessor: 'address1'},
      {Header: 'Pincode', accessor: 'pincode'},
      {Header: 'City', accessor: 'city'},
      {Header: 'State', accessor: 'state'},
      {Header: 'Activites', accessor: 'dsa_activity'},
      {Header: 'Experience', accessor: 'experience'},
      {Header: 'Date & Time', accessor: 'created_on'},
      {
        Header: 'Service Area',
        accessor: 'service_area',
        Cell: ({value}) => {
          const states = value.split(', ');
          const primaryState = states[0];
          const otherStates = states.slice(1);

          return (
            <div>
              <span>{primaryState}</span>
              {otherStates.length > 0 && (
                <span
                  title={otherStates.join(', ')}
                  style={{cursor: 'pointer', color: '#4da802'}}
                >
                  {' '}
                  +{otherStates.length} more..
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: row => {
          return <span>{row.row.original.status}</span>;
        },
      },
      {
        Header: 'Actions',
        Cell: ({row}) => (
          <td>
            <div className="text-nowrap">
              <button
                onClick={() => handleNavigate(row)}
                className="btn btn-sm btn-info icon-btn"
                title="Details"
              >
                <i className="fa fa-info" aria-hidden="true"></i>
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target={`#id${row.original.dsa_id}`}
                // onClick={() => handleArchive(row)}
                className="ms-2 btn btn-sm btn-danger"
                title="Delete"
              >
                <i className="fa fa-box-archive"></i>
                <DeleteDsaModal row={row} />
              </button>
            </div>
          </td>
        ),
      },
    ],
    []
  );

  const {data, isLoading, error} = useQuery(
    'newAndRejectedDsa',
    getNewAndRejectedDsa
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data) {
    return <div>No data fetched.</div>;
  }
  // console.log(data)
  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>DSA Applicants</h3>
        </div>
        <LeadTable
          tableName={'List of DSA Applicants'}
          columns={columns}
          tableData={data && data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          areaServedallowed
          epcstatusallowed
          startandenddateallowed
          acallowed
          exallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DSAApplicants;
