import React, {useEffect} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  changeCustomerStatus,
  getAllCustomer,
  getCustomerStatus,
} from '../../service/dataService';
import LeadTable from '../leads/components/LeadTable';
import {useState} from 'react';
import Export from '../../../components/ExportToExcel';

const Customer = () => {
  const navigate = useNavigate();

  const handleExport = () => {
    //console.log(data.data);
    Export(
      data && data.data.data,
      ['customer_name', 'email', 'phone', 'city', 'state', 'status'],
      ['Name', 'Email', 'Phone', 'City', 'State', 'Status']
    );
  };

  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleNavigate = row => {
    const id = row.original.customer_id;
    navigate(`details/${id}`);
  };

  const customerStatus = useQuery('getCustomerStatus', getCustomerStatus);
  useEffect(() => {
    customerStatus.refetch();
  }, []);

  const {data, isLoading, error} = useQuery('getAllCustomer', getAllCustomer);

  //For invalidate queries
  const cach = useQueryClient();
  const changeCustomerStatusMutate = useMutation(changeCustomerStatus, {
    onError: error => {
      toast.error(error);
    },
    onSuccess(data) {
      // Wiconsole.log(data);ll be called 3 times
      toast.success(data.message);
      cach.invalidateQueries({queryKey: ['getAllCustomer']});
      // console.log("after invalidating all customers");
      navigate('/admin/customers');
      // console.log("Succesful",{data});
    },
    onSettled: () => {
      cach.invalidateQueries({queryKey: ['getAllCustomer']});
      // console.log("on Settled invalding");
    },
  });

  const handleChange = async (e, row) => {
    // console.log(e.target.value);
    // console.log(row.original.customer_id);
    const statusId = e.target.value;
    // console.log(statusId);
    const id = row.original.customer_id;
    row.original.status_id = statusId;
    const data = await changeCustomerStatusMutate.mutateAsync({id, statusId});
    // console.log(data);
  };
  const columns = React.useMemo(
    // eslint-disable-next-line no-sparse-arrays
    () => [
      {Header: 'Sr. No', accessor: 'srNo'},
      {Header: 'Customer Id', accessor: 'customer_id'},
      ,
      // { Header: "Customer Id", accessor: "customer_id" },
      {
        Header: 'Name',
        Cell: ({row}) => (
          <div>
            <button
              className="btn text-dark ms-n3"
              onClick={() => handleNavigate(row)}
            >
              {row.original.customer_name}
            </button>
          </div>
        ),
      },
      // { Header: "State", accessor: "state" },
      // { Header: "City", accessor: "city" },
      {Header: 'Email', accessor: 'email'},
      {Header: 'Phone', accessor: 'phone'},
      {Header: 'No. of Projects', accessor: 'total_projects'},

      // { Header: "No Of Projects" },

      //   {
      //     Header: "Status",
      //     Cell: (row) => {
      //       return (
      //         <span class="badge bg-label-primary me-1">
      //           {row.row.original.status}
      //         </span>
      //       );
      //     },
      //   },
      {
        Header: 'Status',
        Cell: ({row}) => (
          <select
            onChange={e => handleChange(e, row)}
            value={row.original.status_id}
            className="form-select"
          >
            {customerStatus.data &&
              customerStatus.data.map(data => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>
        ),
      },
    ],
    [customerStatus.data]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Customer</h3>
        </div>
        <LeadTable
          tableName={'List of Customers'}
          columns={columns}
          tableData={data.data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          noofprojectsAllowed
          customerstatusallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default Customer;
