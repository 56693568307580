import React from 'react';
import LeadTable from '../../AdminDashboard/pages/leads/components/LeadTable';
import {useMutation, useQuery} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {
  applicationStatusProvidedByEpc,
  allAssignApplicationToEPC,
  changeApplicationStatus,
  uploadQuoteInApplication,
} from '../service/DataService';
import {useEffect} from 'react';
import {toast} from 'react-toastify';
import {getApplicationStatus} from '../../AdminDashboard/service/dataService';
import exportToExcel from '../../components/ExportToExcel';
import * as dataquery from '../service/DataService';
import {saveAs} from 'file-saver';
import {test} from '../../assets/images/test-file.png';

const AssignedLeads = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [file, setfile] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const getapplicationStatusProvidedByEpc = useQuery(
    'applicationStatusProvidedByEpc',
    applicationStatusProvidedByEpc
  );
  const {data, isLoading, error} = useQuery(
    'allAssignApplicationToEPC',
    allAssignApplicationToEPC
  );

  useEffect(() => {
    getapplicationStatusProvidedByEpc.refetch();
  }, []);

  const changeApplicationStatusMutate = useMutation(changeApplicationStatus, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      // console.log("Succesful",{data});
      // navigate("/admin/application");
    },
  });
  const uploadQuoteInApplication = useMutation(
    dataquery.uploadQuoteInApplication,
    {
      onError: error => {
        toast.error(error.message);
      },
      onSuccess(data) {
        toast.success(data.message);
      },
    }
  );

  //set file and file Name
  const handleFileUpload = (file, row) => {
    const formData = new FormData();
    formData.append('quoteDocument', file);
    const id = row.original.application_id;
    uploadQuoteInApplication.mutate({id, formData});
  };
  const handlefilechange = (e, row) => {
    const selectedFile = e.target.files[0];
    setfile(selectedFile);
    handleFileUpload(selectedFile, row);
    document.getElementById(`UploadQuote_${row.index}`).value =
      selectedFile.name;
  };

  // const handlefilechange = (e, row) => {
  //   setfile(e.target.files[0]);
  //   const id = row.original.application_id;
  //   const updatedData =  uploadQuoteInApplication.mutate({id, file});
  //   document.getElementById("UploadQuote").value = e.target.files[0].name;
  // }

  const downloadImage = row => {
    const image_url = row.original.quote;
    // console.log(image_url,"..")
    saveAs(image_url, 'quote');
  };

  const deleteImage = row => {
    setIsDeleted(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sr. No',
        Cell: (row, index) => {
          return index + 1;
        },
      },
      {Header: 'Name', accessor: 'customer_name'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Phone', accessor: 'phone'},
      {Header: 'Address', accessor: 'address1'},
      {Header: 'City', accessor: 'city'},
      {Header: 'State', accessor: 'state'},
      {Header: 'Date & Time', accessor: 'created_on'},
      {
        Header: 'Status',
        Cell: ({row}) => (
          <select
            onChange={e => handleStatus(e, row)}
            value={row.original.status_id}
            className="form-select"
            style={{width: '200px'}}
          >
            {getapplicationStatusProvidedByEpc &&
              getapplicationStatusProvidedByEpc?.data &&
              getapplicationStatusProvidedByEpc?.data?.data &&
              getapplicationStatusProvidedByEpc?.data?.data?.map(data => (
                <option key={data.status_id} value={data.status_id}>
                  {data.status}
                </option>
              ))}
          </select>
        ),
      },

      {
        Header: 'Add Quote',
        Cell: ({row}) => (
          <Link
            to={`/epc-dashboard/assigned-leads/add-quote/${row.original.application_id}`}
            id="quote"
            className="dropdown-item"
          >
            {row.original.application_proposal_id === null ? (
              <i className="fa-solid fa-circle-plus"></i>
            ) : (
              // <span
              //   className={`quote  ${
              //     row.original.is_epc_quote_rejected !== null ? "rejected" : ""
              //   }`}
              // >
              <span
                className={`quote ${
                  row.original.is_epc_quote_rejected === null
                    ? ''
                    : row.original.is_epc_quote_rejected
                      ? 'rejected'
                      : ''
                }`}
              >
                {row.original.is_epc_quote_rejected === null
                  ? 'Quote Shared'
                  : row.original.is_epc_quote_rejected
                    ? 'Quote Rejected'
                    : 'Quote Shared'}
              </span>
            )}
          </Link>
          //     <>
          //       <div className="uploaded-quote">

          //      {row.original.quote ? (
          //       <span>
          //          {!isDeleted ? (
          //           <span>

          //           <a
          //             href={row.original.quote}
          //             target="_blank"
          //             rel="noopener noreferrer"
          //             alt="Quote"
          //             title="Quote"
          //             onClick={() => downloadImage(row)}
          //           >
          //             <img
          //               src="/images/test-file.png"
          //               alt="Quote"
          //             />
          //           </a>
          //           <span className="delete-quote">
          //           <a onClick={deleteImage(row)}> <i className="fa fa-close" aria-hidden="true" ></i></a>
          //            </span>
          //            </span>

          //          ) : (
          //           <label htmlFor={`uploadQuoteInput_${row.index}`}>
          //             <input
          //               type="file"
          //               name="UploadQuote"
          //               id={`uploadQuoteInput_${row.index}`}
          //               onChange={(e) => handlefilechange(e, row)}
          //               style={{ display: "none" }}
          //             />
          //             <i className="fa fa-upload" aria-hidden="true"  ></i> {" "}Upload
          //             <input
          //                 type="text"
          //                 name={`UploadQuote_${row.index}`}
          //                 id={`UploadQuote_${row.index}`}
          //                 className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          //                 readOnly
          //             />
          //           {/* { row.original.quote || 'Upload Quote'} */}
          //           </label>
          //          )}
          //           </span>

          //      ): (
          // <label htmlFor={`uploadQuoteInput_${row.index}`}>
          //   <input
          //     type="file"
          //     name="UploadQuote"
          //     id={`uploadQuoteInput_${row.index}`}
          //     onChange={(e) => handlefilechange(e, row)}
          //     style={{ display: "none" }}
          //   />
          //   <i className="fa fa-upload" aria-hidden="true"  ></i> {" "}Upload
          //   <input
          //        type="text"
          //        name={`UploadQuote_${row.index}`}
          //        id={`UploadQuote_${row.index}`}
          //        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
          //        readOnly
          //   />
          //  {/* { row.original.quote || 'Upload Quote'} */}
          // </label>
          //      )}
          //                  </div>

          //      </>
        ),
      },
    ],
    [getapplicationStatusProvidedByEpc.data]
  );
  // Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    exportToExcel(
      data.data,
      [
        'customer_name',
        'email',
        'phone',
        'address1',
        'city',
        'state',
        'status',
      ],
      ['CompanyName', 'Email', 'Phone', 'Address', 'City', 'State', 'Status']
    );
  };
  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();

  const navigate = useNavigate();

  if (!getapplicationStatusProvidedByEpc.data) {
    return <>Loading...</>;
  }
  const handleStatus = (e, row) => {
    const statusId = e.target.value;
    const id = row.original.application_id;
    row.original.status_id = statusId;
    const data = changeApplicationStatusMutate.mutate({id, statusId});
  };

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  if (!getapplicationStatusProvidedByEpc.data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Assigned Leads</h3>
        </div>
        <LeadTable
          pageNumber={0}
          tableName={'List of Assigned Leads'}
          columns={columns}
          tableData={data && data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          startandenddateallowed
          pepcstatusallowed
          // areaServedallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default AssignedLeads;
