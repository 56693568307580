import {Link} from 'react-router-dom';

import './style.css';

const LogoSection = ({logo, toggleMenu, isMenuOpen}) => (
  <div className="col navigation">
    <div className="solfin-logo">
      <Link to="/customer-dashboard">
        <img src={logo} alt="Solfin" title="Solfin" />
      </Link>
    </div>
    <button
      className="navbar-toggler d-xl-none d-xl-block"
      type="button"
      onClick={toggleMenu}
      aria-expanded={isMenuOpen ? 'true' : 'false'}
      aria-label="Toggle navigation"
    >
      <span className="fa fa-bars"></span>
    </button>
  </div>
);

export default LogoSection;
