import {
  getDocumentPresignedUrl,
  raiseRequestForComfortLetter,
} from '../../../service/dataService';
import {toast} from 'react-toastify';
import {useMutation, useQuery, useQueryClient} from 'react-query';

const DistributorComfortLetterAction = ({
  distributorId,
  comfortLettersList,
  getComfortLettersForDistributor,
}) => {
  const queryClient = useQueryClient();
  const viewComfortLetter = async (e, documentCode) => {
    try {
      // Replace with your actual API endpoint
      const response = await getDocumentPresignedUrl(documentCode);
      if (response.data && response.data.preSignedUrl) {
        window.open(response.data.preSignedUrl, '_blank');
      } else {
        toast.error('Error fetching file details, Please try again.');
      }
    } catch (error) {
      console.error('Error fetching the file URL:', error);
    }
  };

  const raiseComfortLetterRequest = async () => {
    const response = await raiseRequestForComfortLetter(distributorId);
    if (response && response.success == true) {
      toast.success('Comfort letter request has been generated');
      queryClient.invalidateQueries(getComfortLettersForDistributor);
    } else {
      toast.error(
        'Unable to raise comfort letter request, Please try after some time'
      );
    }
    console.log(response);
  };
  return !comfortLettersList || comfortLettersList.length == 0 ? (
    <>
      <button
        onClick={raiseComfortLetterRequest}
        className={'btn btn-info filter-btn'}
      >
        Request Comfort Letter
      </button>
    </>
  ) : comfortLettersList[0].statusEnum == 'REQUESTED' || comfortLettersList[0].statusEnum == 'PENDING_ON_SALES' || comfortLettersList[0].statusEnum == 'PENDING_ON_USER' ? (
    'Requested'
  ) : (
    <button
      className="btn text-dark"
      onClick={e => {
        viewComfortLetter(
          e,
          comfortLettersList[0].uploadedDocumentReferenceCode
        );
      }}
    >
      {' '}
      <i className="fa-solid fa-download"></i> Download
    </button>
  );
};

export default DistributorComfortLetterAction;
