import {Route, Routes} from 'react-router-dom';

import Status from '../pages/status/Status';
import Profile from '../pages/profile/Profile';
import Projects from '../pages/projects/Projects';
import ComingSoon from '../pages/comingSoon/ComingSoon';
import ChangePassword from '../pages/profile/ChangePassword';
import NewApplication from '../pages/applications/NewApplication';
import CustomerLayout from '../../FrontEnd/components/CustomerLayout';
import AppliedApplications from '../pages/applications/AppliedApplications';
import RegistrationComplete from '../pages/registrationComplete/RegistrationComplete';

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route element={<CustomerLayout />}>
        <Route path="/customer-dashboard" element={<AppliedApplications />} />
        <Route path="/customer/new-application" element={<NewApplication />} />
        <Route path="/customer/projects" element={<Projects />} />
        <Route path="/customer/status/:id" element={<Status />} />
        <Route path="/customer/monitoring" element={<ComingSoon />} />
        <Route path="/customer/profile" element={<Profile />} />
      </Route>
      <Route path="/customer/change-password" element={<ChangePassword />} />
      <Route
        path="/customer/registration-complete"
        element={<RegistrationComplete />}
      />
    </Routes>
  );
};

export default CustomerRoutes;
