import React from 'react';

const ComingSoon = () => {
  return (
    <section className="saving-calculator header-space">
      <div className="container custom-container">
        <div className="row align-items-center text-center text-md-start">
          <div className="col-12 col-md-5 mb-4 mb-md-0">
            <div className="coming-soon-page">
              <img
                src="../images/coming-soon.svg"
                alt="Coming Soon Illustration"
                title="Coming Soon"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-12 col-md-5">
            <h1 className="fw-bold heading-color">
              Coming <span className="text-theme-green">Soon</span>...
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComingSoon;
