import React, {useState} from 'react';
import * as dataquery from '../service/DataServices';
import {useQuery} from 'react-query';

import LeadTable from '../../AdminDashboard/pages/leads/components/LeadTable';
import Export from '../../components/ExportToExcel';
import {useNavigate} from 'react-router-dom';
import copy from 'copy-to-clipboard';

const Leads = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();
  const handleExport = () => {
    Export(
      data.data.data,
      [
        'customer_name',
        'email',
        'phone',
        'address1',
        'is_finance_required',
        'elec_bill_date',
        'city',
        'state',
        'status',
        'customer_type',
        'location_type',
        'elec_bill_date',
        'avg_consumption',
        'elec_bill_image',
        'electricity_consumer_no',
        'electricity_rate',
        'area_available',
        'consumption_to_shift',
        'connect_to_grid',
        'estimated_project_cost',
        'created_on',
        'google_coordinates',
      ],
      [
        'CustomerName',
        'Email',
        'Phone',
        'Address',
        'IsFinanceRequired',
        'Elec_Bill_Date',
        'City',
        'State',
        'Status',
        'CustomerType',
        'LocationType',
        'Elec_Bill Date',
        'Avg_Consumption',
        'Elec_Bill_Image',
        'Electricity_ConsumerNo.',
        'ElectricityRate',
        'Area_Available',
        'ConsumptionShift',
        'ConnectToGrid',
        'Estimated_Project_Cost',
        'Created_On',
        'GoogleCoordinates',
      ]
    );
  };
  const handleNavigate = row => {
    const applicationId = row.original.application_id;
    navigate(`/dsa-dashboard/leads-detail/${applicationId}`);
  };
  const columns = React.useMemo(
    () => [
      {Header: 'Sr. No', accessor: 'srNo'},
      //{ Header: "Customer Id", accessor: "customer_id" },
      {
        Header: 'Name',
        Cell: ({row}) => (
          <div>
            <button
              className="btn text-dark ms-n3"
              onClick={() => handleNavigate(row)}
            >
              {row.original.customer_name}
            </button>
          </div>
        ),
      },
      {Header: 'Type', accessor: 'customer_type'},
      {Header: 'State', accessor: 'state'},
      {Header: 'City', accessor: 'city'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Phone', accessor: 'phone'},
      {Header: 'Date & Time', accessor: 'created_on'},
      {
        Header: 'Financing Required',
        Cell: row => {
          if (row.row.original.is_finance_required) {
            return <span>Yes</span>;
          } else {
            return <span>No</span>;
          }
        },
      },
      {Header: 'Status', accessor: 'status'},
    ],
    []
  );

  const generateLeadFormLink = () => {
    console.log(generateLinkQuery.data);
    console.log('clicked');
    const link = generateLinkQuery.data.link;
    // const delimiter = "/register?t=";
    // const queryString = link.substring(link.indexOf(delimiter))
    // console.log(queryString)
    // navigate(queryString)
    window.open(link, '_blank');
  };

  const handleCopyToClipboard = () => {
    const link = generateLinkQuery.data.link;
    copy(link);
  };
  const generateLinkQuery = useQuery(
    'getLeadFormLink',
    dataquery.getMyRegisterLink
  );

  const {data, isLoading, error} = useQuery(
    'getAllDsaLeads',
    dataquery.getAllLeads
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!data) {
    return <div>No data fetched.</div>;
  }
  console.log(data.data);
  return (
    <>
      <div className="content-body">
        <div className=" d-flex justify-content-between">
          <div className="page-title">
            <h3>Applications</h3>
          </div>
        </div>
        <LeadTable
          pageNumber={0}
          tableName={'List of Applications'}
          columns={columns}
          tableData={data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          startandenddateallowed
          statusallowed
        />
      </div>
    </>
  );
};

export default Leads;

// <>
// <div className="content-body">
//                 <div className="page-title">
//                     <h3>My Leads</h3>
//                 </div>

//                 <div className="dash-card">
//                     <div className="dash-card-header flex-wrap card-flexbox">
//                         <h6>Leads List</h6>
//                         <div className="table-right-side card-flexbox">
//                             <Link
//                              href="#" className="btn btn-info">Export</Link>
//                             <Link href="#" className="btn btn-info filter-btn" id="button">Filter</Link>
//                             <form action="#" method="get" className="card-flexbox">
//                                 <div className="form-group">
//                                     <input type="text" placeholder="Search" className="solfin-input"/>
//                                     <button type="submit" className="btn btn-sm btn-info search-btn"><i className="fa fa-search"></i></button>
//                                 </div>
//                             </form>
//                         </div>
//                         <div id="filter-wrapper" className="">
//                             <div id="filters">
//                                 <form action="#" method="get">
//                                     <div className="form-wrap">
//                                         <div className="form-group">
//                                             <label htmlFor="City">City</label>
//                                             <input type="text" id="City" className="solfin-input"/>
//                                         </div>
//                                         <div className="form-group">
//                                             <label htmlFor="Type">Status</label>
//                                             <select className="solfin-input" id="Type">
//                                                 <option selected="Site Survey Complete" value="1">Site Survey Complete</option>
//                                                 <option value="2">Final Quote Shared</option>
//                                             </select>
//                                         </div>
//                                         <div className="form-group w-100">
//                                             <div className="submit-filter">
//                                                 <input type="submit" className="btn btn-info" value="Search"/>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                           </div>
//                     </div>

//                     <div className="dash-card-body">
//                         <div className="table-responsive">
//                             <table className="table">
//                                 <thead>
//                                     <tr>
//                                         <th><input type="checkbox" name="" id=""/></th>
//                                         <th>Sr. No.<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>Name<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>City<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>State<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>Email<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>Phone<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                         <th>Status<Link className="data-sort"><i className="fa fa-sort"></i></Link></th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>1</td>
//                                         <td>John Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>2</td>
//                                         <td>Jane Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-warning">Final Quote Shared</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>3</td>
//                                         <td>John Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>4</td>
//                                         <td>Jane Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-warning">Final Quote Shared</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>5</td>
//                                         <td>John Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>6</td>
//                                         <td>Jane Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>7</td>
//                                         <td>John Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-warning">Final Quote Shared</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>8</td>
//                                         <td>Jane Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>9</td>
//                                         <td>John Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                     <tr>
//                                         <td><input type="checkbox" name="" id=""/></td>
//                                         <td>10</td>
//                                         <td>Jane Deo</td>
//                                         <td>Panchkula</td>
//                                         <td>Haryana</td>
//                                         <td>test@test.com</td>
//                                         <td>0172 4649954</td>
//                                         <td><Link href="#" className="btn btn-sm btn-info">Site Survey Complete</Link></td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>

//                         <div className="solfin-pagination">
//                             <ul>
//                                 <li className="disabled"><Link href="#"><i className="fa-solid fa-angle-left"></i></Link></li>
//                                 <li className="active"><Link href="#">1</Link></li>
//                                 <li><Link href="#">2</Link></li>
//                                 <li><Link href="#">3</Link></li>
//                                 <li><Link href="#">4</Link></li>
//                                 <li><Link href="#">5</Link></li>
//                                 <li><Link href="#"><i className="fa-solid fa-angle-right"></i></Link></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>

//             </div>

//             <div className="modal" role="alert">
//     <div className="modal-content">
//         <div className="modal-header">
//             <h4>Estimator Details</h4>
//             <button className="close" role="button"><i className="fa fa-close"></i></button>
//         </div>
//         <div className="modal-body">
//             <ul className="points-list">
//                 <li>Avg. units consume/month</li>
//                 <li>Discome rate</li>
//                 <li>Rooftop area available</li>
//                 <li>%age of consumption to be shifted to solar - connect to grid - <strong>Yes/No</strong></li>
//             </ul>
//         </div>
//     </div>
//     </div>

// </>
