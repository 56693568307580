import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import RouteWrapper from './RouteWrapper';

const Header = lazy(() => import('../components/Header'));
const Register = lazy(() => import('../pages/register/Register'));
const Dsa = lazy(() => import('../pages/dsa/Dsa'));
const Epc = lazy(() => import('../pages/epc/Epc'));
const Home = lazy(() => import('../pages/home/Home'));
const Aboutus = lazy(() => import('../pages/aboutus/AboutUs'));
const Estimator = lazy(() => import('../pages/estimator/Estimator'));
const Termsandconditions = lazy(
  () => import('../pages/termsandconditions/Termsandconditions')
);
const PartnerRegistrationComplete = lazy(
  () =>
    import('../pages/partnerregistrationcomplete/PartnerRegistrationComplete')
);
const RegistrationComplete = lazy(
  () => import('../pages/registrationcomplete/RegistrationComplete')
);
const HomePageAdditions = lazy(() => import('../components/HomePageAdditions'));
const MySavings = lazy(() => import('../pages/estimator/MySavings'));
const Login = lazy(() => import('../pages/login/Login'));
const ComingSoon = lazy(() => import('../pages/comingSoon/ComingSoon'));
const Mission = lazy(() => import('../pages/others/Mission'));
const PremiumPartners = lazy(
  () => import('../pages/premiumPartners/PremiumPartners')
);
const ResidentialLoanProducts = lazy(
  () => import('../pages/loan/LoanProducts')
);
const Knowledge = lazy(() => import('../pages/knowledge/Knowledge'));
const ForgotPassword = lazy(() => import('../pages/login/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/login/ResetPassword'));
const NewCustomerRegisterComplete = lazy(
  () => import('../pages/registrationcomplete/NewCustomerRegisterComplete')
);
const OldCustomerRegisterComplete = lazy(
  () => import('../pages/registrationcomplete/OldCustomerRegisterComplete')
);
const CustomerHeader = lazy(() => import('../components/CustomerHeader'));
const Lease = lazy(() => import('../pages/loan/Lease'));
const RefundPolicy = lazy(() => import('../pages/refundpolicy/RefundPolicy'));
const CookiePolicy = lazy(() => import('../pages/cookiePolicy/cookiePolicy'));
const WantFinance = lazy(() => import('../pages/finance/WantFinance'));
const DhanvikasPrivacyPolicy = lazy(
  () => import('../pages/privacypolicy/DhanvikasPrivacypolicy')
);
const XsPrivacyPolicy = lazy(
  () => import('../pages/privacypolicy/XSFinanacePrivacypolicy')
);
const DistributorJoinV2 = lazy(
  () => import('../pages/distributor/DistributorJoin')
);
const FairPracticeCode = lazy(
  () => import('../pages/fairPracticeCode/FairPracticeCode')
);
const GrievanceRedressalPolicy = lazy(
  () => import('../pages/grievanceRedressalPolicy/GrievanceRedressalPolicy')
);

const ContactSales = lazy(() => import('../pages/contactSales/ContactSales'));
const ContactSalesForLease = lazy(
  () => import('../pages/contactSales/ContactSalesLease')
);

const AllRoutes = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route
          path="/"
          element={
            <RouteWrapper>
              <Home />
              <HomePageAdditions />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/Register"
          element={
            <RouteWrapper>
              <Register />
            </RouteWrapper>
          }
        />
        <Route
          path="/finance"
          element={
            <RouteWrapper>
              <WantFinance />
            </RouteWrapper>
          }
        />
        <Route
          exact
          path="/RegisterCustomer"
          element={
            <>
              <CustomerHeader />
              <Register />
            </>
          }
        />
        <Route
          path="/PremiumPartners"
          element={
            <RouteWrapper>
              <PremiumPartners />
            </RouteWrapper>
          }
        />
        <Route
          path="/CookiePolicy"
          element={
            <RouteWrapper>
              <CookiePolicy />
            </RouteWrapper>
          }
        />

        <Route
          path="/Epc"
          element={
            <RouteWrapper>
              <Epc />
            </RouteWrapper>
          }
        />
        <Route
          path="/Dsa"
          element={
            <RouteWrapper>
              <Dsa />
            </RouteWrapper>
          }
        />
        <Route
          path="/Aboutus"
          element={
            <RouteWrapper>
              <Aboutus />
            </RouteWrapper>
          }
        />
        <Route
          path="/Estimator"
          element={
            <RouteWrapper>
              <Estimator />
            </RouteWrapper>
          }
        />
        <Route
          path="/MySavings"
          element={
            <RouteWrapper>
              <MySavings />
            </RouteWrapper>
          }
        />
        <Route
          path="/loan-products/residential"
          element={
            <RouteWrapper>
              <ResidentialLoanProducts />
            </RouteWrapper>
          }
        />
        <Route
          path="/knowledge"
          element={
            <RouteWrapper>
              <Knowledge />
            </RouteWrapper>
          }
        />

        <Route
          path="/Privacypolicy"
          element={
            <RouteWrapper>
              <XsPrivacyPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path="/DhanvikasPrivacypolicy"
          element={
            <RouteWrapper>
              <DhanvikasPrivacyPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path="/Refundpolicy"
          element={
            <RouteWrapper>
              <RefundPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path="/FairPracticeCode"
          element={
            <RouteWrapper>
              <FairPracticeCode />
            </RouteWrapper>
          }
        />

        <Route
          path="/GrievanceRedressalPolicy"
          element={
            <RouteWrapper>
              <GrievanceRedressalPolicy />
            </RouteWrapper>
          }
        />
        <Route
          path="/Termsandconditions"
          element={
            <RouteWrapper>
              <Termsandconditions />
            </RouteWrapper>
          }
        />
        <Route
          path="/distributor/join"
          element={
            <>
              <Header />
              <DistributorJoinV2 />
            </>
          }
        ></Route>
        <Route
          path="/RegistrationComplete"
          element={<RegistrationComplete />}
        />
        <Route
          path="/cust-registration-complete"
          element={<OldCustomerRegisterComplete />}
        />
        <Route
          path="/cust-registered"
          element={<NewCustomerRegisterComplete />}
        />
        <Route
          path="PartnerRegistrationComplete"
          element={<PartnerRegistrationComplete />}
        />
        <Route
          path="/login"
          element={
            <RouteWrapper>
              <Login />
            </RouteWrapper>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <RouteWrapper>
              <ForgotPassword />
            </RouteWrapper>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <>
              <ResetPassword />
            </>
          }
        />

        <Route
          path="/lease"
          element={
            <RouteWrapper>
              <Lease />
            </RouteWrapper>
          }
        />
        <Route
          path="/coming-soon"
          element={
            <RouteWrapper>
              <ComingSoon />
            </RouteWrapper>
          }
        />
        <Route
          path="/mission"
          element={
            <RouteWrapper>
              <Mission />
            </RouteWrapper>
          }
        />
        <Route
          path="/contact-sales"
          element={
            <RouteWrapper>
              <ContactSales />
            </RouteWrapper>
          }
        />
        <Route
          path="/lease-contact-sales"
          element={
            <RouteWrapper>
              <ContactSalesForLease />
            </RouteWrapper>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AllRoutes;
