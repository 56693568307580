import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

const RouteWrapper = ({children, showHeaderFooter = true}) => (
  <>
    {showHeaderFooter && <Header />}
    {children}
    {showHeaderFooter && <Footer />}
  </>
);

export default RouteWrapper;
