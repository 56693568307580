import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {
  getMyProfileInformation,
  saveUserImage,
} from '../../service/dataService';
import {toast} from 'react-toastify';

const Profile = () => {
  const [profileImage, setProfileImage] = useState('');

  const formData = new FormData();
  formData.append('userImage', profileImage);

  const handleProfileImage = event => {
    const selectedFile = event.target.files[0];
    setProfileImage(selectedFile);
    insertProfileImage.mutate(formData);
  };

  const insertProfileImage = useMutation(saveUserImage, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      toast.success('Profile Image updated Successfully');
    },
  });

  const {data, isLoading, error} = useQuery(
    'getMyProfileInformation',
    getMyProfileInformation
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data || !data.data.data) {
    return <div>No data fetched.</div>;
  }

  return (
    <section>
      <div className="solfin-profile cunsumer-top-space header-space pt-1 pb-3">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="mb-0">
                <h5 className="fw-bold">Profile</h5>
              </div>

              <div className="card rounded-4 mt-3">
                <div className="card-header ps-xl-5 ps-4 pe-xl-5 pe-4 pt-4 pb-4 rounded-top-4 d-flex align-items-center justify-content-between">
                  <span className="fw-semibold">Personal Details</span>
                  {/* <a class="btn btn-primary btn-header rounded-3 ps-3 pe-3 pt-1 pb-1 text-nowrap" href="javascript:void(0);" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-pencil me-1"></i> Edit</a>  */}
                </div>
                {/* <!-- /card-header --> */}
                <div className="card-body ps-xl-5 ps-4 pe-xl-5 pe-4">
                  <div className="row">
                    <div className="col col-md-4">
                      <form action="#" method="get">
                        <div className="d-flex align-items-center flex-wrap flex-md-nowrap mt-xl-5 mt-4">
                          <label
                            htmlFor="ChangeProfile"
                            className="change-profile-now position-relative"
                          >
                            <input
                              className="position-absolute top-0"
                              type="file"
                              id="ChangeProfile"
                              name="imageFile"
                              onChange={handleProfileImage}
                            />
                            <div className="change-img rounded-pill d-flex align-items-center justify-content-center position-absolute bg-white">
                              {/* <span src="../images/gallery.svg" alt="ChangeImage" title="ChangeImage">
                                            {profileImage ? "Change Image" : "Upload Image"}
                                            </span> */}
                              <span>
                                <i className="fa-solid fa-camera"></i>
                              </span>
                            </div>
                            <div className="profile-pic-view rounded-pill overflow-hidden">
                              <span>
                                <img
                                  src={
                                    data &&
                                    data.data &&
                                    data.data.data &&
                                    data.data.data.user_img
                                      ? data.data.data.user_img
                                      : '../images/default_user.png'
                                  }
                                  alt="ProfileImage"
                                  title="ProfileImage"
                                />
                              </span>
                            </div>
                          </label>
                        </div>
                      </form>
                    </div>
                    <div className="col col-md-8">
                      <div className="registration-details d-flex justify-content-between align-items-center pt-xl-4 pt-3 pb-xl-5 pb-4">
                        <ul className="list-unstyled p-0 w-100 d-flex flex-wrap justify-content-between align-items-center">
                          <li>
                            <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                              <span className="opacity-75 mt-1 mb-1">
                                Customer Id
                              </span>
                              <span className="fw-medium mt-1 mb-1">
                                {data &&
                                data.data &&
                                data.data.data &&
                                data.data.data.customer_id
                                  ? data.data.data.customer_id
                                  : ''}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                              <span className="opacity-75 mt-1 mb-1">
                                Full Name
                              </span>
                              <span className="fw-medium mt-1 mb-1">
                                {data &&
                                data.data &&
                                data.data.data &&
                                data.data.data.customer_name
                                  ? data.data.data.customer_name
                                  : ''}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                              <span className="opacity-75 mt-1 mb-1">
                                Email
                              </span>
                              <span className="fw-medium mt-1 mb-1">
                                {data &&
                                data.data &&
                                data.data.data &&
                                data.data.data.email
                                  ? data.data.data.email
                                  : ''}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                              <span className="opacity-75 mt-1 mb-1">
                                Phone
                              </span>
                              <span className="fw-medium mt-1 mb-1">
                                {data &&
                                data.data &&
                                data.data.data &&
                                data.data.data.phone
                                  ? data.data.data.phone
                                  : ''}
                              </span>
                            </div>
                          </li>
                          {/* <li>
                                    <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">Address</span>
                                      <span className="fw-medium mt-1 mb-1">#123, First Floor, Sector 10</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">State</span>
                                      <span className="fw-medium mt-1 mb-1">Haryana</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">City</span>
                                      <span className="fw-medium mt-1 mb-1">Panchkula</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="border-bottom pt-xl-4 pt-3 pb-xl-4 pb-3 d-flex justify-content-between align-items-center">
                                      <span className="opacity-75 mt-1 mb-1">Pincode</span>
                                      <span className="fw-medium mt-1 mb-1">134109</span>
                                    </div>
                                  </li>  */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /card-body --> */}
              </div>
              {/* <!-- /card --> */}
            </div>
            {/* <!-- /col-12 --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /my-products --> */}
    </section>
  );
};

export default Profile;
