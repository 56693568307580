import React from 'react';

const AreaServedModal = ({area}) => {
  return (
    <div
      className="modal fade"
      id={`stateId${area.state_id}`}
      tabIndex="-1"
      aria-labelledby="MoreTagsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="MoreTagsModalLabel">
              {area.state} - Cities
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {area.cities.map((city, index) => (
              <a
                key={`${city}-${index}`}
                className="tag d-inline-block text-decoration-none fw-light border rounded-5 pt-2 pb-2 pe-3 ps-3"
                href="#"
              >
                {city}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaServedModal;
