import axios from 'axios';
const apikey = process.env.REACT_APP_HOSTNAME;
const apikeyV2 = process.env.REACT_APP_HOSTNAME_v2;

//Get Location Type
export const getLocationType = async () => {
  try {
    const {data} = await axios.get(`${apikey}/locationtype`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get all EPC data
export const getAllEpc = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const verifyDistributorKYC = async distributorId => {
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/distributor/${distributorId}/kyc/approve`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const raiseRequestForComfortLetter = async distributorId => {
  try {
    const {data} = await axios.post(
      `${apikeyV2}/admin/channel-financing/comfort-letter`,
      {
        distributorId: distributorId,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getComfortLettersForDistributor = async distributorId => {
  try {
    const {data} = await axios.get(
      `${apikeyV2}/admin/channel-financing/comfort-letter/${distributorId}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get approved EPC data
export const getApprovedEpc = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc/approved`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get new and rejected  EPC data
export const getNewAndRejectedEpc = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc/newandrejected`);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get new and rejected  EPC data
export const getPendingEpcForKyc = async () => {
  try {
    const {data} = await axios.get(`${apikey}/epc/kyc/pending`);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getEmpanelledDistributors = async () => {
  try {
    const {data} = await axios.get(`${apikey}/distributor/approved`);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorLeads = async () => {
  try {
    const {data} = await axios.get(`${apikey}/distributor/leads`);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const createNewDistributorLead = async requestBody => {
  try {
    const {data} = await axios.post(`${apikey}/distributor/leads`, requestBody);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const sendDistributorInviteLink = async leadId => {
  try {
    const {data} = await axios.post(
      `${apikey}/distributor/leads/${leadId}/invite`
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const sendEmailToEpcLead = async requestBody => {
  try {
    const {data} = await axios.post(
      `${apikey}/distributor/leads/send-email`,
      requestBody
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteEpcLead = async leadId => {
  try {
    const {data} = await axios.delete(`${apikey}/distributor/leads/${leadId}`);
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get EPC data by its id
export const getEpc = async id => {
  try {
    const {data} = await axios.get(`${apikey}/epc/${id}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorKycDocument = async id => {
  try {
    const {data} = await axios.get(`${apikey}/distributor/kyc/${id}/docs`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorKycDocumentUrl = async documentId => {
  try {
    const {data} = await axios.get(
      `${apikey}/distributor/kyc/docs/${documentId}`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateDistributorKycDocumentStatus = async body => {
  try {
    const {data} = await axios.put(`${apikey}/distributor/kyc`, body);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Update EPC status with status code ie- ACTIVE, INACTIVE , SUSPENDED
export const updateEpc = async data1 => {
  try {
    const id = data1.id;
    const statusId = data1.statusId;
    const {data} = await axios.put(`${apikey}/epc/updatestatus/${id}`, {
      statusId,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Update EPC status with status code ie- ACTIVE, INACTIVE , SUSPENDED
export const updateEpcApplicantStatus = async data1 => {
  try {
    const id = data1.id;
    const statusCode = data1.statusCode;
    const {data} = await axios.put(
      `${apikey}/epc/update-applicants-status/${id}`,
      {
        statusCode,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//archive epc with its id
export const updateEpcArchive = async id => {
  try {
    const {data} = await axios.put(`${apikey}/epc/setarchive/${id}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//DSA

//Get approved DSA data
export const getApprovedDsa = async () => {
  try {
    const {data} = await axios.get(`${apikey}/dsa/approved`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get new and rejected  DSA data
export const getNewAndRejectedDsa = async () => {
  try {
    const {data} = await axios.get(`${apikey}/dsa/newandrejected`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Get EPC data by its id
export const getDsa = async id => {
  try {
    const {data} = await axios.get(`${apikey}/Dsa/${id}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Update EPC status with status code ie- ACTIVE, INACTIVE , SUSPENDED
export const updateDsa = async data1 => {
  try {
    const id = data1.id;
    const statusId = data1.statusId;
    const {data} = await axios.put(`${apikey}/dsa/updatestatus/${id}`, {
      statusId,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
//Update EPC status with status code ie- ACTIVE, INACTIVE , SUSPENDED
export const updateDsaApplicantsStatus = async data1 => {
  try {
    const id = data1.id;
    console.log('id in updateDsaApplicantsStatus');
    console.log(data1);
    const statusCode = data1.statusCode;
    const {data} = await axios.put(
      `${apikey}/dsa/update-applicants-status/${id}`,
      {
        statusCode,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//archive dsa with its id
export const updateDsaArchive = async id => {
  try {
    const {data} = await axios.put(`${apikey}/dsa/setarchive/${id}`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getSingleCustomer = async id => {
  try {
    const customerId = +id;
    const data = await axios.get(
      `${apikey}/leads/getSingleApplication/${customerId}`,
      {withCredentials: true}
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const changeCustomerStatus = async updatedData => {
  // eslint-disable-next-line prefer-const
  let {id, statusId} = updatedData;
  statusId = Number(statusId);
  try {
    // console.log(statusId);
    const {data} = await axios.put(
      `${apikey}/customer/updatestatus/${id}`,
      {
        statusId,
      },
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Change leads status
export const changeLeadsStatus = async updatedData => {
  try {
    // console.log(updatedData);
    const {id, statusId} = updatedData;
    const {data} = await axios.put(
      `${apikey}/leads/updateLeadsStatus/${id}`,
      {
        statusId,
      },
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Assign leads
export const assignLeads = async updatedData => {
  try {
    const {id, epcId} = updatedData;
    const {data} = await axios.put(
      `${apikey}/leads/application-assigned/${id}`,

      {epcId},

      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//post loan offer for leads
export const insertQuoteLoanOffer = async updatedData => {
  try {
    // console.log(updatedData, "data")
    const {id, formData} = updatedData;

    const {data} = await axios.post(
      `${apikey}/admin-dashboard/insert-quote-offer/${id}`,

      formData,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    // console.log("error", err)
    throw Error(err.response.data.message);
  }
};

//get loan offer data
export const getQuoteLoanOffer = async id => {
  try {
    const data = await axios.get(
      `${apikey}/admin-dashboard/get-quote-offer/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get epc for loan offer
export const getEPCListForQuote = async () => {
  try {
    const data = await axios.get(
      `${apikey}/admin-dashboard/get-epcs-for-quote`,

      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDSAListForQuote = async () => {
  try {
    const data = await axios.get(
      `${apikey}/admin-dashboard/get-dsa-for-quote`,

      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//deleting application lead with its id
export const deleteApplication = async id => {
  try {
    const {data} = await axios.delete(
      `${apikey}/leads/deleteapplication/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const deleteEstimatorLeads = async id => {
  try {
    const {data} = await axios.delete(
      `${apikey}/admin-dashboard/delete-estimator-leads/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//change application status
export const changeApplicationStatus = async updatedData => {
  try {
    // console.log(updatedData);
    const {id, statusId} = updatedData;
    const {data} = await axios.put(
      `${apikey}/leads/updateApplicationStatus/${id}`,
      {
        statusId,
      },
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Leads
export const getAllApplicationLeads = async () => {
  try {
    console.log('its called get all application leads');
    const data = await axios.get(`${apikey}/leads/getLeadsApplication`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getAllEstimatedLeads = async () => {
  try {
    const data = await axios.get(`${apikey}/leads/getLeadsEstimator`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Fields
export const getEmpanelledStatus = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getEmpanelledStatus`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//all states
export const getAllStates = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getallstates`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//all cities
export const getAllCities = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getallcities`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//cities by state Id
export const getCitiesById = async id => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getfilteredcities/${id}`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//all customer type
export const getAllCustomerTypes = async () => {
  try {
    // console.log(`${apikey}`)
    const {data} = await axios.get(`${apikey}/fields/getallcustomertypes/`, {
      withCredentials: true,
    });
    // console.log(`${process.env.REACT_APP_HOST}`)
    //  console.log(data)
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const data = await axios.get(`${apikey}/user/logout`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//getLeadsStatus

export const getLeadsStatus = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getLeadsStatus/`, {
      withCredentials: true,
    });
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//getCustomerStatus

export const getCustomerStatus = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getCustomerStatus/`, {
      withCredentials: true,
    });
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//getApplicationStatus
export const getApplicationStatus = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getApplicationStatus/`, {
      withCredentials: true,
    });
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get All Epc which are available for assignment
export const getAllEpcForAssignment = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/fields/getAllLeadsForAssignment/`,
      {withCredentials: true}
    );
    return data.data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const acceptEpc = async id => {
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/accept-epc/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const acceptDsa = async id => {
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/accept-dsa/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Approve proposal and create project
export const createProject = async id => {
  try {
    const {data} = await axios.post(
      `${apikey}/admin-dashboard/approve-proposal/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Reject proposal
export const rejectProposal = async id => {
  try {
    const {data} = await axios.post(
      `${apikey}/admin-dashboard/reject-proposal/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Reject quote
export const rejectProposalSubmittedByEpc = async id => {
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/reject-epc-proposal/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get all projects
export const getAllProjects = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/admin-dashboard/get-all-projects`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    // console.log(err, "error")
    throw Error(err.response.data.message);
  }
};

//get project detail
export const getProjectDetail = async id => {
  try {
    const {data} = await axios.get(
      `${apikey}/admin-dashboard/get-project-detail/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Reject quote
export const insertLoanDocuments = async updatedData => {
  try {
    const {id, formData} = updatedData;
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/insert-loan-documents/${id}`,
      formData,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//get customer detail
export const getCustomerDetail = async id => {
  try {
    const {data} = await axios.get(
      `${apikey}/admin-dashboard/get-customers-details/${id}`,
      {withCredentials: true}
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Customers
export const getAllCustomer = async () => {
  try {
    const data = await axios.get(`${apikey}/admin-dashboard/get-all-customers`);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const changeEpcPublishStatus = async publishData => {
  const {id, publishStatus} = publishData;
  try {
    const {data} = await axios.post(
      `${apikey}/admin-dashboard/toggle-epc-publish/${id}`,
      {publishStatus},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const changeDsaPublishStatus = async publishData => {
  const {id, publishStatus} = publishData;
  try {
    const {data} = await axios.post(
      `${apikey}/admin-dashboard/toggle-dsa-publish/${id}`,
      {publishStatus},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Customers
export const getProjectStatusProvidedByAdmin = async () => {
  try {
    const data = await axios.get(
      `${apikey}/fields/projectStatusProvidedByAdmin`
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateProjectStatus = async updatedData => {
  const {id, statusId} = updatedData;
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/update-project-status/${id}`,
      {statusId},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//all customer type
export const getAllLocationTypes = async () => {
  try {
    // console.log(`${apikey}`)
    const {data} = await axios.get(`${apikey}/fields/locationtype/`, {
      withCredentials: true,
    });
    // console.log(`${process.env.REACT_APP_HOST}`)
    console.log('locationtype');
    console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateInstallationDate = async updatedData => {
  const {id, installationDate} = updatedData;
  console.log(installationDate);
  try {
    const {data} = await axios.put(
      `${apikey}/admin-dashboard/update-installation-date/${id}`,
      {installationDate},
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Fields
export const getExperiences = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getExperiences`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

//Fields
export const getActivities = async () => {
  try {
    const {data} = await axios.get(`${apikey}/fields/getActivities`, {
      withCredentials: true,
    });
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const applicationStatusProvidedByEpc = async () => {
  try {
    const {data} = await axios.get(
      `${apikey}/fields/applicationStatusProvidedByEpc`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorPendingInvoices = async () => {
  try {
    const {data} = await axios.get(
      `${apikeyV2}/admin/distributor/invoices/pending`,
      {
        withCredentials: true,
      }
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDistributorVerifiedInvoices = async () => {
  try {
    const {data} = await axios.get(
      `${apikeyV2}/admin/distributor/invoices/verified`,
      {
        withCredentials: true,
      }
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const getDocumentPresignedUrl = async documentCode => {
  try {
    const {data} = await axios.get(`${apikeyV2}/documents/${documentCode}`, {
      withCredentials: true,
    });
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

export const updateInvoiceStatus = async (invoiceCode, status, remarks) => {
  try {
    const {data} = await axios.put(
      `${apikeyV2}/admin/distributor/invoices/${invoiceCode}/verify`,
      {
        status: status,

        remarks: remarks,
      },
      {
        withCredentials: true,
      }
    );
    // console.log(data);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};
