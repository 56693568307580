import React, {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {logout, isValidDistributor} from '../service/DataService';
import {useMutation, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';

const SideBarItems = ({data}) => {
  const [isToggle, setIsToggle] = useState(false);
  return (
    <div>
      <li className={!data.subItems ? '' : 'sub-menu'}>
        {!data.subItems ? (
          <NavLink to={data.path} end>
            <i className={data.icon}></i> <span>{data.name}</span>
          </NavLink>
        ) : (
          <>
            <button
              onClick={() => setIsToggle(prev => !prev)}
              className="sidebar-toggle-div cursor-pointer "
            >
              <i className="fa-solid fa-file-lines"></i>{' '}
              <span>
                {data.name}
                <i
                  className={`fa right ${isToggle ? 'fa-angle-up' : 'fa-angle-down'}`}
                ></i>
              </span>{' '}
              <div className="down-arrow"></div>
            </button>
            <ul className={isToggle ? '' : 'd-none active'}>
              {data.subItems.map(sub => (
                <li key={sub.subId}>
                  <NavLink to={sub.path} end>
                    <span>{sub.subItemsName}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </>
        )}
      </li>
    </div>
  );
};

const SideBar = () => {
  const [isToggle, setIsToggle] = useState(false);
  // console.log(isToggle);
  let sideBarData = [];
  const navigate = useNavigate();

  const Logout = async () => {
    // console.log("Logout")
    const response = await logout();
    //  console.log(response)
    if (response.status === 200) {
      // console.log("navigate")
      navigate('/login');
    }
  };

  const {data, isLoading, error} = useQuery(
    'isValidDistributor',
    isValidDistributor
  );

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data) {
    return <div>No data fetched.</div>;
  }
  console.log(data.data.data);
  if (data.data.data.is_distributor == true) {
    sideBarData = [
      {
        id: 1,
        name: 'My Details',
        icon: 'fa fa-user',
        path: '/distributor-dashboard',
      },

      {
        id: 3,
        name: 'Invoices',
        icon: 'fa-solid fa-file-lines',
        subItems: [
          // { subId: 1, subItemsName: "My Leads", path: "/admin/my-leads" },
          {
            subId: 2,
            subItemsName: 'Pending',
            path: '/distributor-dashboard/pending-invoices',
          },
          {
            subId: 3,
            subItemsName: 'Verified',
            path: '/distributor-dashboard/verified-invoices',
          },
          //{ subId: 3, subItemsName: "Disbursed", path: "/distributor-dashboard/disbursed-invoices" }
        ],
      },
    ];
  } else {
    sideBarData = [
      {id: 1, name: 'My Details', icon: 'fa fa-user', path: '/epc-dashboard'},
      {
        id: 2,
        name: 'Leads',
        icon: 'fa right fa-angle-down',
        subItems: [
          // { subId: 1, subItemsName: "My Leads", path: "/admin/my-leads" },
          {
            subId: 2,
            subItemsName: 'Assigned Leads',
            path: '/epc-dashboard/assigned-leads',
          },
        ],
      },
      {
        id: 3,
        name: 'Rate Matrix',
        icon: 'fa-solid fa-file-lines',
        path: '/epc-dashboard/rate-matrix',
      },
      {
        id: 4,
        name: 'Projects',
        icon: 'fa fa-file-contract',
        path: '/epc-dashboard/projects',
      },
    ];
  }

  return (
    <>
      <div className="solfin-sidebar">
        <div className="solfin-logo">
          <span>
            <img
              src="/images/solfin-epc-logo.svg"
              alt="Solfin"
              title="Solfin"
            />
          </span>
        </div>
        {/* <!-- /logo --> */}

        <div className="dashboard-navs">
          <ul>
            {sideBarData.map(data => (
              <SideBarItems key={data.id} data={data} />
            ))}
          </ul>
        </div>
        {/* <div className="logout">
          <button onClick={Logout}>
            <i className="fa-solid fa-right-from-bracket"></i>{" "}
            <span>Logout</span>
          </button>
        </div> */}
      </div>
      {/* <!-- /solfin-siderbar --> */}
    </>
  );
};

export default SideBar;
