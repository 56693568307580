import {useEffect, useState, useRef} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import Select from 'react-select';
import {useFormik} from 'formik';
import * as queryData from '../service/DataService';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {Tooltip} from 'react-tooltip';

export const epcDetailSchema = Yup.object({
  description: Yup.string().required('Please provide your description'),
  sectors: Yup.array()
    .required('Please select Dealing Sector')
    .min(1, 'Please select at least one Dealing Sector'),
});

const PublishingDetails = () => {
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [logoFile, setLogoFile] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [selectedSector, setSelectedSector] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [option, setOption] = useState();

  const queryClient = useQueryClient();

  const [isPublishingDetailChanges, setIsPublishingDetailsChanges] =
    useState(false);

  const getPublishingDetails = useQuery(
    'publishingDetails',
    queryData.getPublishingDetails
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  console.log('selectedOptions');
  console.log(selectedOptions);
  const insertEpcPublishingDetails = useMutation(
    queryData.insertEpcPublishingDetails,
    {
      onError: error => {
        toast.error(error.message);
      },
      onSuccess: data => {
        setIsPublishingDetailsChanges(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        if (fileInputRef2.current) {
          fileInputRef2.current.value = '';
        }
        toast.success('Publishing Details saved successfully');
        queryClient.invalidateQueries('publishingDetails');
      },
    }
  );

  const getSectors = useQuery('Sectors', queryData.getSectors);

  const publishingDetails =
    getPublishingDetails &&
    getPublishingDetails?.data &&
    getPublishingDetails?.data[0];
  // values defined for formik
  const initialDescription = publishingDetails?.short_description || '';
  const initialValues = {
    description: initialDescription,
  };

  // Build Model and fill data in Formik model
  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,
      //  validationSchema: epcDetailSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append('logoImage', logoFile);
        formData.append('publishImage', imageFile);
        formData.append('shortDescription', values.description);
        formData.append('dealsInIds', JSON.stringify(selectedSector));
        insertEpcPublishingDetails.mutate(formData);

        action.resetForm();
      },
    });

  useEffect(() => {
    if (publishingDetails && publishingDetails.short_description) {
      handleChange({
        target: {
          name: 'description',
          value: publishingDetails.short_description,
        },
      });
    }
  }, [publishingDetails, handleChange]);

  const handleLogochange = e => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['jpg', 'jpeg', 'png']; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      const fileSize = selectedFile.size;

      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        toast.error(
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ')
        );
        e.target.value = ''; // Clear the input field
        return;
      }

      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
        toast.error('File size exceeds the limit of 2 MB.');
        e.target.value = ''; // Clear the input field
        return;
      }

      // The file format and size are allowed, so set it in the state
      setLogoFile(selectedFile);
      setIsPublishingDetailsChanges(true);
    }
  };

  const handleImagechange = e => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['jpg', 'jpeg', 'png']; // Allowed file formats
    const targetWidth = 1500; // Desired width
    const targetHeight = 1200; // Desired height

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      // Check if the file format is allowed
      if (allowedFormats.includes(fileExtension)) {
        const reader = new FileReader();

        reader.onload = e => {
          const image = new Image();
          image.src = e.target.result;

          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calculate cropping coordinates to center the image
            let x, y, width, height;
            if (image.width > image.height) {
              width = Math.min(image.width, targetWidth);
              height = (image.height / image.width) * width;
              x = (image.width - width) / 2;
              y = (image.height - height) / 2;
            } else {
              height = Math.min(image.height, targetHeight);
              width = (image.width / image.height) * height;
              y = (image.height - height) / 2;
              x = (image.width - width) / 2;
            }

            // Set canvas dimensions to crop the image
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the image on the canvas to crop
            ctx.drawImage(
              image,
              x,
              y,
              width,
              height,
              0,
              0,
              targetWidth,
              targetHeight
            );

            // Convert canvas content to a Blob
            canvas.toBlob(blob => {
              const croppedFile = new File([blob], selectedFile.name, {
                type: selectedFile.type,
              });

              // Set the cropped File object in the state or take further actions
              setImageFile(croppedFile);
              setIsPublishingDetailsChanges(true);
            }, selectedFile.type);
          };
        };

        reader.readAsDataURL(selectedFile);
      } else {
        // The file format is not allowed, display an error message
        toast.error(
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ')
        );
        // Optionally, clear the input field
        e.target.value = '';
      }
    }
  };

  // const handleImagechange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ["jpg", "jpeg", "png"]; // Allowed file formats
  //   const targetWidth = 1000; // Desired width
  //   const targetHeight = 1000; // Desired height

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     // Check if the file format is allowed
  //     if (allowedFormats.includes(fileExtension)) {
  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         const image = new Image();
  //         image.src = e.target.result;

  //         image.onload = () => {
  //           // Create a canvas to crop and resize the image
  //           const canvas = document.createElement('canvas');
  //           const ctx = canvas.getContext('2d');

  //           // Set canvas dimensions
  //           canvas.width = targetWidth;
  //           canvas.height = targetHeight;

  //           // Draw the image on the canvas to crop and resize
  //           ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

  //           // Convert canvas content to a Blob
  //           canvas.toBlob((blob) => {
  //             // Create a new File object with the blob and original file name
  //             const croppedFile = new File([blob], selectedFile.name, {
  //               type: selectedFile.type,
  //             });

  //             // Set the cropped File object in the state or take further actions
  //             setImageFile(croppedFile);
  //             setIsPublishingDetailsChanges(true);
  //           }, selectedFile.type);
  //         };
  //       };

  //       reader.readAsDataURL(selectedFile);
  //     } else {
  //       // The file format is not allowed, display an error message
  //       toast.error(
  //         "Invalid file format. Allowed formats: " + allowedFormats.join(", ")
  //       );
  //       // Optionally, clear the input field
  //       e.target.value = "";
  //     }
  //   }
  // };

  // const handleImagechange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ["jpg", "jpeg", "png"]; // Allowed file formats

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     // Check if the file format is allowed
  //     if (allowedFormats.includes(fileExtension)) {
  //       // The file format is allowed, so set it in the state
  //       setImageFile(selectedFile);
  //       setIsPublishingDetailsChanges(true);
  //     } else {
  //       // The file format is not allowed, you can display an error message or take other actions as needed
  //       toast.error(
  //         "Invalid file format. Allowed formats: " + allowedFormats.join(", ")
  //       );
  //       // Optionally, clear the input field
  //       e.target.value = "";
  //     }
  //   }
  // };

  const handleFieldChange = e => {
    handleChange(e); // Call Formik's handleChange to update form state
    setIsPublishingDetailsChanges(true);
  };

  const handleChangeArea = selectedOptions => {
    setIsPublishingDetailsChanges(true);
    setSelectedOptions(selectedOptions);

    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedSector(selectedIds);
  };

  const sectorOptions =
    getSectors.data &&
    getSectors.data.data.map(options => ({
      value: options.sector_id,
      label: options.sector,
    }));

  if (!getPublishingDetails.data) {
    return <div>Loading...</div>;
  }

  if (getPublishingDetails.isLoading) {
    return <div>Loading...</div>;
  }
  console.log('publishingDetails');
  console.log(publishingDetails);
  return (
    <>
      {getPublishingDetails ? (
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex="0"
        >
          <div className="solfin-application-details">
            <form onSubmit={handleSubmit}>
              <div className="form-wrap position-relative">
                <div className="form-group w-33">
                  <label htmlFor="Dealsin">
                    Upload Logo{' '}
                    <span
                      id="myTooltip"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="If you have been provided a unique code by Solfin, please input it here"
                    >
                      <a
                        className="info icon-info-sign"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Image size should not be larger than 50x50 pixels"
                      >
                        <i className="fa fa-circle-info"></i>
                      </a>
                      <Tooltip id="my-tooltip" />
                    </span>
                  </label>

                  <input
                    type="file"
                    name="logoFile"
                    id="logoid"
                    onChange={handleLogochange}
                    className="form-control solfin-input"
                    placeholder="No file selected"
                    ref={fileInputRef}
                  />

                  {publishingDetails && publishingDetails ? (
                    <div className="position-absolute">
                      <a
                        className="ms-3 text-dark h1"
                        href={
                          publishingDetails && publishingDetails.logo
                            ? publishingDetails.logo
                            : ''
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-file opacity-50"></i>
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* <!-- /form-group --> */}
                <div className="form-group w-33">
                  <label htmlFor="Dealsin">
                    Upload Image{' '}
                    <span
                      id="myTooltip"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="If you have been provided a unique code by Solfin, please input it here"
                    >
                      <a
                        className="info icon-info-sign"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Image size should not be larger than 800x530 pixels"
                      >
                        <i className="fa fa-circle-info"></i>
                      </a>
                      <Tooltip id="my-tooltip" />
                    </span>
                  </label>

                  <input
                    type="file"
                    name="imageFile"
                    id="imageid"
                    onChange={handleImagechange}
                    className="form-control solfin-input"
                    placeholder="No file selected"
                    ref={fileInputRef2}
                  />
                  {publishingDetails && publishingDetails ? (
                    <div className="position-absolute">
                      <a
                        className="ms-3 text-dark h1"
                        href={
                          publishingDetails && publishingDetails.image
                            ? publishingDetails.image
                            : ''
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-file opacity-50"></i>
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {/* <!-- /form-group --> */}
                <div className="form-group w-33">
                  <label htmlFor="Dealsin">Deal In</label>
                  <div className="position-relative mt-3">
                    <Select
                      name="basic[]"
                      isMulti
                      defaultValue={
                        publishingDetails && publishingDetails.sector
                          ? JSON.parse(publishingDetails.sector)
                          : []
                      }
                      //value={selectedOptions}
                      //value={["Solar"]}
                      options={sectorOptions}
                      onChange={e => {
                        handleChangeArea(e);
                      }}
                    />
                  </div>
                  {/* {errors.sectors && touched.sectors ? (
            <p className="form-error text-danger">
            {errors.sectors}
            </p>
             ) : null} */}
                </div>

                {/* <!-- /form-group --> */}
              </div>
              {/* <!-- /form-wrap --> */}

              <div className="form-wrap">
                <div className="served-area-row w-100">
                  {/* <div className="form-group w-50">
              <label for="Dealsin">State</label>
              <div className="position-relative">
                <select className="form-control solfin-input">
                  <option value="HR">Haryana</option>
                  <option value="PB">Punjab</option>
                  <option value="HP">Himachal Pardesh</option>
                  <option value="RJ">Rajsthan</option>
                  <option value="GJ">Gujrat</option>
                  <option value="DL">Delhi</option>
                </select>
              </div>
            </div> */}
                  {/* <!-- /form-group --> */}
                  {/* <div className="form-group w-50">
            <label htmlFor="Dealsin">Served Areas</label>
            <div className="position-relative">
              <Select
                name="basic[]"
                isMulti
                value={selectedOptions}
                options={options}
                onChange={handleChangeArea}
              />
            </div>
          </div> */}
                  {/* <!-- /form-group --> */}
                </div>
                {/* <!-- /served-area-row --> */}

                {/* <div className="form-group w-100">
            <div className="addmore-areas">
              <a
                href="javascript:void(0);"
                className="btn btn-info add-btn"
              >
                <i className="fa fa-plus"></i> Add More
              </a>
            </div>
          </div> */}
                {/* <!-- /form-group --> */}
              </div>
              {/* <!-- /form-wrap --> */}

              <div className="form-wrap mt-5">
                <div className="form-group w-100">
                  <label htmlFor="Dealsin">Short Description</label>
                  <textarea
                    name="description"
                    cols="30"
                    rows="4"
                    id="Description"
                    maxLength="300"
                    // value={getPublishingDetails && getPublishingDetails.data[0] && getPublishingDetails.data[0].short_description==="null" ?  values.description : getPublishingDetails && getPublishingDetails.data[0] && getPublishingDetails.data[0].short_description }
                    value={values.description}
                    onChange={e => {
                      handleChange(e);
                      handleFieldChange(e);
                    }}
                    onBlur={handleBlur}
                    placeholder={
                      getPublishingDetails &&
                      getPublishingDetails.data &&
                      getPublishingDetails.data.short_description
                        ? getPublishingDetails.data[0].short_description
                        : 'Please add your short description upto 300 characters'
                    }
                    className="form-control solfin-input"
                  ></textarea>
                  {errors.description && touched.description ? (
                    <p className="form-error text-danger">
                      {errors.description}
                    </p>
                  ) : null}
                </div>
                {/* <!-- /form-group --> */}
                <div className="form-group w-100">
                  <div className="submit-filter">
                    <input
                      type="submit"
                      className="btn btn-info"
                      disabled={
                        isPublishingDetailChanges
                          ? false
                          : getPublishingDetails.data.length === 0
                            ? false
                            : true
                      }
                      value={
                        isPublishingDetailChanges
                          ? 'Save'
                          : getPublishingDetails.data.length === 0
                            ? 'Save'
                            : 'Saved'
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PublishingDetails;
