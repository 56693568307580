import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {logout} from '../service/DataServices';
import {useMutation, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/images/solfin-dsa-logo.svg';

const SideBarItems = ({data}) => {
  const [isToggle, setIsToggle] = useState(false);
  return (
    <div>
      <li className={!data.subItems ? '' : 'sub-menu'}>
        {!data.subItems ? (
          <NavLink to={data.path} end>
            <i className={data.icon}></i> <span>{data.name}</span>
          </NavLink>
        ) : (
          <>
            <button
              onClick={() => setIsToggle(prev => !prev)}
              className="sidebar-toggle-div cursor-pointer"
            >
              <i className="fa-solid fa-file-lines"></i>{' '}
              <span>
                {data.name}
                <i
                  className={`fa right ${isToggle ? 'fa-angle-up' : 'fa-angle-down'}`}
                ></i>
              </span>{' '}
              <div className="down-arrow"></div>
            </button>
            <ul className={isToggle ? '' : 'd-none'}>
              {data.subItems.map(sub => (
                <li key={sub.subId}>
                  <NavLink to={sub.path} end>
                    <span>{sub.subItemsName}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </>
        )}
      </li>
    </div>
  );
};

const SideBar = () => {
  const [isToggle, setIsToggle] = useState(false);
  console.log(isToggle);

  const navigate = useNavigate();

  const Logout = async () => {
    console.log('Logout');
    const response = await logout();
    console.log(response);
    if (response.status === 200) {
      console.log('navigate');
      navigate('/login');
    }
  };

  const sidBarData = [
    {id: 1, name: 'My Details', icon: 'fa fa-user', path: '/dsa-dashboard'},
    {
      id: 2,
      name: 'My Leads',
      icon: 'fa-solid fa-file-lines',
      path: '/dsa-dashboard/my-leads',
    },
    {
      id: 3,
      name: 'Projects',
      icon: 'fa-solid fa-file-lines',
      path: '/dsa-dashboard/projects',
    },
    // { id: 4, name: "Contracts", icon: "fa fa-file-contract", path: "/epc/contracts" },
  ];

  return (
    <>
      <div className="solfin-sidebar">
        <div className="solfin-logo">
          <span>
            <img src={logo} alt="Solfin" title="Solfin" />
          </span>
        </div>
        {/* <!-- /logo --> */}

        <div className="dashboard-navs">
          <ul>
            {sidBarData.map(data => (
              <SideBarItems key={data.id} data={data} />
            ))}
          </ul>
        </div>
        <div className="logout">
          <button>
            <i className="fa-solid fa-right-from-bracket"></i>{' '}
            <span>Logout</span>
          </button>
        </div>
      </div>
      {/* <!-- /solfin-siderbar --> */}
    </>
  );
};

export default SideBar;
