import {Link, useNavigate, useParams} from 'react-router-dom';
import * as querydata from '../../service/dataService';
import {useMutation, useQuery} from 'react-query';
import {saveAs} from 'file-saver';
import {toast} from 'react-toastify';
import {file1} from '../../../assets/images/default_file.jpg';
import AreaServedModal from '../../../components/AreaServedModal';
import React from 'react';
const DsaApplicantsDetails = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const acceptDsa = useMutation(querydata.acceptDsa, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.success(data.message);
      navigate('/admin/dsa-empanelled');
    },
  });

  const locationtype = useQuery('getLocationType', querydata.getLocationType);
  const cities = useQuery('getAllCities', querydata.getAllCities);
  const updateStatusMutation = useMutation(
    querydata.updateDsaApplicantsStatus,
    {
      onError: error => {
        toast.error(error.message);
      },
      onSuccess(data) {
        // Wiconsole.log(data);ll be called 3 times
        toast.success(data.message);
        // console.log("Succesful",{data});
        navigate('/admin/dsa-applicants');
      },
    }
  );

  const {data, isLoading, error} = useQuery(['getDsa', +id], () =>
    querydata.getDsa(id)
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }

  const downloadImage = () => {
    const image_url = data.data[0].document_file;
    const fileName = data.data[0].document_name;
    saveAs(image_url, fileName);
  };

  const handleChangeStatus = async (id, statusCode) => {
    const updataData = {id: id, statusCode: statusCode};
    const data1 = await updateStatusMutation.mutateAsync(updataData);
  };

  const handleAssignedChange = () => {
    acceptDsa.mutate(id);
  };

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <Link className="back-to-page" to="/admin/dsa-applicants">
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
          <div className="dash-breadcrumb">
            <ul>
              <li>
                <Link to="/admin/dsa-applicants">Applicants</Link>
              </li>
              <li>Details</li>
            </ul>
          </div>
        </div>
        {/* <!-- /page title --> */}

        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="tab-menu mb-0">
              <ul>
                <li>
                  <a href="#" className="active" data-rel="tab-1">
                    Personal Details
                  </a>
                </li>
                {/* <li>
                  <a href="#" data-rel="tab-2" className="">
                    Quote & Loan Offer
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          {/* <!-- /card header --> */}
          <div className="dash-card-body">
            <div className="solfin-tabs">
              <div className="tab-main-box">
                <div className="tab-box" id="tab-1" style={{display: 'block'}}>
                  <form action="#" method="get">
                    <div className="solfin-application-details">
                      <ul>
                        {/* <li>
                          <span className="detail-label">Type</span>
                          <span className="detail-value">
                          {data.customer_type}
                          </span>
                        </li> */}
                        <li>
                          <span className="detail-label">Name</span>
                          <span className="detail-value">
                            {data.data[0].dsa_name}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Email</span>
                          <span className="detail-value">
                            {data.data[0].email}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Phone</span>
                          <span className="detail-value">
                            {data.data[0].phone}
                          </span>
                        </li>

                        <li>
                          <span className="detail-label">Address</span>
                          <span className="detail-value">
                            {data.data[0].address1}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">City</span>
                          <span className="detail-value">
                            {data.data[0].city}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">State</span>
                          <span className="detail-value">
                            {data.data[0].state}
                          </span>
                        </li>

                        <li>
                          <span className="detail-label">Pincode</span>
                          <span className="detail-value">
                            {data.data[0].pincode}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Document Name</span>
                          <span className="detail-value">
                            {data.data[0].document_name}
                          </span>
                        </li>

                        <li>
                          <span className="detail-label">Document Image</span>
                          <span className="detail-value img-uploaded">
                            {data.data[0].document_file ? (
                              <a
                                href={data.data[0].document_file}
                                target="_blank"
                                onClick={downloadImage}
                                className="text-dark"
                                rel="noreferrer"
                              >
                                {' '}
                                <i className="fa-solid fa-download"></i>{' '}
                                Download
                              </a>
                            ) : (
                              ''
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Type of Activity</span>
                          <span className="detail-value">
                            {data.data[0].dsa_activities}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Experience</span>
                          <span className="detail-value">
                            {data.data[0].experience}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Area Served</span>
                          <span className="detail-value">
                            <div className="state-served">
                              {data.serviceArea.map((area, index) => (
                                <React.Fragment key={index}>
                                  <div
                                    className="btn text-dark text-decoration-underline py-0 px-1"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#stateId${area.state_id}`}
                                  >
                                    {area.state}
                                    <AreaServedModal area={area} />{' '}
                                  </div>
                                  {index !== data.serviceArea.length - 1 && ','}
                                </React.Fragment>
                              ))}
                            </div>
                          </span>
                        </li>

                        <li>
                          <span className="detail-label">Status</span>
                          <span className="detail-value">
                            {data.data[0].status}
                          </span>
                        </li>
                        <li>
                          <span className="detail-label">Profile</span>
                          <span className="detail-value">
                            {data.data[0].profile}
                          </span>
                        </li>

                        {/* <li>
                          <span className="detail-label">Location on Map</span>
                          <span className="detail-value">
                            <a
                              href="https://www.google.com/maps/@30.6934743,76.8128744,16.58z?hl=en"
                              target="_blank"
                            >
                              <img
                                src="../assets/images/map-location.png"
                                alt="Location"
                                title="Location"
                              />
                            </a>
                          </span>
                        </li> */}
                      </ul>
                    </div>
                  </form>
                  <div className="form-actions d-flex">
                    {acceptDsa.isLoading ? (
                      <button
                        className="opacity-100 btn btn-info spinner-btn2"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden" role="status">
                          Loading...
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={handleAssignedChange}
                        className="btn btn-info"
                      >
                        Approve
                      </button>
                    )}
                    {updateStatusMutation.isLoading ? (
                      <button
                        className="opacity-100 btn btn-info spinner-btn2"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden" role="status">
                          Loading...
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleChangeStatus(id, 'Rejected')}
                        className="btn btn-info filter-btn"
                      >
                        Reject
                      </button>
                    )}
                  </div>
                </div>
                {/* <!-- /end tab --> */}
              </div>
            </div>
            {/* <!-- solfin-tabs --> */}
          </div>
          {/* // <!-- /dash-card-body --> */}
        </div>
        {/* // <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DsaApplicantsDetails;
