import {Outlet} from 'react-router-dom';
import Header from '../../../CustomerDashboard/components/Header';
import ComingSoon from '../../../CustomerDashboard/pages/comingSoon/ComingSoon';

const CustomerLayout = () => {
  let isChildRendered = true;

  try {
    if (!Outlet) {
      throw new Error('No Outlet found');
    }
  } catch (error) {
    isChildRendered = false;
  }

  return (
    <>
      <Header />
      <main className="customerLayout">
        {isChildRendered ? <Outlet /> : <ComingSoon />}
      </main>
    </>
  );
};

export default CustomerLayout;
