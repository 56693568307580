import React from 'react';
import InvoiceTable from './components/InvoiceTable';
import {useQuery} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import Export from '../../../components/ExportToExcel';

import {toast} from 'react-toastify';
import InvoiceVerificationRemarks from './components/InvoiceVerificationRemarks';
import {
  getDistributorVerifiedInvoices,
  getDocumentPresignedUrl,
} from '../../service/dataService';

const VerifiedInvoices = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const {data, isLoading, error} = useQuery(
    'getDistributorVerifiedInvoices',
    getDistributorVerifiedInvoices
  );

  const handleNavigate = distributorId => {
    navigate(`/admin/distributor-applicants/kyc-detail/${distributorId}`);
  };

  const viewInvoice = async documentCode => {
    try {
      // Replace with your actual API endpoint
      const response = await getDocumentPresignedUrl(documentCode);
      if (response.data && response.data.preSignedUrl) {
        window.open(response.data.preSignedUrl, '_blank');
      } else {
        toast.error('Error fetching file details, Please try again.');
      }
    } catch (error) {
      console.error('Error fetching the file URL:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sr. No',
        Cell: (row, index) => {
          return index + 1;
        },
      },
      {Header: 'Invoice Code', accessor: 'invoiceCode'},
      {Header: 'Loan Amount Required', accessor: 'amountRequired'},
      {Header: 'Verification Status', accessor: 'status'},
      {Header: 'Uploaded At', accessor: 'uploadDate'},
      {Header: 'verification Date', accessor: 'verificationDate'},
      {Header: 'Disbursed Amount', accessor: 'amountDisbursed'},
      {
        Header: 'Disbursal Date',
        accessor: 'disbursalDate',
      },
      {
        Header: 'View Invoice',
        Cell: ({row}) => (
          <td>
            <div className="text-nowrap">
              <button
                onClick={() => viewInvoice(row.original.documentCode)}
                className="ms-2 btn btn-sm green-btn"
                title="View Invoice"
              >
                View
              </button>
            </div>
          </td>
        ),
      },
      {
        Header: 'View Distributor',
        Cell: ({row}) => (
          <td>
            <div className="text-nowrap">
              <button
                onClick={() => handleNavigate(row.original.distributorId)}
                className="ms-2 btn btn-sm green-btn"
                title="View Invoice"
              >
                View
              </button>
            </div>
          </td>
        ),
      },
    ],
    [data]
  );
  // Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data.invoices,
      [
        'invoiceCode',
        'amountRequired',
        'uploadDate',
        'status',
        'verificationDate',
        'amountDisbursed',
        'disbursalDate',
      ],
      [
        'Invoice Code',
        'Loan Amount Required',
        'Uploaded At',
        'Verification Status',
        'verification Date',
        'Disbursed Amount',
        'Disbursal Date',
      ]
    );
  };
  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();

  const navigate = useNavigate();

  if (!data || !data.data) {
    return <>Loading...</>;
  }

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  if (!data.data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Proccessed Invoices</h3>
        </div>
        <InvoiceTable
          pageNumber={0}
          tableName={'List of Proccessed Invoices'}
          columns={columns}
          tableData={data && data.data && data.data.invoices}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          startandenddateallowed
          dataQuery={'getDistributorVerifiedInvoices'}
          pepcstatusallowed
          // areaServedallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default VerifiedInvoices;
