import React, {useState, useRef} from 'react';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {uploadNewInvoiceForChannelFinancing} from '../../service/DataService';
const UploadNewInvoice = ({setIsUploading, dataQuery}) => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [file, setfile] = useState('');
  const handleInvoiceUpload = e => {
    console.log(e.target.files);
    const selectedFile = e.target.files[0];
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'png']; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      const fileSize = selectedFile.size;
      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        toast.error(
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ')
        );
        return;
      }
      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
        toast.error('File size exceeds the limit of 2 MB.');
        return;
      }
      setfile(selectedFile);
      document.getElementById('invoiceFile').value = selectedFile.name;
    } else {
      toast.error('Please Upload Document');
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    const requestBody = {};
    const currformData = new FormData(formRef.current);
    console.log(currformData);
    for (const [key, value] of currformData.entries()) {
      requestBody[key] = value;
    }
    console.log(requestBody);
    if (!requestBody.invoiceFile) {
      toast.error('Please upload invoice file');
      return;
    }

    if (!requestBody.amountRequired) {
      toast.error('Please enter loan amount required');
      return;
    }
    setIsUploading(true);
    const response = await uploadNewInvoiceForChannelFinancing(currformData);
    setIsUploading(false);
    queryClient.invalidateQueries(dataQuery);
  };
  const changeHandler = (e, id) => {
    errors[id] = null;
  };
  return (
    <>
      <div
        className="modal fade"
        id={'new-invoice-upload'}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                {'Upload new invoice for financing'}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form ref={formRef}>
                <div className="form-wrap send-email-form">
                  <div className="form-group mb-3">
                    <label htmlFor={'invoiceFile'}>Invoice File</label>
                    <input
                      type="file"
                      name="invoiceFile"
                      id="invoiceFile"
                      onChange={e => handleInvoiceUpload(e)}
                      className="form-control solfin-input"
                      placeholder="Upload Invoice file"
                    />
                    {errors.invoiceFile ? (
                      <p className="form-error text-danger">
                        {errors.invoiceFile}
                      </p>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label htmlFor={'amountRequired'}>
                      Required Loan Amount
                    </label>
                    <input
                      type="text"
                      id={'amountRequired'}
                      placeholder="Amount Required"
                      // value={quoteData.project_name === null ? values.projectName : values.projectName=quoteData.project_name }
                      name="amountRequired"
                      className="solfin-input"
                      onChange={e => {
                        changeHandler(e);
                      }}
                    />
                    {errors.amountRequired ? (
                      <p className="form-error text-danger">
                        {errors.amountRequired}
                      </p>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info filter-btn"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-info"
                data-bs-dismiss="modal"
                onClick={e => handleSubmit(e)}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadNewInvoice;
