import React from 'react';
import LeadTable from '../leads/components/LeadTable';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {getPendingEpcForKyc} from '../../service/dataService';
import Export from '../../../components/ExportToExcel';
import * as dataquery from '../../service/dataService';
import {toast} from 'react-toastify';
import DeleteModal from '../../components/DeleteModal';
import DeleteEpcModal from '../../components/DeleteEpcModal';

const DistributorKyc = () => {
  const queryClient = useQueryClient();
  // const [currentPageNumber, setCurrentPageNumber] = useState();

  // console.log("currentPageNumber");
  // console.log(currentPageNumber);

  //Export To Excel
  const handleExport = () => {
    //console.log(data.data.data);
    Export(
      data.data,
      [
        'company_name',
        'email',
        'phone',
        'address1',
        'pin_code',
        'city',
        'state',
        'customer_type',
        'premium_partner_code',
        'created_on',
        'published_on',
        'service_area',
        'status',
      ],
      [
        'CompanyName',
        'Email',
        'Phone',
        'Address',
        'PinCode',
        'City',
        'State',
        'CustomerType',
        'PremiumPartnerCode',
        'CreatedOn',
        'Publish Status',
        'Service Area',
        'Status',
      ]
    );
  };

  //const [epcData, setEpcData] = useState([{}])
  const [modalData, setModalData] = useState();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = row => {
    const id = row.original.epc_id;
    // console.log(id);
    navigate(`/admin/distributor-applicants/kyc-detail/${id}`);
  };

  const epcArchive = useMutation(dataquery.updateEpcArchive, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      toast.success(data.message);
      queryClient.invalidateQueries('pendingEpcForKyc');
      // data.refetch();
    },
  });

  const handleArchive = row => {
    const id = row.original.epc_id;
    epcArchive.mutate(id);
  };
  const columns = React.useMemo(
    () => [
      {Header: 'Sr. No', accessor: 'srNo'},
      {Header: 'Company Name', accessor: 'company_name'},
      {Header: 'City', accessor: 'city'},
      {Header: 'State', accessor: 'state'},
      {Header: 'Email', accessor: 'email'},
      {Header: 'Phone', accessor: 'phone'},
      {Header: 'Date & Time', accessor: 'created_on'},
      {
        Header: 'Area Served',
        accessor: 'service_area',
        Cell: ({value}) => {
          const states = value.split(', ');
          const primaryState = states[0];
          const otherStates = states.slice(1);

          return (
            <div>
              <span>{primaryState}</span>
              {otherStates.length > 0 && (
                <span
                  title={otherStates.join(', ')}
                  style={{cursor: 'pointer', color: '#4da802'}}
                >
                  {' '}
                  +{otherStates.length} more..
                </span>
              )}
            </div>
          );
        },
      },
      {Header: 'Premium Partner Code', accessor: 'premium_partner_code'},
      {
        Header: 'Status',
        accessor: 'status',
        Cell: row => {
          return <span>{row.row.original.status}</span>;
        },
      },
      {
        Header: 'Actions',
        Cell: ({row}) => (
          <td>
            <div className="text-nowrap">
              <button
                onClick={() => handleNavigate(row)}
                className="btn btn-sm btn-info icon-btn"
                title="Details"
                href="applicant-detail.html"
              >
                <i className="fa fa-info" aria-hidden="true"></i>
              </button>
            </div>
          </td>
        ),
      },
    ],
    []
  );

  const {data, isLoading, error} = useQuery(
    'pendingEpcForKyc',
    getPendingEpcForKyc
  );

  // useEffect(() => {
  //   setEpcData(data)
  // },[data.data])
  // console.log(data)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data || !data.data) {
    return <div>No data fetched.</div>;
  }
  // console.log(data)

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>Distributor Kyc Applicants</h3>
        </div>
        <LeadTable
          // setCurrentPageNumber={setCurrentPageNumber}
          pageNumber={0}
          tableName={'List of Distributor Applicants'}
          columns={columns}
          tableData={data && data.data}
          handleExport={handleExport}
          isFilterActive={isFilterActive}
          cityallowed
          stateallowed
          areaServedallowed
          startandenddateallowed
          ppallowed
          epcstatusallowed
        />
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default DistributorKyc;
