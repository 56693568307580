import {useState} from 'react';
import {NavLink} from 'react-router-dom';

import './style.css';

const Navigation = ({
  activeItem,
  handleItemClick,
  isMenuOpen,
  closeMenu,
  navItems,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = item => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div className="col">
      <nav className="navbar navbar-expand-xl p-0" id="solfin-nav">
        <div className="container-fluid">
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-xl-0">
              {navItems.map((item, index) => (
                <NavItem
                  key={index}
                  title={item.title}
                  links={item.links}
                  linkTo={item.linkTo}
                  active={activeItem === item.title}
                  onMouseEnter={() => handleMouseEnter(item.title)}
                  onMouseLeave={handleMouseLeave}
                  isHovered={hoveredItem === item.title}
                  handleNavItemClick={() => handleItemClick(item.title)}
                  onNavLinkClick={closeMenu}
                />
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

// Reusable NavItem component
const NavItem = ({
  title,
  active,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  handleNavItemClick,
  onNavLinkClick,
  links = [],
  linkTo,
}) => (
  <li
    className={`nav-item ${links.length > 0 ? 'dropdown' : ''} ${
      active ? 'active' : ''
    }`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {links.length > 0 ? (
      <>
        <span className="nav-link dropdown-toggle" onClick={handleNavItemClick}>
          {title}
        </span>
        <ul
          className={`dropdown-menu ${
            isHovered ? 'show' : ''
          } dropdown-item-wrapper`}
        >
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                className="dropdown-item"
                to={link.to}
                onClick={onNavLinkClick}
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <NavLink className="nav-link" to={linkTo} onClick={handleNavItemClick}>
        {title}
      </NavLink>
    )}
  </li>
);

export default Navigation;
