import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {logout} from '../service/dataService';
import {useMutation, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/images/solfin-admin-logo.svg';

const SideBarItems = ({data}) => {
  const [isToggle, setIsToggle] = useState(false);
  return (
    <li className={!data.subItems ? '' : 'sub-menu'}>
      {!data.subItems ? (
        <NavLink to={data.path}>
          <i className={data.icon}></i> <span>{data.name}</span>
        </NavLink>
      ) : (
        <>
          <a
            onClick={() => setIsToggle(prev => !prev)}
            className="nav-btn sidebar-toggle-div cursor-pointer"
          >
            <i className="fa-solid fa-file-lines"></i> <span>{data.name}</span>{' '}
            <div className="down-arrow">
              <i
                className={`fa right ${
                  isToggle ? 'fa-angle-up' : 'fa-angle-down'
                }`}
              ></i>
            </div>
          </a>
          <ul className={isToggle ? '' : 'd-none active'}>
            {data.subItems.map(sub => (
              <li key={sub.subId}>
                <NavLink to={sub.path}>
                  <span>{sub.subItemsName}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );
};

const SideBar = () => {
  const [isToggle, setIsToggle] = useState(false);
  // console.log(isToggle);

  const navigate = useNavigate();

  const Logout = async () => {
    // console.log("Logout")
    const response = await logout();
    //  console.log(response)
    if (response.status === 200) {
      // console.log("navigate")
      navigate('/login');
    }
  };

  const sidBarData = [
    //{ id: 1, name: "DashBoard", icon: "fa-solid fa-gauge", path: "/admin" },
    {
      id: 2,
      name: 'Leads',
      icon: 'fa-solid fa-file-lines',
      subItems: [
        {
          subId: 1,
          subItemsName: 'Estimator Leads',
          path: '/admin/estimator-leads',
        },
        {subId: 2, subItemsName: 'Application', path: '/admin/application'},
      ],
    },
    {
      id: 3,
      name: 'Customers',
      icon: 'fa-solid fa-users',
      path: '/admin/customers',
    },
    {
      id: 4,
      name: 'EPC',
      icon: 'fa-solid fa-file-lines',
      subItems: [
        //   {
        //   subId:7, subItemsName: "Leads" , path :"/admin/epc-leads"
        // },
        /*{ subId: 3, subItemsName: "Kyc Pending", path: "/admin/epc/kyc" },*/
        {subId: 3, subItemsName: 'Applicants', path: '/admin/epc-applicants'},
        {subId: 4, subItemsName: 'Empanelled', path: '/admin/epc-empanelled'},
      ],
    },
    {
      id: 7,
      name: 'Distributor',
      icon: 'fa-solid fa-file-lines',
      subItems: [
        {
          subId: 7,
          subItemsName: 'Leads',
          path: '/admin/distributor-leads',
        },
        {subId: 3, subItemsName: 'KYC Pending', path: '/admin/distributor/kyc'},
        {
          subId: 4,
          subItemsName: 'Empanelled',
          path: '/admin/distributor-empanelled',
        },
      ],
    },
    {
      id: 7,
      name: 'Channel Financing',
      icon: 'fa-solid fa-file-lines',
      subItems: [
        {
          subId: 7,
          subItemsName: 'Pending Invoices',
          path: '/admin/channelFinancing/invoices/pending',
        },
        {
          subId: 7,
          subItemsName: 'Verified Invoices',
          path: '/admin/channelFinancing/invoices/verified',
        },
      ],
    },
    {
      id: 5,
      name: 'DSA',
      icon: 'fa-brands fa-sellcast',
      subItems: [
        {subId: 5, subItemsName: 'Applicants', path: '/admin/dsa-applicants'},
        {subId: 6, subItemsName: 'Empanelled', path: '/admin/dsa-empanelled'},
      ],
    },
    {
      id: 6,
      name: 'Projects',
      icon: 'fa-solid fa-gauge',
      path: '/admin/projects',
    },
  ];

  return (
    <>
      <div className="solfin-sidebar">
        <div className="solfin-logo">
          <span>
            <img
              src="/images/solfin-admin-logo.svg"
              alt="Solfin"
              title="Solfin"
            />
          </span>
        </div>
        {/* <!-- /logo --> */}

        <div className="dashboard-navs">
          <ul>
            {sidBarData.map(data => (
              <SideBarItems key={data.id} data={data} />
            ))}

            {/* <li className="active">
              <a href="index.html">
                <i className="fa-solid fa-gauge"></i> <span>Dashboard</span>
              </a>
            </li>
            <li className="sub-menu">
              <a href="#Leads">
                <i className="fa-solid fa-file-lines"></i> <span>Leads</span>{" "}
                <div className="down-arrow">
                  <i className="fa right fa-angle-down"></i>
                </div>
              </a>
              <ul>
                <li>
                  <a href="estimators.html">
                    <span>Estimator Leads</span>
                  </a>
                </li>
                <li>
                  <a href="application.html">
                    <span>Application</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="customers.html">
                <i className="fa-solid fa-users"></i> <span>Customers</span>
              </a>
            </li>
            <li className="sub-menu">
              <a href="#EPC">
                <i claimgclassName="fa-solid fa-file-lines"></i>{" "}
                <span>EPC</span>{" "}
                <div className="down-arrow">
                  <i className="fa right fa-angle-down"></i>
                </div>
              </a>
              <ul>
                <li>
                  <a href="applicants.html">
                    <span>Applicants</span>
                  </a>
                </li>
                <li>
                  <a href="empanelled.html">
                    <span>Empanelled</span>
                  </a>
                </li>
              </ul>
            </li>
            <li className="sub-menu">
              <a href="#">
                <i className="fa-brands fa-sellcast"></i> <span>DSA</span>
                <div className="down-arrow">
                  <i className="fa right fa-angle-down"></i>
                </div>
              </a>
              <ul>
                <li>
                  <a href="dsa-applicants.html">
                    <span>Applicants</span>
                  </a>
                </li>
                <li>
                  <a href="dsa-empanelled.html">
                    <span>Empanelled</span>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a href="contracts.html">
                <i className="fa fa-helmet-safety"></i> <span>Contracts</span>
              </a>
            </li> */}
          </ul>
        </div>
        {/* <div className="logout">
          <button onClick={Logout}>
            <i className="fa-solid fa-right-from-bracket"></i>{" "}
            <span>Logout</span>
          </button>
        </div> */}
      </div>
      {/* <!-- /solfin-siderbar --> */}
    </>
  );
};

export default SideBar;
