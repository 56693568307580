import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import * as queryData from '../../service/dataService';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import '../../../App.css';
import {useNavigate} from 'react-router-dom';

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required('Please enter your Current Password'),
  newPassword: Yup.string().required('Please enter New Password'),
  confirmPassword: Yup.string()
    .required('Please confirm your New Password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  };

  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append('oldPassword', values.oldPassword);
        formData.append('newPassword', values.newPassword);

        changePassword.mutate(values);
        action.resetForm();
      },
    });
  const changePassword = useMutation(queryData.changePassword, {
    onError: error => {
      toast.error(error.message);
      // console.log(error.message);
    },
    onSuccess: data => {
      toast.success(data);
      navigate('/customer-dashboard');
    },
  });

  // const togglePasswordVisibility = () => {
  //   setOldPasswordVisible((prevVisible) => !prevVisible);
  //   setNewPasswordVisible((prevVisible) => !prevVisible);
  //   setConfirmPasswordVisible((prevVisible) => !prevVisible);

  // };

  return (
    <>
      <div
        className="edit-profile"
        id="PasswordChangeModal"
        aria-labelledby="PasswordChangeModalLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-4 fw-semibold"
                id="exampleModalLabel"
              >
                Change Password
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="#" method="get" onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <label htmlFor="oldPassword" className="mb-2">
                    Current Password
                  </label>
                  <div className="password-input-wrapper">
                    <input
                      // type={oldPasswordVisible ? "text" : "password"}
                      type="password"
                      name="oldPassword"
                      className="form-control"
                      value={values.oldPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {/* <button
      type="button"
       className="eye-icon-button"
      onClick={togglePasswordVisibility}
    >
        <i className={oldPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash" } />
     </button> */}
                  </div>
                  {errors.oldPassword && touched.oldPassword ? (
                    <p className="form-error text-danger">
                      {errors.oldPassword}
                    </p>
                  ) : null}
                </div>
                {/* <!-- form group --> */}

                <div className="form-group mb-4">
                  <label htmlFor="newPassword" className="mb-2">
                    New Password
                  </label>
                  <div className="password-input-wrapper">
                    <input
                      // type={newPasswordVisible ? "text" : "password"}
                      type="password"
                      name="newPassword"
                      className="form-control"
                      value={values.newPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {/* <button
      type="button"
       className="eye-icon-button"
      onClick={togglePasswordVisibility}
    >
        <i className={newPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash" } />
     </button> */}
                  </div>

                  {errors.newPassword && touched.newPassword ? (
                    <p className="form-error text-danger">
                      {errors.newPassword}
                    </p>
                  ) : null}
                </div>
                {/* <!-- form group --> */}

                <div className="form-group mb-4">
                  <label htmlFor="confirmPassword" className="mb-2">
                    Confirm Password
                  </label>
                  <div className="password-input-wrapper">
                    <input
                      // type={confirmPasswordVisible ? "text" : "password"}
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {/* <button
      type="button"
       className="eye-icon-button"
      onClick={togglePasswordVisibility}
    >
        <i className={confirmPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash" } />
     </button> */}
                  </div>

                  {errors.confirmPassword && touched.confirmPassword ? (
                    <p className="form-error text-danger">
                      {errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
                {/* <!-- form group --> */}

                {/* <!-- form group --> */}

                <div className="solfin-actions text-end mt-2">
                  <button
                    type="reset"
                    value="Cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border ps-4 pe-4 pt-3 pb-3"
                  >
                    {' '}
                    Cancel{' '}
                  </button>
                  <button
                    type="submit"
                    value="Change Password"
                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn ps-4 pe-4 pt-3 pb-3 ms-2"
                  >
                    Change Password{' '}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
