import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import * as querydata from '../../service/dataService';
import {useMutation} from 'react-query';
import {toast} from 'react-toastify';
import StateSelect from '../../components/StateSelect';
import CitySelect from '../../components/CitySelect';
import {Tooltip} from 'react-tooltip';
import GoogleMap from '../../../components/GoogleMap';
import CustomerType from '../../../FrontEnd/components/CustomerType';
//client Validation
export const newRegisterSchema = Yup.object({
  pinCode: Yup.string()
    .required('PinCode is required')
    .length(6, 'Pincode must be exactly 6 digits')
    .matches(/^\d+$/, 'Pincode must only contain digits'),
  cityId: Yup.number().required('Please select city'),
  address1: Yup.string().required('Please enter your address'),
  stateId: Yup.string().required('Please select state'),
  consumerNumber: Yup.string().required(
    'Please Enter your Electricity consumer number'
  ),
  //pan: Yup.string().max(10).required("Please enter your pan card number"),
  customerTypeId: Yup.number().required('Please select registrant type'),
  companyName: Yup.string(),
});

const NewApplication = () => {
  console.log('NewApplication----->');
  const [geocords, setgeocords] = useState('');
  const [selectedState, setSelectedState] = useState('-1');

  const [isChecked, setIsChecked] = useState(false);
  const [selectedCustomerTypeCode, setSelectedCustomerTypeCode] = useState('');
  const [editable, setEditable] = useState(true);
  //clear all fields
  const closeerrormessage = () => window.location.reload();

  //initialize Navigate method
  const navigate = useNavigate();

  // variable for file upload
  const [file, setfile] = useState('');

  // values defined for formik
  const initialValues = {
    customerTypeId: '',
    companyName: '',
    pinCode: '',
    cityId: '',
    address1: '',
    isFinanceRequired: '1',
    stateId: selectedState,
    googleCoordinates: '',
    consumerNumber: '',
    pan: '',
  };

  //set file and file Name
  const handlefilechange = e => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'png']; // Allowed file formats

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

      // Check if the file format is allowed
      if (allowedFormats.includes(fileExtension)) {
        setfile(selectedFile);
        document.getElementById('filetextid').value = selectedFile.name;
        error.file = '';
      } else {
        setfile(null);
        document.getElementById('filetextid').value = '';
        error.file =
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ');
      }
    } else {
      setfile(null);
      document.getElementById('filetextid').value = '';
      error.file = 'Please Upload Document';
    }
  };

  //build Model and fill data in formik model
  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,
      validationSchema: newRegisterSchema,

      onSubmit: (values, action) => {
        // console.log(values, "values....")

        const formData = new FormData();
        formData.append('googleCoordinates', geocords);
        formData.append('pinCode', values.pinCode);
        formData.append('cityId', values.cityId);
        formData.append('address1', values.address1);
        formData.append('isFinanceRequired', values.isFinanceRequired);
        formData.append('billImage', file);
        formData.append('pan', values.pan);
        formData.append('consumerNumber', values.consumerNumber);
        formData.append('customerTypeId', values.customerTypeId);
        if (
          !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(selectedCustomerTypeCode)
        ) {
          formData.append('companyName', values.companyName);
        }
        const newErrors = validateForm();
        if (Object.keys(newErrors).length === 0) {
          insertApplication.mutate(formData);
        } else {
          setErrors(newErrors);
        }

        // console.log(formData, "formdata....")

        // action.resetForm();
      },
    });
  const [error, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!errors.customerTypeId && !touched.customerTypeId) {
      if (!values.customerTypeId || values.customerTypeId == -1) {
        newErrors.customerTypeId = 'Please select registrant type';
        toast.error('Please select registrant type');
        document.getElementById('RegistantsType').focus();
      }
    } else {
      if (!values.customerTypeId || values.customerTypeId == -1) {
        toast.error('Please select registrant type');
        touched.customerTypeId = 'Please select registrant type';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('RegistantsType').focus();
      }
    }

    //company name
    if (
      values.customerTypeId &&
      !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(selectedCustomerTypeCode)
    ) {
      if (!errors.companyName && !touched.companyName) {
        if (!values.companyName) {
          newErrors.companyName = 'Please enter your company name';
          toast.error('Please enter your company name');
          document.getElementById('CompanyName').focus();
        }
      } else {
        if (!values.companyName) {
          toast.error('Please enter your company name');
          touched.companyName = 'Please enter your company name';
          newErrors.companyName = 'Please enter your company name';
          document.getElementById('CompanyName').focus();
        }
      }
    }

    if (!errors.consumerNumber && !touched.consumerNumber) {
      if (!values.consumerNumber) {
        newErrors.consumerNumber =
          'Please Enter your Electricity consumer number';
        toast.error('Please Enter your Electricity consumer number');
        document.getElementById('elec_consumer_number').focus();
      }
    } else {
      if (!values.consumerNumber) {
        toast.error('Please Enter your Electricity consumer number');
        touched.consumerNumber =
          'Please Enter your Electricity consumer number';
        newErrors.customerNames =
          'Please Enter your Electricity consumer number';
        document.getElementById('elec_consumer_number').focus();
      }
    }

    if (!errors.address1 && !touched.address1) {
      if (!values.address1) {
        newErrors.address1 = 'Please enter your address';
        toast.error('Please enter your address');
        document.getElementById('Address').focus();
      }
    } else {
      if (!values.address1) {
        toast.error('Please enter your address');
        touched.address1 = 'Please enter your address';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Address').focus();
      }
    }

    if (!errors.pinCode && !touched.pinCode) {
      if (!values.pinCode) {
        newErrors.pinCode = 'PinCode is required';
        toast.error('PinCode is required');
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        newErrors.pinCode = 'Pincode must only contain digits';
        toast.error('Pincode must only contain digits');
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        newErrors.pinCode = 'Pincode must be exactly 6 digits';
        toast.error('Pincode must be exactly 6 digits');
        document.getElementById('Pincode').focus();
      }
    } else {
      if (!values.pinCode) {
        toast.error('PinCode is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        toast.error('Pincode must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        toast.error('Pincode must be exactly 6 digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      }
      touched.pinCode = 'PinCode is required';
    }

    if (!errors.stateId && !touched.stateId) {
      if (values.stateId == -1) {
        newErrors.stateId = 'Please select State';
        toast.error('Please select State');
        document.getElementById('State').focus();
      }
    } else {
      if (values.stateId == -1) {
        toast.error('Please select States');
        touched.stateId = 'Please select States';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('State').focus();
      }
    }
    // if (!values.pan || values.pan.length==0) {
    //   newErrors.pan = 'Please Upload Pan File';
    //   toast.error("Please Upload Pan File");
    // }
    if (values.isFinanceRequired !== '0') {
      if (!errors.pan && !touched.pan) {
        if (!values.pan) {
          newErrors.pan = 'Please enter your pan card number';
          toast.error('Please enter your pan card number');
          document.getElementById('PANNumber').focus();
        }
      } else {
        if (!values.pan) {
          toast.error('Please enter your pan card number');
          touched.pan = 'Please enter your pan card number';
          newErrors.customerNames = 'Please enter full name';
          document.getElementById('PANNumber').focus();
        }
      }
    }

    if (!file || file.length == 0) {
      newErrors.file = 'Please Upload Electricity Bill';
      toast.error('Please Upload Electricity Bill');
      document.getElementById('filetextid').focus();
    }
    if (!errors.cityId && !touched.cityId) {
      if (!values.cityId) {
        newErrors.cityId = 'Please select City';
        toast.error('Please select City');
        document.getElementById('City').focus();
      }
    } else {
      if (!values.cityId) {
        toast.error('Please select City');
        touched.cityId = 'Please select City';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('City').focus();

        // newErrors.city_Id = 'Please select City';
      }
    }
    return newErrors;
  };

  //form post
  const insertApplication = useMutation(querydata.insertNewApplication, {
    onError: error => {
      toast.error(error.message);
      // console.log("error", error.message)
    },
    onSuccess: data => {
      toast.success(data.message);
      // if(data.redirectToVegePay){
      //   window.location.href = 'https://finance.solfin.co.in/onboarding';
      // }
      // else{
      //   navigate("/customer-registration-complete");
      // }
      navigate('/customer/registration-complete', {
        state: {isFinanceRequired: values.isFinanceRequired},
      });
    },
  });

  console.log(values);
  return (
    <section>
      <div className="saving-calculator solfin-profile cunsumer-top-space header-space pb-2 pt-1">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="mb-1">
                <h5 className="fw-bold">New Application</h5>
              </div>
              <div className="registration-form application-form m-auto">
                <form id="customerform">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group pb-2 mb-3">
                        <label htmlFor="RegistantsType" className="mb-2">
                          Registrant Type
                        </label>
                        <CustomerType
                          parameter={values}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setSelectedCustomerTypeCode={
                            setSelectedCustomerTypeCode
                          }
                        />
                        {errors.customerTypeId && touched.customerTypeId ? (
                          <p className="form-error text-danger 2">
                            {errors.customerTypeId}
                          </p>
                        ) : null}
                      </div>
                      {/* <!-- /form-group --> */}
                    </div>
                  </div>

                  {values.customerTypeId &&
                  !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(
                    selectedCustomerTypeCode
                  ) ? (
                    <div className="saving-calculate-form registration-form">
                      <div className="heading mb-4">
                        <span className="fw-semibold saving-title">
                          Personal Details
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="CompanyName"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Organisation Name</span>

                              <input
                                type="text"
                                id="CompanyName"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Enter Name"
                                name="companyName"
                                value={values.companyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </label>
                            {errors.companyName && touched.companyName ? (
                              <p className="form-error text-danger">
                                {errors.companyName}
                              </p>
                            ) : error.companyName ? (
                              <p className="form-error text-danger">
                                {error.companyName}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="saving-calculate-form registration-form">
                    <div className="heading mb-2 pt-1">
                      <span className="fw-semibold saving-title">
                        Location Details
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Pincode"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Pincode</span>
                            <input
                              type="text"
                              id="Pincode"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Area Pincode"
                              name="pinCode"
                              value={values.pinCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </label>
                          {errors.pinCode && touched.pinCode ? (
                            <p className="form-error text-danger">
                              {errors.pinCode}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          {/* <label for="State" class="border rounded-4 w-100 position-relative">
                                        <span class="fw-bold">State</span>
                                        <select id="State" class="form-control border-0 ps-0 bg-transparent pt-1 pb-1">
                                            <option value="1" selected="Select State">Select State</option>
                                            <option value="2">Haryana</option>
                                            <option value="Pubjab">Punjab</option>
                                            <option value="3">Uttar Pardesh</option>
                                            <option value="4">Delhi</option>
                                        </select>
                                    </label> */}
                          <StateSelect
                            values={values}
                            setSelectedState={setSelectedState}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          {/* <label for="City" class="border rounded-4 w-100 position-relative">
                                        <span class="fw-bold">City</span>
                                        <select id="State" class="form-control border-0 ps-0 bg-transparent pt-1 pb-1">
                                            <option value="1" selected="Select City">Select City</option>
                                            <option value="2">Panchkula</option>
                                            <option value="3">Jind</option>
                                            <option value="4">Rewari</option>
                                            <option value="5">Jhajjar</option>
                                        </select>
                                    </label> */}
                          <CitySelect
                            parameter={values}
                            selectedState={selectedState}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-12">
                        <div className="form-group mb-2">
                          <label
                            htmlFor="Address"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Address</span>
                            <input
                              type="text"
                              id="Address"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Type Your Address"
                              name="address1"
                              value={values.address1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </label>
                          {errors.address1 && touched.address1 ? (
                            <p className="form-error text-danger">
                              {errors.address1}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-12 --> */}
                      <div className="col-12">
                        <div className="mark-location-map fw-semibold mt-lg-1">
                          <span>Mark Location on Map</span>{' '}
                          <a
                            className="info icon-info-sign"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Click on Satellite button on the top right corner of the Map and check mark Labels to view names of places"
                          >
                            <i className="fa fa-circle-info"></i>
                          </a>
                          <Tooltip id="my-tooltip" />
                          <div
                            className="map-location mt-3"
                            id="contentContainer"
                          >
                            <GoogleMap
                              pinCode={values.pinCode}
                              address1={values.address1}
                              values={values}
                              setgeocords={setgeocords}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  <div className="col-lg-12 mb-3 mt-3">
                    <div className="form-group mt-1 ps-xl-4">
                      <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                        <div className="location-title medium-title mw-100">
                          <span className="fw-bold">Financing required?</span>
                        </div>
                        <label htmlFor="Yes" className="checkboxes-view p-0">
                          <div className="check-now position-relative d-flex align-items-center">
                            <input
                              type="radio"
                              defaultChecked
                              id="Yes"
                              className="position-absolute top-0"
                              name="isFinanceRequired"
                              value={1}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="now-check rounded-pill position-relative"></div>
                            <span>Yes</span>
                          </div>
                          {/* <!-- /check-now --> */}
                        </label>
                        <label htmlFor="No" className="checkboxes-view p-0">
                          <div className="check-now position-relative d-flex align-items-center">
                            <input
                              type="radio"
                              id="No"
                              className="position-absolute top-0"
                              name="isFinanceRequired"
                              value={0}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div className="now-check rounded-pill position-relative"></div>
                            <span>No</span>
                          </div>
                          {/* <!-- /check-now --> */}
                        </label>
                      </div>
                      {/* <!-- /checkbox-labels --> */}
                    </div>
                    {/* <!-- /form-group --> */}
                    {/* <!-- <div class="check-conditions mb-0">
                                  <label for="AllowTerms" class="checkboxes-view p-0 position-relative d-flex">
                                      <input type="checkbox" name="" class="position-absolute top-0" id="AllowTerms">
                                      <div class="now-check position-relative m-0"></div>
                                      <p>By clicking here, I state that I have read and understood the <a class="fw-bold text-dark text-decoration-underline" href="terms-conditions.html" target="_blank">Terms and Conditions</a></p>
                                  </label>
                              </div> --> */}
                  </div>
                  <div className="saving-calculate-form mt-3">
                    <div className="row">
                      {values.isFinanceRequired === '1' ? (
                        <>
                          <div className="col-xl-6">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="PANNumber"
                                className="border rounded-4 w-100 position-relative"
                              >
                                <span className="fw-bold">PAN Number</span>
                                <input
                                  id="PANNumber"
                                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                  placeholder="Type PAN Number"
                                  name="pan"
                                  value={values.pan}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </label>
                              {errors.pan && touched.pan ? (
                                <p className="form-error text-danger">
                                  {errors.pan}
                                </p>
                              ) : null}
                            </div>
                            {/* <!-- /form-group --> */}
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-6">
                        <div className="form-group mb-3">
                          <label
                            htmlFor="elec_consumer_number"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">
                              Electricity Consumer Number
                            </span>
                            <input
                              type="text"
                              id="elec_consumer_number"
                              name="consumerNumber"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Type Electricity Consumer Number"
                              value={values.consumerNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.consumerNumber && touched.consumerNumber ? (
                              <p className="form-error text-danger">
                                {errors.consumerNumber}
                              </p>
                            ) : null}
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <div className="border rounded-4 w-100 position-relative label-form">
                            <span className="fw-bold">
                              Upload Latest Electricity Bill
                            </span>
                            <div className="form-group">
                              <input
                                type="text"
                                name="filename"
                                id="filetextid"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Upload Bill"
                                readOnly
                              />
                              <span className="input-group-btn unit-label upload-docs position-absolute">
                                <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                  <input
                                    type="file"
                                    // onChange="this.form.filename.value = this.files.length ? this.files[0].name : ''" />
                                    name="file"
                                    onChange={handlefilechange}
                                    disabled={!editable}
                                  />
                                  Upload
                                </div>
                              </span>
                            </div>
                          </div>
                          {errors.file && touched.file ? (
                            <p className="form-error text-danger">
                              {errors.file}
                            </p>
                          ) : error.file ? (
                            <p className="form-error text-danger">
                              {error.file}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}

                      {/* <!-- /col-lg-4 col-md-6 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between flex-lg-nowrap flex-wrap">
                      <div className="solfin-action mb-4">
                        {insertApplication.isLoading ? (
                          <button
                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn opacity-100 spinner-btn2"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden" role="status">
                              Loading...
                            </span>
                          </button>
                        ) : (
                          <input
                            type="submit"
                            value="Submit"
                            onClick={handleSubmit}
                            id="submitButton"
                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                          />
                        )}
                      </div>
                      <div className="solfin-query-call d-flex align-items-center d-none">
                        <span>Any questions? Call us now.</span>
                        <span className="ps-3">
                          <a href="tel:9599446877">
                            <img
                              src="../images/call-us.svg"
                              alt="Call Us"
                              title="Call Us"
                            />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /col-12 --> */}
                </form>
              </div>
              {/* <!-- /registration-form --> */}
            </div>
            {/* <!-- /col-12 --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /my-products --> */}
    </section>
    //  <!--Products Head end-->
  );
};

export default NewApplication;
