import React from 'react';

const ProjectApproveModal = ({handleApprove, createProject}) => {
  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              Create Project
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            Once you approve these documents this application will be considered
            complete and the project will be created
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-info filter-btn"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {createProject.isLoading ? (
              <button
                className="opacity-100 btn btn-info spinner-btn2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden" role="status">
                  Loading...
                </span>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-info approve-project-btn"
                onClick={handleApprove}
                data-bs-dismiss="modal"
              >
                Create the project
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectApproveModal;
