import {Route, Routes, useNavigate} from 'react-router-dom';
import Layout from '../components/Layout';
import Details from '../pages/Details';
import PendingInvoices from '../Distributor/PendingInvoices';
import VerifiedInvoices from '../Distributor/VerifiedInvoices';
import LoanSummary from '../Distributor/LoanSummary';

const EpcRoutes = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/epc-dashboard" element={<><Layout></Layout></>} /> */}
        <Route
          path="/distributor-dashboard"
          element={
            <>
              <Layout>
                <Details />
              </Layout>
            </>
          }
        />
        {/* <Route path="/epc/my-leads" element={<><Layout><MyLeads/></Layout></>} /> */}
        <Route
          path="/distributor-dashboard/pending-invoices"
          element={
            <>
              <Layout>
                <PendingInvoices />
              </Layout>
            </>
          }
        />
        <Route
          path="/distributor-dashboard/verified-invoices"
          element={
            <>
              <Layout>
                <VerifiedInvoices />
              </Layout>
            </>
          }
        />
        <Route
          path="/distributor-dashboard/loan-summary"
          element={
            <>
              <Layout>
                <LoanSummary />
              </Layout>
            </>
          }
        />
      </Routes>
    </>
  );
};

export default EpcRoutes;
