import {Route, Routes} from 'react-router-dom';
import Layout from '../components/Layout';
import RequestedComfortLetters from '../pages/RequestedComfortLetters';
import ComfortLetterDetails from '../pages/ComfortLetterDetails';

const ManufacturerRoutes = () => {
  return (
    <Routes>
      <Route
        path="manufacturer-dashboard/comfort-letters/details/:letterCode"
        element={
          <Layout>
            <ComfortLetterDetails />
          </Layout>
        }
      />
      <Route
        path="manufacturer-dashboard"
        element={
          <Layout>
            <RequestedComfortLetters />
          </Layout>
        }
      />
      <Route
        path="manufacturer-dashboard/comfort-letters/signed"
        element={
          <Layout>
            <RequestedComfortLetters />
          </Layout>
        }
      />
    </Routes>
  );
};

export default ManufacturerRoutes;
