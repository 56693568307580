import React, {useRef} from 'react';
import {useQuery} from 'react-query';
import * as queryData from '../service/dataService';
import {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Filter = ({
  isFilterActive,
  setFilter,
  setFilteredData,
  tableData,
  cityallowed,
  stateallowed,
  customertypeallowed,
  statusallowed,
  esstatusallowed,
  customerstatusallowed,
  publishstatusallowed,
  epcstatusallowed,
  pepcstatusallowed,
  eplstatusallowed,
  startandenddateallowed,
  areaServedallowed,
  connecttoGridAllowed,
  locationtypeallowed,
  sourceAllowed,
  financingallowed,
  ppallowed,
  assignedtoallowed,
  noofprojectsAllowed,
  fpAllowed,
  acallowed,
  exallowed,
  pstatusallowed,
  istartandenddateallowed,
  dallowed,
  eallowed,
}) => {
  const [locationtypeId, setLocationtypeId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [areaservedId, setAreaServedId] = useState(null);
  const [source, setSource] = useState(null);
  const [noofprojects, setNoOfProjects] = useState(null);
  const [noofprojectsmax, setNoOfProjectsMax] = useState(null);
  const [fp, setFP] = useState(null);
  const [typeId, settypeId] = useState(null);
  const [statusId, setstatusId] = useState(null);
  const [dId, setDId] = useState(null);
  const [eId, setEId] = useState(null);
  const [esstatusId, setEsstatusId] = useState(null);
  const [customerstatusId, setCustomerStatusId] = useState(null);
  const [publishstatusId, setPublishStatusId] = useState(null);
  const [epcstatusId, setEPCStatusId] = useState(null);
  const [pepcstatusId, setPEPCStatusId] = useState(null);
  const [pstatusId, setPStatusId] = useState(null);
  const [eplstatusId, setEPLStatusId] = useState(null);
  const [assignedtoId, setAssignedToId] = useState(null);
  const [financingId, setFinancingId] = useState(null);
  const [ppId, setPPId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [istartDate, setIStartDate] = useState(null);
  const [iendDate, setIEndDate] = useState(null);
  const [connecttoGrid, setConnectedtoGrid] = useState(null);
  const [acId, setACId] = useState(null);
  const [exId, setEXId] = useState(null);

  const handleOptionChange = event => {
    setConnectedtoGrid(event.target.value);
  };

  const locationtypedropdownRef = useRef(null);
  const statedropdownRef = useRef(null);
  const citydropdownRef = useRef(null);
  const areaserveddropdownRef = useRef(null);
  const sourceRef = useRef(null);
  const noofprojectsRef = useRef(null);
  const noofprojectsmaxRef = useRef(null);
  const fpRef = useRef(null);
  const typedropdownRef = useRef(null);
  const statusdropdownRef = useRef(null);
  const ddropdownRef = useRef(null);
  const edropdownRef = useRef(null);
  const esstatusdropdownRef = useRef(null);
  const customerstatusdropdownRef = useRef(null);
  const publishstatusdropdownRef = useRef(null);
  const epcstatusdropdownRef = useRef(null);
  const pepcstatusdropdownRef = useRef(null);
  const pstatusdropdownRef = useRef(null);
  const eplstatusdropdownRef = useRef(null);
  const assignedtodropdownRef = useRef(null);
  const financingdropdownRef = useRef(null);
  const ppdropdownRef = useRef(null);
  const acdropdownRef = useRef(null);
  const exdropdownRef = useRef(null);

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };
  const handleIStartDateChange = date => {
    setIStartDate(date);
  };

  const handleIEndDateChange = date => {
    setIEndDate(date);
  };

  // console.log(tableData.created_on, "date")

  const handleFiltersChange = () => {
    const filtered = tableData.filter(item1 => {
      const [date, time] = item1.created_on.split(' ');
      const [day, month, year] = date.split('/');
      // const [hour, minute] = time.split(':');

      // Create a new date string in "mm/dd/yyyy" format
      const convertedDateString = `${month}/${day}/${year}`;

      const sourcename = 'organic';
      const sourcename1 = 'dsa-';
      const itemDate = new Date(convertedDateString);

      if (item1.installation_date != null) {
        const installationDate = new Date(item1.installation_date);

        //2023-10-28T18:30:00.000Z
        // week month date year
        const [iweek, imonth, iday, iyear, itime] = installationDate
          .toString()
          .split(' ');
        //const [iyear, imonth, iday] = idate.split('-');
        // Create a new date string in "mm/dd/yyyy" format
        const iconvertedDateString = `${imonth}/${iday}/${iyear}`;
        const iitemDate = new Date(iconvertedDateString);

        return (
          (!startDate || itemDate >= new Date(startDate)) &&
          (!endDate || itemDate <= new Date(endDate)) &&
          (!istartDate || iitemDate >= new Date(istartDate)) &&
          (!iendDate || iitemDate <= new Date(iendDate)) &&
          (!areaservedId ||
            item1.service_area
              .toLowerCase()
              .includes(areaservedId.toLowerCase())) &&
          (!noofprojects ||
            parseInt(item1.total_projects) >= parseInt(noofprojects)) &&
          (!noofprojectsmax ||
            parseInt(item1.total_projects) <= parseInt(noofprojectsmax)) &&
          // && (!noofprojects || item1.total_projects == (noofprojects))
          (!fp ||
            (item1.finance_partner != null &&
              item1.finance_partner
                .toLowerCase()
                .includes(fp.toLowerCase()))) &&
          (!source ||
            (item1.dsa_name != null &&
              item1.dsa_name.toLowerCase().includes(source.toLowerCase())) ||
            (sourcename.includes(source.toLowerCase()) &&
              item1.dsa_name == null) ||
            (sourcename1.includes(source.toLowerCase()) &&
              item1.dsa_name != null)) &&
          (!cityId || item1.city_id == cityId.toString()) &&
          (!stateId || item1.state_id == stateId.toString()) &&
          (!connecttoGrid ||
            item1.connect_to_grid == connecttoGrid.toString()) &&
          (!typeId || item1.customer_type_id == typeId.toString()) &&
          (!locationtypeId ||
            item1.location_type_id == locationtypeId.toString()) &&
          (!statusId || item1.status_id == statusId.toString()) &&
          (!customerstatusId ||
            item1.status_id == customerstatusId.toString()) &&
          (!publishstatusId ||
            (item1.is_published == 1 && publishstatusId == 1) ||
            ((item1.is_published == null || item1.is_published == 0) &&
              publishstatusId == 0)) &&
          (!epcstatusId ||
            item1.status.toLowerCase() == epcstatusId.toLowerCase()) &&
          (!eplstatusId || item1.status_id == eplstatusId) &&
          (!pepcstatusId || item1.status_id == pepcstatusId) &&
          (!pstatusId || item1.project_status_id == pstatusId) &&
          (!dId || item1.dsa_id == dId) &&
          (!eId || item1.epc_id == eId) &&
          (!acId || item1.activity_ids.includes(acId)) &&
          (!exId || item1.experience_id == exId) &&
          (!assignedtoId ||
            item1.assigned_to_epc == assignedtoId.toString() ||
            (assignedtoId.toString() == 'unassigned' &&
              item1.assigned_to_epc == null)) &&
          (!esstatusId || item1.status_id == esstatusId.toString()) &&
          (!financingId ||
            item1.is_finance_required == financingId.toString()) &&
          (!ppId ||
            (item1.premium_partner_code != null && ppId == 1) ||
            (item1.premium_partner_code == null && ppId == 0))
        );
      } else {
        return (
          (!startDate || itemDate >= new Date(startDate)) &&
          (!endDate || itemDate <= new Date(endDate)) &&
          (!istartDate ||
            !iendDate ||
            (istartDate != null &&
              iendDate != null &&
              item1.installation_date != null) ||
            istartDate == null ||
            iendDate == null ||
            item1.installation_date) &&
          (!areaservedId ||
            item1.service_area
              .toLowerCase()
              .includes(areaservedId.toLowerCase())) &&
          // && (!noofprojects || item1.total_projects == (noofprojects))
          (!noofprojects ||
            parseInt(item1.total_projects) >= parseInt(noofprojects)) &&
          (!noofprojectsmax ||
            parseInt(item1.total_projects) <= parseInt(noofprojectsmax)) &&
          (!fp ||
            (item1.finance_partner != null &&
              item1.finance_partner
                .toLowerCase()
                .includes(fp.toLowerCase()))) &&
          (!source ||
            (item1.dsa_name != null &&
              item1.dsa_name.toLowerCase().includes(source.toLowerCase())) ||
            (sourcename.includes(source.toLowerCase()) &&
              item1.dsa_name == null) ||
            (sourcename1.includes(source.toLowerCase()) &&
              item1.dsa_name != null)) &&
          (!cityId || item1.city_id == cityId.toString()) &&
          (!stateId || item1.state_id.toString() == stateId.toString()) &&
          (!connecttoGrid ||
            item1.connect_to_grid == connecttoGrid.toString()) &&
          (!typeId || item1.customer_type_id == typeId.toString()) &&
          (!locationtypeId ||
            item1.location_type_id == locationtypeId.toString()) &&
          (!statusId || item1.status_id == statusId.toString()) &&
          (!customerstatusId ||
            item1.status_id == customerstatusId.toString()) &&
          (!publishstatusId ||
            (item1.is_published == 1 && publishstatusId == 1) ||
            ((item1.is_published == null || item1.is_published == 0) &&
              publishstatusId == 0)) &&
          (!epcstatusId ||
            item1.status.toLowerCase() == epcstatusId.toLowerCase()) &&
          (!eplstatusId || item1.status_id == eplstatusId) &&
          (!pepcstatusId || item1.status_id == pepcstatusId) &&
          (!pstatusId || item1.project_status_id == pstatusId) &&
          (!dId || item1.dsa_id == dId) &&
          (!eId || item1.epc_id == eId) &&
          (!acId || item1.activity_ids.includes(acId)) &&
          (!exId || item1.experience_id == exId) &&
          (!assignedtoId ||
            item1.assigned_to_epc == assignedtoId.toString() ||
            (assignedtoId.toString() == 'unassigned' &&
              item1.assigned_to_epc == null)) &&
          (!esstatusId || item1.status_id == esstatusId.toString()) &&
          (!financingId ||
            item1.is_finance_required == financingId.toString()) &&
          (!ppId ||
            (item1.premium_partner_code != null && ppId == 1) ||
            (item1.premium_partner_code == null && ppId == 0))
        );
      }
    });
    setFilteredData(filtered);
  };
  const handleClearFilters = () => {
    if (stateallowed) statedropdownRef.current.value = ''; // Reset the dropdown value
    if (locationtypeallowed) locationtypedropdownRef.current.value = '';
    if (cityallowed) citydropdownRef.current.value = ''; // Reset the dropdown value
    if (customertypeallowed) typedropdownRef.current.value = ''; // Reset the dropdown value
    if (statusallowed) statusdropdownRef.current.value = ''; // Reset the dropdown value
    if (dallowed) ddropdownRef.current.value = ''; // Reset the dropdown value
    if (eallowed) edropdownRef.current.value = ''; // Reset the dropdown value
    if (customerstatusallowed) customerstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (publishstatusallowed) publishstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (epcstatusallowed) epcstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (pepcstatusallowed) pepcstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (pstatusallowed) pstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (eplstatusallowed) eplstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (esstatusallowed) esstatusdropdownRef.current.value = ''; // Reset the dropdown value
    if (financingallowed) financingdropdownRef.current.value = ''; // Reset the dropdown value
    if (ppallowed) ppdropdownRef.current.value = ''; // Reset the dropdown value
    if (assignedtoallowed) assignedtodropdownRef.current.value = ''; // Reset the dropdown value
    if (acallowed) acdropdownRef.current.value = ''; // Reset the dropdown value
    if (exallowed) exdropdownRef.current.value = ''; // Reset the dropdown value

    if (areaServedallowed) areaserveddropdownRef.current.value = ''; // Reset the dropdown value
    if (sourceAllowed) sourceRef.current.value = ''; // Reset the dropdown value
    if (noofprojectsAllowed) noofprojectsRef.current.value = ''; // Reset the dropdown value
    if (noofprojectsAllowed) noofprojectsmaxRef.current.value = ''; // Reset the dropdown value
    // if (fpAllowed)
    //   fpRef.current.value = ''; // Reset the dropdown value

    setStateId('');
    setLocationtypeId('');
    setCityId('');
    setAreaServedId('');
    setSource('');
    setNoOfProjects('');
    setNoOfProjectsMax('');
    setFP('');
    settypeId('');
    setstatusId('');
    setDId('');
    setEId('');
    setCustomerStatusId('');
    setPublishStatusId('');
    setEPCStatusId('');
    setPEPCStatusId('');
    setPStatusId('');
    setEPLStatusId('');
    setEsstatusId('');
    setAssignedToId('');
    setFinancingId('');
    setPPId('');
    setACId('');
    setEXId('');
    setStartDate('');
    setEndDate('');
    setIStartDate('');
    setIEndDate('');
    setConnectedtoGrid('');
    setFilteredData(tableData);
  };

  const getAllStatesQuery = useQuery('getAllStates', queryData.getAllStates);
  const getAllCitiesQuery = useQuery('getAllCities', queryData.getAllCities);

  const getAllTypesQuery = useQuery(
    'getAllTypes',
    queryData.getAllCustomerTypes
  );
  const getAllLocationTypesQuery = useQuery(
    'getAllLocationTypes',
    queryData.getAllLocationTypes
  );

  const getFilteredCities = useQuery(['getCitiesById', +stateId], () =>
    queryData.getCitiesById(+stateId)
  );
  // const getAllStatusQuery = useQuery('getAllStatus', queryData.getEmpanelledStatus)
  const applicationStatus = useQuery(
    'getApplicationStatus',
    queryData.getApplicationStatus
  );
  const getLeadStatus = useQuery('getLeadStatus', queryData.getLeadsStatus);
  const getEpcAssignedLeads = useQuery(
    'getEpcAssignedLeads',
    queryData.getAllEpcForAssignment
  );
  const getCustomerStatus = useQuery(
    'getCustomerStatus',
    queryData.getCustomerStatus
  );
  const getEmpanelledStatus = useQuery(
    'getEmpanelledStatus',
    queryData.getEmpanelledStatus
  );
  const getActivities = useQuery('getActivities', queryData.getActivities);
  const getExperiences = useQuery('getExperiences', queryData.getExperiences);
  const getEPCListForQuote = useQuery(
    'getEPCListForQuote',
    queryData.getEPCListForQuote
  );
  const getDSAListForQuote = useQuery(
    'getDSAListForQuote',
    queryData.getDSAListForQuote
  );
  const getProjectStatusProvidedByAdmin = useQuery(
    'getProjectStatusProvidedByAdmin',
    queryData.getProjectStatusProvidedByAdmin
  );
  const applicationStatusProvidedByEpc = useQuery(
    'applicationStatusProvidedByEpc',
    queryData.applicationStatusProvidedByEpc
  );

  // if (getFilteredCities.data) {

  //   console.log(getFilteredCities.data.data)
  // }

  if (getAllStatesQuery.isLoading) {
    return <div>Loading...</div>;
  }

  if (getAllStatesQuery.error) {
    return <div>Error:</div>;
  }

  if (!getAllStatesQuery.data) {
    return <div>No data fetched.</div>;
  }
  // console.log(getAllStatesQuery.data.data)
  return (
    <>
      <div id="filters" className={`pb-4 ${!isFilterActive ? 'd-none' : ''}`}>
        <div className="row">
          <div className="col-12">
            <div className="row g-3">
              {locationtypeallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Location Type</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={locationtypedropdownRef}
                    required
                    onChange={e => setLocationtypeId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllLocationTypesQuery.data &&
                      getAllLocationTypesQuery.data.data.map(data => (
                        <>
                          <option value={data.location_type_id}>
                            {data.location_type}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {stateallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">State</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={statedropdownRef}
                    required
                    onChange={e => setStateId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllStatesQuery.data &&
                      getAllStatesQuery.data.data.map(data => (
                        <>
                          <option value={data.state_id}>{data.state}</option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {cityallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">City</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={citydropdownRef}
                    required
                    onChange={e => setCityId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getFilteredCities.data &&
                      getFilteredCities.data.data.map(data => (
                        <>
                          <option value={data.city_id}>{data.city}</option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {areaServedallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Area Served</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={areaserveddropdownRef}
                    required
                    onChange={e =>
                      setAreaServedId(e.target.selectedOptions[0].textContent)
                    }
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllStatesQuery.data &&
                      getAllStatesQuery.data.data.map(data => (
                        <>
                          <option value={data.state_id}>{data.state}</option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {/* {areaServedallowed ?
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Area Served</label>
                  <input ref={areaserveddropdownRef} onChange={(e) => setAreaServedId(e.target.value)} className="form-control" placeholder="Search" />
                </div> : ""} */}
              {sourceAllowed ? (
                // <div className="col-12 col-sm-6 col-lg-2">
                //   <label className="form-label">Source</label>
                //   <input ref={sourceRef} onChange={(e) => setSource(e.target.value)} className="form-control" placeholder="Search" />
                // </div> : ""
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Source</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={sourceRef}
                    required
                    onChange={e => setSource(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="Organic">Organic</option>
                    <option value="DSA">DSA</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {noofprojectsAllowed ? (
                <div className="col-12 col-sm-6 col-lg-4">
                  <label className="form-label">No of Projects</label>
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="number"
                        ref={noofprojectsRef}
                        onChange={e => setNoOfProjects(e.target.value)}
                        className="py-2 form-control solfin-input"
                        placeholder="Min"
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="number"
                        ref={noofprojectsmaxRef}
                        onChange={e => setNoOfProjectsMax(e.target.value)}
                        className="py-2 form-control solfin-input"
                        placeholder="Max"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* {fpAllowed ?
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Finance Partner</label>
                  <input ref={fpRef} onChange={(e) => setFP(e.target.value)} className="form-control" placeholder="Search" />
                </div> : ""} */}
              {eallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">EPC</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={edropdownRef}
                    required
                    onChange={e => setEId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getEPCListForQuote &&
                      getEPCListForQuote.data &&
                      getEPCListForQuote.data.data &&
                      getEPCListForQuote.data.data.data.map(data => (
                        <>
                          <option
                            id={data.epc_id}
                            key={data.epc_id}
                            value={data.epc_id}
                          >
                            {data.company_name}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {dallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">DSA</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={ddropdownRef}
                    required
                    onChange={e => setDId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getDSAListForQuote &&
                      getDSAListForQuote.data &&
                      getDSAListForQuote.data.data &&
                      getDSAListForQuote.data.data.data.map(data => (
                        <>
                          <option
                            id={data.dsa_id}
                            key={data.dsa_id}
                            value={data.dsa_id}
                          >
                            {data.dsa_name}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}

              {/* getEPCListForQuote
  getDSAListForQuote */}
              {customertypeallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Type</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={typedropdownRef}
                    required
                    onChange={e => settypeId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getAllTypesQuery &&
                      getAllTypesQuery.data &&
                      getAllTypesQuery.data.data &&
                      getAllTypesQuery.data.data.map(data => (
                        <>
                          <option
                            id={data.customer_type_id}
                            key={data.customer_type_id}
                            value={data.customer_type_id}
                          >
                            {data.customer_type}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {statusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={statusdropdownRef}
                    required
                    onChange={e => setstatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {applicationStatus &&
                      applicationStatus.data &&
                      applicationStatus.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {pepcstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={pepcstatusdropdownRef}
                    required
                    onChange={e => setPEPCStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {applicationStatusProvidedByEpc &&
                      applicationStatusProvidedByEpc.data &&
                      applicationStatusProvidedByEpc.data.data &&
                      applicationStatusProvidedByEpc.data.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}

              {pstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={pstatusdropdownRef}
                    required
                    onChange={e => setPStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getProjectStatusProvidedByAdmin &&
                      getProjectStatusProvidedByAdmin.data &&
                      getProjectStatusProvidedByAdmin.data.data.data.map(
                        data => (
                          <>
                            <option
                              id={data.status_id}
                              key={data.status_id}
                              value={data.status_id}
                            >
                              {data.status}
                            </option>
                          </>
                        )
                      )}
                  </select>
                </div>
              ) : (
                ''
              )}

              {customerstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={customerstatusdropdownRef}
                    required
                    onChange={e => setCustomerStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getCustomerStatus &&
                      getCustomerStatus.data &&
                      getCustomerStatus.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {publishstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Publish Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={publishstatusdropdownRef}
                    required
                    onChange={e => setPublishStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="1">Publish</option>
                    <option value="0">Unpublish</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {epcstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={epcstatusdropdownRef}
                    required
                    onChange={e => setEPCStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="New">New</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {eplstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={eplstatusdropdownRef}
                    required
                    onChange={e => setEPLStatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getEmpanelledStatus &&
                      getEmpanelledStatus.data &&
                      getEmpanelledStatus.data.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {acallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Activities</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={acdropdownRef}
                    required
                    onChange={e => setACId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getActivities &&
                      getActivities.data &&
                      getActivities.data.data.map(data => (
                        <>
                          <option
                            id={data.activity_id}
                            key={data.activity_id}
                            value={data.activity_id}
                          >
                            {data.activity}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {exallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Experience</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={exdropdownRef}
                    required
                    onChange={e => setEXId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getExperiences &&
                      getExperiences.data &&
                      getExperiences.data.data.map(data => (
                        <>
                          <option
                            id={data.experience_id}
                            key={data.experience_id}
                            value={data.experience_id}
                          >
                            {data.experience}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {esstatusallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={esstatusdropdownRef}
                    required
                    onChange={e => setEsstatusId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    {getLeadStatus &&
                      getLeadStatus.data &&
                      getLeadStatus.data.map(data => (
                        <>
                          <option
                            id={data.status_id}
                            key={data.status_id}
                            value={data.status_id}
                          >
                            {data.status}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {assignedtoallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Assigned To</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={assignedtodropdownRef}
                    required
                    onChange={e => setAssignedToId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="unassigned">Unassigned</option>
                    {getEpcAssignedLeads &&
                      getEpcAssignedLeads.data &&
                      getEpcAssignedLeads.data.map(data => (
                        <>
                          <option
                            id={data.epc_id}
                            key={data.epc_id}
                            value={data.epc_id}
                          >
                            {data.company_name}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              ) : (
                ''
              )}
              {financingallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Financing</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={financingdropdownRef}
                    required
                    onChange={e => setFinancingId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {ppallowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label">Premium Partner</label>
                  <select
                    className="form-select solfin-input py-2"
                    ref={ppdropdownRef}
                    required
                    onChange={e => setPPId(e.target.value)}
                  >
                    <option selected value="">
                      Choose...
                    </option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              ) : (
                ''
              )}
              {startandenddateallowed ? (
                <>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date To</label>
                    <DatePicker
                      className="solfin-input py-2"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date From</label>
                    <DatePicker
                      className="solfin-input py-2"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              {istartandenddateallowed ? (
                <>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date To</label>
                    <DatePicker
                      className="solfin-input py-2"
                      selected={istartDate}
                      onChange={handleIStartDateChange}
                      selectsStart
                      startDate={istartDate}
                      endDate={iendDate}
                      placeholderText="Start Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-2">
                    <label className="form-label">Date From</label>
                    <DatePicker
                      className="solfin-input py-2"
                      selected={iendDate}
                      onChange={handleIEndDateChange}
                      selectsEnd
                      startDate={istartDate}
                      endDate={iendDate}
                      minDate={istartDate}
                      placeholderText="End Date"
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                </>
              ) : (
                ''
              )}
              {connecttoGridAllowed ? (
                <div className="col-12 col-sm-6 col-lg-2">
                  <label className="form-label checklabel">
                    Connect to Grid
                  </label>
                  <div className="check-label mt-3">
                    <label className="me-3">
                      <input
                        className="me-2"
                        type="radio"
                        value="Yes"
                        checked={connecttoGrid === 'Yes'}
                        onChange={handleOptionChange}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        className="me-2"
                        type="radio"
                        value="No"
                        checked={connecttoGrid === 'No'}
                        onChange={handleOptionChange}
                      />
                      No
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isconnected"
                    value="yes"
                    id="flexRadioDefault1"
                    checked={radioChecked === "yes"}
                    onChange={handleIsConnected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="no"
                    name="isconnected"
                    checked={radioChecked === "no"}
                    id="flexRadioDefault2"
                    onChange={handleIsConnected}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    No
                  </label>
                </div> */}
              <div className="d-flex align-items-center justify-content-end mt-3">
                <button
                  onClick={() => handleFiltersChange()}
                  className="btn btn-info me-2"
                  tabIndex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                >
                  <span>
                    <span className="d-none d-sm-inline-block">Search</span>
                  </span>
                </button>
                <button
                  onClick={() => handleClearFilters()}
                  className="btn btn-info filter-btn"
                  tabIndex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                >
                  <span>
                    <span className="d-none d-sm-inline-block">Reset</span>
                  </span>
                </button>
              </div>
              {/* <div className="col-12 col-sm-6 col-lg-4">
                <label className="form-label">Date From</label>
                <input
                  type="date"
                  className="form-control dt-input"
                  data-column="4"
                  data-column-index="3"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Start Date"
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-4">
                <label className="form-label">Date To</label>
                <div className="mb-0">
                  <input
                    type="date"
                    className="form-control dt-date flatpickr-range dt-input"
                    data-column="5"
                    placeholder="EndDate"
                    data-column-index="4"
                    name="dt_date"
                    value={endDate}
                    onChange={handleEndDateChange}
                  /> */}
              {/* <input
                    type="hidden"
                    className="form-control dt-date start_date dt-input"
                    data-column="5"
                    data-column-index="4"
                    name="value_from_start_date"
                  />
                  <input
                    type="hidden"
                    className="form-control dt-date end_date dt-input"
                    name="value_from_end_date"
                    data-column="5"
                    data-column-index="4"
                  /> */}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-0" />
    </>
  );
};

export default Filter;
