import {Link} from 'react-router-dom';

const ProfileMenu = ({onLogout}) => (
  <div className="col right-col">
    <div className="right-header d-flex align-items-center justify-content-end">
      <div className="solfin-header-profile position-relative ms-lg-5 ms-4">
        <Link
          to="#"
          className="d-flex align-items-center"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="top-profile-img overflow-hidden rounded-pill d-flex">
            <img src="/images/default_user.png" alt="User" title="User" />
          </span>
          <span className="down-arrow ms-2 text-dark">
            <i className="fa-solid fa-chevron-down"></i>
          </span>
        </Link>
        <ul className="dropdown-menu end-0">
          <li>
            <Link className="dropdown-item" to="/customer/profile">
              Profile
            </Link>
          </li>
          <li>
            <Link
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              href="#"
            >
              Change Password
            </Link>
          </li>
          <li>
            <button className="dropdown-item" onClick={onLogout}>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default ProfileMenu;
