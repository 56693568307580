import {useFormik} from 'formik';
import {useMutation} from 'react-query';
import * as queryData from '../service/dataService';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {useRef, useState} from 'react';

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required('Please enter your Current Password'),
  newPassword: Yup.string().required('Please enter New Password'),
  confirmPassword: Yup.string()
    .required('Please confirm your New Password')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, action) => {
      // console.log(values);
      changePassword.mutate(values);
      action.resetForm();
    },
  });

  const changePassword = useMutation(queryData.changePassword, {
    onError: error => {
      toast.error(error.message);
      // console.log(error.message);
    },
    onSuccess: data => {
      // console.log(data);
      if (data.success === false) {
        toast.error(data.message);
      } else if (data.success === true) {
        toast.success(data.message);
        navigate('/customer-dashboard');
        const button = document.getElementById('modal-close-btn');
        button.click();
      }
      navigate('/customer-dashboard');
    },
  });

  const handleCloseModal = () => {
    // Reset the form when the modal is closed
    resetForm();
  };

  return (
    <div
      className="modal fade edit-profile"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-4 fw-semibold" id="exampleModalLabel">
              Change Password
            </h1>
            <button
              type="button"
              id="modal-close-btn"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}
            ></button>
          </div>
          <div className="modal-body">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="FullName" className="mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  name="oldPassword"
                  className="form-control"
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.oldPassword && errors.oldPassword ? (
                <div className="error">{errors.oldPassword}</div>
              ) : null}
              {/* <!-- form group --> */}

              <div className="form-group mb-4">
                <label htmlFor="FullName" className="mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  className="form-control"
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.newPassword && errors.newPassword ? (
                <div className="error">{errors.newPassword}</div>
              ) : null}
              {/* <!-- form group --> */}

              <div className="form-group mb-4">
                <label htmlFor="FullName" className="mb-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {touched.confirmPassword && errors.confirmPassword ? (
                <div className="error">{errors.confirmPassword}</div>
              ) : null}
              {/* <!-- form group --> */}

              <div className="solfin-actions text-end mt-2">
                <input
                  type="reset"
                  value="Cancel"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border ps-4 pe-4 pt-3 pb-3"
                />
                <input
                  type="submit"
                  value="Change Password"
                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn ps-4 pe-4 pt-3 pb-3 ms-2"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
