import React from 'react';
import {useQuery} from 'react-query';

import * as queryData from '../service/DataService';
import {saveAs} from 'file-saver';
import AreaServedModal from '../../components/AreaServedModal';
import PublishingDetails from './PublishingDetails';
import DistributorDetails from '../Distributor/Details';

const Details = () => {
  // const [filteredData,setFilteredData]= useState(data && data.rateMatrix && data.rateMatrix.filter(obj => obj.state_id =="0"));

  const {data, isLoading, error} = useQuery('myDetails', queryData.myDetails);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data fetched.</div>;
  }
  if (!data.data) {
    return <div>No data fetched.</div>;
  }
  if (data.data.is_distributor == true) {
    return <DistributorDetails data={data} id={data.data.epc_id} />;
  }
  const onButtonClick = () => {
    const image_url =
      data && data?.documents[0] && data.documents[0].documentAddress;
    saveAs(image_url, 'file1');
  };
  const onButtonClick1 = () => {
    const image_url1 =
      data && data?.documents[0] && data.documents[1].documentAddress;
    saveAs(image_url1, 'file2');
  };

  return (
    <>
      <div className="content-body">
        <div className="page-title">
          <h3>My Details</h3>
        </div>
        {/* <!-- /page title --> */}
        <div className="dash-card">
          <div className="dash-card-header border-0">
            <div className="mb-0">
              <ul className="nav nav-pills mb-3" id="detail-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Enrollment Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Publishing Details
                  </button>
                </li>
              </ul>
              <div className="tab-content mt-3" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div className="solfin-application-details">
                    <ul>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{width: '100%'}}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Partner Id:
                        </span>{' '}
                        <span>{data.data.epc_id}</span>
                      </li>
                      <li
                        className="d-flex align-items-center pb-3"
                        style={{width: '100%'}}
                      >
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Name:
                        </span>{' '}
                        <span>{data.data.company_name}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Address:
                        </span>{' '}
                        <span>{data.data.address1}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Pin Code:
                        </span>{' '}
                        <span>{data.data.pin_code}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          City:
                        </span>{' '}
                        <span>{data.data.city}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          State:
                        </span>
                        <span>{data.data.state}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Phone Number:
                        </span>
                        <span>{data.data.phone}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Email:
                        </span>
                        <span>{data.data.email}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Customer Type:
                        </span>
                        <span>{data.data.customer_type}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{width: '45%'}}>
                          Document Type 1:
                        </span>
                        <span>
                          {data &&
                          data?.documents &&
                          data?.documents[0] &&
                          data?.documents[0].documentName
                            ? data && data?.documents[0].documentName
                            : 'No file is uploaded'}
                        </span>
                      </li>

                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{width: '45%'}}>
                          Document 1 Image:
                        </span>
                        <span>
                          {data &&
                          data.documents[0] &&
                          data.documents[0].documentAddress ? (
                            <a
                              href={
                                data &&
                                data.document &&
                                data.documents[0].documentAddress
                              }
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              <button
                                className="btn text-dark"
                                onClick={onButtonClick}
                              >
                                <i className="fa-solid fa-download"></i>{' '}
                                Download
                              </button>
                            </a>
                          ) : (
                            ''
                          )}
                        </span>
                      </li>

                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{width: '45%'}}>
                          Document Type 2:
                        </span>
                        <span>
                          {data &&
                          data?.documents &&
                          data?.documents[1] &&
                          data?.documents[1].documentName
                            ? data && data.documents[1].documentName
                            : 'No file is uploaded'}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span className="fw-bold mx-2" style={{width: '45%'}}>
                          Document 2 Image:
                        </span>
                        <span>
                          {data &&
                          data.documents[1] &&
                          data.documents[1].documentAddress ? (
                            <a
                              href={data.documents[1].documentAddress}
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              <button
                                className="btn text-dark"
                                onClick={onButtonClick1}
                              >
                                <i className="fa-solid fa-download"></i>{' '}
                                Download
                              </button>
                            </a>
                          ) : (
                            ''
                          )}
                        </span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Premium Partner Code:
                        </span>
                        <span>{data.data.premium_partner_code}</span>
                      </li>
                      <li className="d-flex align-items-center pb-3">
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Status:
                        </span>
                        <span>{data.data.status}</span>
                      </li>

                      <li>
                        {/* <span className="detail-label">Area Served</span> */}
                        <span
                          className="fw-bold mx-2"
                          style={{marginRight: '50px', width: '45%'}}
                        >
                          Area Served:
                        </span>
                        <span className="detail-value">
                          <div className="state-served">
                            {data.serviceArea.map((area, index) => (
                              <React.Fragment key={index}>
                                <div
                                  className="btn text-dark text-decoration-underline py-0 px-1"
                                  data-bs-toggle="modal"
                                  data-bs-target={`#stateId${area.state_id}`}
                                >
                                  {area.state}
                                  <AreaServedModal area={area} />{' '}
                                </div>
                                {index !== data.serviceArea.length - 1 && ','}
                              </React.Fragment>
                            ))}
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <PublishingDetails />
              </div>
            </div>
          </div>
          {/* <!-- /card header --> */}
        </div>
        {/* <!-- /dash-card --> */}
      </div>
      {/* <!-- /content body --> */}
    </>
  );
};

export default Details;
