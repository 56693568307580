import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ChangePassword from './ChangePassword';
import TopBar from '../../FrontEnd/components/TopBar';
import Navigation from '../../FrontEnd/components/Navigation';
import ProfileMenu from '../../FrontEnd/components/ProfileMenu';
import LogoSection from '../../FrontEnd/components/LogoSection';

import logo from '../../assets/images/solfin-logo.svg';
import { logout } from '../service/dataService';

const customerNavRoutes = [
  {
    title: 'Applications',
    links: [
      { to: '/customer-dashboard', label: 'Applied' },
      { to: '/customer/new-application', label: 'New' }
    ]
  },
  {
    title: 'Projects',
    linkTo: '/customer/projects'
  },
  {
    title: 'Monitoring',
    linkTo: '/customer/monitoring'
  }
];

const Header = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState('Applications');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleLogout = async () => {
    const response = await logout();
    if (response.status === 200) {
      navigate('/login');
    }
  };

  return (
    <>
      <header className="inner-header smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-2 mt-1">
        <div className="container custom-container">
          <div className="header-wrap bg-white rounded-20">
            <TopBar email="info@solfin.co.in" phone="+919599446877" />
            <div className="mein-header consumer-header">
              <div className="row align-items-lg-center justify-content-between">
                <LogoSection
                  logo={logo}
                  toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
                  isMenuOpen={isMenuOpen}
                />
                <Navigation
                  activeItem={activeItem}
                  handleItemClick={setActiveItem}
                  isMenuOpen={isMenuOpen}
                  closeMenu={() => setIsMenuOpen(false)}
                  navItems={customerNavRoutes}
                />
                <ProfileMenu onLogout={handleLogout} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <ChangePassword />
    </>
  );
};

export default Header;
